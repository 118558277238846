import {useStore} from 'products/metrum/store/store'
import shallow from 'zustand/shallow'
import {regalModel, shelfMaterials, frontMaterials} from 'products/metrum/store/datamodels'
import {accHelper, getDrawerParts} from 'utils/helpers'
import {materialModel, drawerLengths, drawerSideInset} from 'utils/datamodels'
import {IDrawer} from 'common/threeComponents/DrawerFCNew'

export const DrawerList = () => {
  const drawerList = useStore((store) => store.config.drawers.list)
  const [backpanels, depth, shelfHeight, columns, grid, shelfMaterialID, drawerMaterial] = useStore(
    (store) => [
      store.config.backpanels,
      store.config.main.depth,
      store.config.main.height,
      store.config.columns.list,
      store.config.main.grid,
      store.config.main.materialID,
      store.config.drawers.materialID,
    ],
    shallow
  )
  const bodyMaterialID = shelfMaterials[shelfMaterialID].drawerInnerMaterial as keyof typeof materialModel
  const thickness = materialModel[shelfMaterialID].thickness
  const bodyThickness = materialModel[bodyMaterialID].thickness
  const gridSize = (shelfHeight - thickness) / grid
  const shadowJoint = regalModel.shadowJoint
  const grainDir = useStore((store) => store.view.drawers.grainDirection) === 0 ? 'horizontal' : 'vertical'
  const backpanelThickness = materialModel[backpanels.materialID].thickness
  const drawers: IDrawer[] = []

  drawerList.forEach((drawer, j) => {
    if (accHelper.is_in_column(drawer.pos.x, columns.length)) {
      const frontThickness = frontMaterials[drawerMaterial].thickness
      const drawerDepth = Math.max(...drawerLengths.filter((l) => l < depth - 0.001 - frontThickness - 0.013 - 0.008 - 0.001 - backpanelThickness)) + frontThickness
      if (drawerDepth === -Infinity) return null
      const extraHeight = grid - drawer.pos.y - drawer.size.y < 0.01 ? thickness + shadowJoint : 0
      const height = drawer.size.y * gridSize - shadowJoint + extraHeight
      const width = columns[drawer.pos.x].width - 2 * shadowJoint
      const bodyHeight = Math.floor((height - 0.015 - 0.0285) * 200) / 200 - extraHeight
      const topInset = height - bodyHeight - 0.0285
      const leftInset = drawerSideInset - bodyThickness - shadowJoint
      const xPos = columns.slice(0, drawer.pos.x).reduce((pv, cv) => pv + cv.width + thickness, thickness + shadowJoint)
      const yPos = drawer.pos.y * gridSize
      const bottomMaterialID = 'mdf8W' as keyof typeof materialModel
      const drawerParts = getDrawerParts({
        width: width,
        height: height,
        depth: drawerDepth,
        bodyHeight: bodyHeight,
        shelfInnerWidth: columns[drawer.pos.x].width,
        frontMaterialID: drawerMaterial,
        bodyMaterialID: bodyMaterialID,
        bottomMaterialID: bottomMaterialID,
      })
      drawers.push({
        key: drawer.pos.x + '-' + drawer.pos.y,
        xPos: xPos,
        yPos: yPos,
        zPos: depth - 0.001,
        width: width,
        height: height,
        depth: drawerDepth,
        leftInset: leftInset,
        topInset: topInset,
        grainDir: grainDir,
        parts: drawerParts,
        type: 'drawer',
      })
    }
  })

  return drawers
}

import {doorMaterials} from '../../store/datamodels'
import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {DoorList} from 'products/analog/partLists/doorList'
import {DoorFC} from './DoorFC'
import {useStore} from 'products/analog/store/store'

export const ConnectedDoors = (props: any): any => {
  const doorList = DoorList()
  const visible = useStore((store) => store.view.doors.visible)
  if (doorList.length > 0) {
    const materialID = doorList[0].parts.front.material
    const materialReference = doorMaterials[materialID].matReference
    const materialObject = MaterialSingleton.getMaterials()
    var material = [materialObject[materialReference]?.small_1, materialObject[materialReference]?.small_2] || materialObject[materialReference]
    var innerMaterial = materialObject['solidAlu'].small_1
  }
  return (
    <>
      {visible &&
        doorList.map((door) => {
          return (
            <DoorFC {...door} thickness={door.parts.front.thickness} innerMaterial={door.offDoor ? innerMaterial : undefined} doorMaterial={material[Math.round(Math.random())]} scene={props.scene} />
          )
        })}
    </>
  )
}

import {Route, Routes} from 'react-router-dom'
import shallow from 'zustand/shallow'
import {ColsModal, WidthModal, OwnValue} from '../../../../components/material_interfaces/material_interfaces'
import * as lookup from 'products/dasregal/store/datamodels'
import {getMaxGrid, getMinGrid} from '../../../../utils/helpers'
import {useStore} from '../../store/store'

export function ShelfStructureModals(props: any) {
  const {height, depth, grid, columns} = useStore(
    (store) => ({height: store.config.main.height, depth: store.config.main.depth, grid: store.config.main.grid, columns: store.config.columns.list}),
    shallow
  )
  const shelfMaterial = useStore((store) => store.config.main.materialID)
  const setHeight = useStore((store) => store.setHeight)
  const minGrid = getMinGrid(height, lookup.regalModel.gridMax)
  const maxGrid = getMaxGrid(height, lookup.regalModel.gridMin)
  const setDepth = useStore((store) => store.setDepth)
  const setGrid = useStore((store) => store.setGrid)
  const setColWidth = useStore((store) => store.columns.setWidth)
  const footHeight = useStore((store) => store.config.feet.height)
  const fullHeight = Math.round((height + footHeight) * 1000) / 1000
  return (
    <>
      <Routes>
        <Route
          path="height"
          element={
            <OwnValue
              title="Regalhöhe"
              val={fullHeight * 1000}
              callback={(newVal: number) => setHeight(newVal / 1000)}
              min={lookup.regalModel.minHeight * 1000}
              max={lookup.shelfMaterials[shelfMaterial].maxWidth * 1000}
            />
          }
        />
        <Route
          path="depth"
          element={
            <OwnValue title="Regaltiefe" val={depth * 1000} callback={(newVal: number) => setDepth(newVal / 1000)} min={lookup.regalModel.minDepth * 1000} max={lookup.regalModel.maxDepth * 1000} />
          }
        />
        <Route path="cols" element={<ColsModal title="Rastermaß" val={grid} min={minGrid} max={maxGrid} callback={(newVal: number) => setGrid(newVal)} />} />
        <Route
          path="cols/:colNum"
          element={
            <WidthModal
              title="Fachbreite"
              val={columns}
              defaultValues={[]}
              callback={(newVal: number, colNum: number) => setColWidth(newVal / 1000, colNum)}
              min={lookup.regalModel.minColumnWidth * 1000}
              max={lookup.regalModel.maxColumnWidth * 1000}
            />
          }
        />
      </Routes>
    </>
  )
}

import {useStore} from 'products/kubatur/store/store'
import shallow from 'zustand/shallow'
import {frontMaterials, shelfMaterials, shelfTypes, slidingDoorMaterials} from 'products/kubatur/store/datamodels'
import {regalModel} from 'products/kubatur/store/datamodels'

export const ColumnList = () => {
  const columnList = useStore((store) => store.config.columns.list)
  const [height, depth, materialID, slidingDoors, doors, shelfTyp] = useStore(
    (store) => [store.config.main.height, store.config.main.depth, store.config.main.materialID, store.config.slidingDoors, store.config.doors, store.config.main.shelfTyp],
    shallow
  )
  const thickness = shelfMaterials[materialID].thickness
  const SlidingDoorThickness = slidingDoorMaterials[slidingDoors.materialID].thickness
  let startPos = 0
  const yPos = 0
  const doorThickness = frontMaterials[doors.materialID].thickness
  const noDoorAtFirstColumn = doors.list.filter((door) => Math.floor(door.pos.x) === 0).length === 0
  const extraDepthLarge = 2 * regalModel.slidingDoorDistance + 2 * thickness + SlidingDoorThickness - 0.01
  const extraDepthSmall = regalModel.slidingDoorDistance + thickness - 0.01
  const extraDepthLeft = shelfTyp === 1 ? (slidingDoors.inFront === 'left' ? extraDepthLarge : extraDepthSmall) : noDoorAtFirstColumn ? regalModel.doorDistance + doorThickness : 0
  const extraDepthRight = slidingDoors.inFront === 'right' ? extraDepthLarge : extraDepthSmall
  const columns = [{key: 0, xPos: startPos, yPos: yPos, zPos: 0, length: height, depth: depth + extraDepthLeft, thickness: thickness, material: materialID, type: 'column'}]

  columnList.forEach((el, index) => {
    startPos += el.width + thickness
    const lastColumnOrShelfTyp2 = index === columnList.length - 1 || shelfTyp === 2 // is last column or shelfTyp is 2 (rotating doors)
    const columnHeight = lastColumnOrShelfTyp2 ? height : height - 2 * thickness - shelfTypes[shelfTyp].topInset - shelfTypes[shelfTyp].bottomInset
    const noDoorAtRight = doors.list.filter((door) => Math.floor(door.pos.x) === index + 1).length === 0
    const noDoorAtLeft = doors.list.filter((door) => Math.ceil(door.pos.x + door.size.x) === index + 1).length === 0
    const noDoorInFront = ((noDoorAtRight || noDoorAtLeft) && index < columnList.length - 1) || noDoorAtLeft // no door in front of column
    const yPos = lastColumnOrShelfTyp2 ? 0 : thickness + shelfTypes[shelfTyp].bottomInset
    const columnDepth = index === columnList.length - 1 && shelfTyp !== 2 ? depth + extraDepthRight : depth + (noDoorInFront && shelfTyp === 2 ? regalModel.doorDistance + doorThickness : 0)
    columns.push({
      key: index + 1,
      xPos: startPos,
      yPos: yPos,
      zPos: 0,
      length: columnHeight,
      depth: columnDepth,
      thickness: thickness,
      material: materialID,
      type: index === columnList.length - 1 ? 'column' : 'middleColumn',
    })
  })

  return columns
}

import {useCallback, useEffect, useRef} from 'react'
import {useFrame} from 'react-three-fiber'
import * as THREE from 'three'
import {materialModel} from 'utils/datamodels'
import {Selector, IVector, Board as FiberBoard} from './FiberBoard'
import {MaterialSingleton} from './Materials'

type DrawerPart = {
  length: number
  depth: number
  thickness: number
  material: keyof typeof materialModel
}

type DrawerParts = {
  front: DrawerPart
  insideFront?: DrawerPart
  sideLeft: DrawerPart
  sideRight: DrawerPart
  back: DrawerPart
  bottom: DrawerPart
}
export interface IDrawer {
  key: string
  xPos: number
  yPos: number
  zPos: number
  width: number
  height: number
  depth: number
  leftInset: number
  topInset: number
  grainDir?: 'horizontal' | 'vertical'
  parts: DrawerParts
  type: string
}

export const DrawerFCFiber = (props: IDrawer) => {
  const {xPos, yPos, zPos, width, height, depth, leftInset, topInset, grainDir} = props
  const {sideLeft, sideRight, front, insideFront, back, bottom} = props.parts

  const isActive = useRef(false)
  const drawerRef = useRef<THREE.Group>(new THREE.Group())
  const frontRotation = useRef<IVector>()
  const widthRef = useRef(width)
  const heightRef = useRef(height)
  widthRef.current = grainDir === 'vertical' ? height : width
  heightRef.current = grainDir === 'vertical' ? width : height
  frontRotation.current = grainDir === 'vertical' ? [0.5, 0.5, 0] : [0.5, 0, 0]
  //@ts-ignore
  const hasInsideFront = materialModel[front.material].insideFront || false
  const materialObject = MaterialSingleton.getMaterials()
  const frontMaterialReference = materialModel[front.material].matReference
  const frontMaterial = materialObject[frontMaterialReference].small_1 || materialObject[frontMaterialReference]
  const bodyMaterialReference = materialModel[sideLeft.material].matReference
  const bodyMaterial = materialObject[bodyMaterialReference].small_1 || materialObject[bodyMaterialReference]
  const bottomMaterialReference = materialModel[bottom.material].matReference
  const bottomMaterial = materialObject[bottomMaterialReference].small_1 || materialObject[bottomMaterialReference]

  useEffect(() => {
    widthRef.current = grainDir === 'vertical' ? height : width
    heightRef.current = grainDir === 'vertical' ? width : height
    frontRotation.current = grainDir === 'vertical' ? [0.5, 0.5, 0] : [0.5, 0, 0]
  }, [grainDir, height, width])

  const onMouseOver = useCallback(() => {
    isActive.current = true
  }, [])

  const onMouseOut = useCallback(() => {
    isActive.current = false
  }, [])

  useFrame((state, delta) => {
    drawerRef.current.position.z = THREE.MathUtils.lerp(drawerRef.current.position.z, isActive.current ? depth - 0.02 : 0, 0.1)
  })

  return (
    <>
      <group name="drawer" ref={drawerRef}>
        <FiberBoard
          {...props}
          key={'front'}
          size={[widthRef.current, front.thickness, heightRef.current]}
          xPos={xPos + width / 2}
          yPos={yPos + height / 2}
          material={frontMaterial}
          rotation={frontRotation.current}
          translate={[0, -0.5, 0]}
        />
        {hasInsideFront && (
          <FiberBoard
            {...props}
            key={'inside-front'}
            size={[insideFront?.length || 0, insideFront?.thickness || 0, insideFront?.depth || 0]}
            xPos={xPos + leftInset + sideLeft.thickness}
            yPos={yPos + height - topInset}
            zPos={zPos - front.thickness}
            material={bodyMaterial}
            rotation={[0.5, 0, 0]}
            translate={[0.5, -0.5, 0.5]}
          />
        )}
        <FiberBoard
          {...props}
          key={'side-left'}
          size={[sideLeft.length, sideLeft.thickness, sideLeft.depth]}
          xPos={xPos + leftInset}
          yPos={yPos + height - topInset}
          zPos={zPos - front.thickness}
          material={bodyMaterial}
          rotation={[0.5, 0, -0.5]}
          translate={[0.5, 0.5, 0.5]}
        />
        <FiberBoard
          {...props}
          key={'side-right'}
          size={[sideRight.length, sideRight.thickness, sideRight.depth]}
          xPos={xPos + leftInset + (hasInsideFront ? back.length + sideLeft.thickness + sideRight.thickness : back.length)}
          yPos={yPos + height - topInset}
          zPos={zPos - front.thickness}
          material={bodyMaterial}
          rotation={[0.5, 0, -0.5]}
          translate={[0.5, -0.5, 0.5]}
        />
        <FiberBoard
          {...props}
          key={'back'}
          size={[back.length, back.thickness, back.depth]}
          xPos={xPos + leftInset + (hasInsideFront ? sideLeft.thickness : 0)}
          yPos={yPos + height - topInset}
          zPos={zPos - depth}
          material={bodyMaterial}
          rotation={[0.5, 0, 0]}
          translate={[0.5, 0.5, 0.5]}
        />
        <FiberBoard
          {...props}
          key={'bottom'}
          size={[bottom.length, bottom.thickness, bottom.depth]}
          xPos={xPos + leftInset + sideLeft.thickness - 0.00475}
          yPos={yPos + height - topInset - sideLeft.depth + 0.012}
          zPos={zPos - depth + back.thickness - 0.00475}
          material={bottomMaterial}
          rotation={[0, 0, 0]}
          translate={[0.5, 0.5, 0.5]}
        />
      </group>
      <Selector
        {...props}
        size={[height, 0.02, width]}
        material={new THREE.MeshBasicMaterial({alphaTest: 2})}
        rotation={[0, 0.5, 0.5]}
        translate={[0.5, -0.5, 0.5]}
        onPointerOver={onMouseOver}
        onPointerOut={onMouseOut}
      />
    </>
  )
}

import './overview.scss'
import tektur_1 from 'common/static/overview/tektur-1.jpg'
import tektur_2 from 'common/static/overview/tektur-2.jpg'
import tektur_3 from 'common/static/overview/tektur-3.jpg'
import tektur_4 from 'common/static/overview/tektur-4.jpg'
import tektur_5 from 'common/static/overview/tektur-5.jpg'
import tektur_6 from 'common/static/overview/tektur-6.jpg'
import tektur_7 from 'common/static/overview/tektur-7.jpg'
import tektur_8 from 'common/static/overview/tektur-8.jpg'
import tektur_9 from 'common/static/overview/tektur-9.jpg'
import tektur_10 from 'common/static/overview/tektur-10.jpg'
import tektur_11 from 'common/static/overview/tektur-11.jpg'
import tektur_12 from 'common/static/overview/tektur-12.jpg'
import tektur_13 from 'common/static/overview/tektur-13.jpg'
import tektur_14 from 'common/static/overview/tektur-14.jpg'
import tektur_15 from 'common/static/overview/tektur-15.jpg'
import tektur_16 from 'common/static/overview/tektur-16.jpg'
import tektur_17 from 'common/static/overview/tektur-17.jpg'
import tektur_18 from 'common/static/overview/tektur-18.jpg'
import tektur_19 from 'common/static/overview/tektur-19.jpg'
import tektur_20 from 'common/static/overview/tektur-20.jpg'
import tektur_21 from 'common/static/overview/tektur-21.jpg'
import tektur_22 from 'common/static/overview/tektur-22.jpg'
import {ProductOverView} from './ProductOverView'

export function ConnectedTekturOverView() {
  return <ProductOverView title={'tektur'} products={tektur} />
}

export const tektur = [
  {img: <img className="overview-image" src={tektur_17} alt="tektur" />, to: 'get/tektur-17'},
  {img: <img className="overview-image" src={tektur_21} alt="tektur" />, to: 'get/tektur-21'},
  {img: <img className="overview-image" src={tektur_19} alt="tektur" />, to: 'get/tektur-19'},
  {img: <img className="overview-image" src={tektur_1} alt="tektur" />, to: 'get/tektur-1'},
  {img: <img className="overview-image" src={tektur_18} alt="tektur" />, to: 'get/tektur-18'},
  {img: <img className="overview-image" src={tektur_13} alt="tektur" />, to: 'get/tektur-13'},
  {img: <img className="overview-image" src={tektur_10} alt="tektur" />, to: 'get/tektur-10'},
  {img: <img className="overview-image" src={tektur_15} alt="tektur" />, to: 'get/tektur-15'},
  {img: <img className="overview-image" src={tektur_16} alt="tektur" />, to: 'get/tektur-16'},
  {img: <img className="overview-image" src={tektur_11} alt="tektur" />, to: 'get/tektur-11'},
  {img: <img className="overview-image" src={tektur_14} alt="tektur" />, to: 'get/tektur-14'},
  {img: <img className="overview-image" src={tektur_6} alt="tektur" />, to: 'get/tektur-6'},
]

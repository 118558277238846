import {useStore} from 'products/analog/store/store'
import shallow from 'zustand/shallow'
import {backpanelMaterials, blueprint, shelfMaterialModel} from 'products/analog/store/datamodels'

export const BackpanelList = () => {
  const backpanelList = useStore((store) => store.backpanels.clearBackpanels(), shallow)
  const [columns, boards, shelfWidth, shelfMaterialID, backpanelMaterialID, grid, feetID] = useStore(
    (store) => [
      store.rows.clearColumns(),
      store.config.rows.list,
      store.config.main.width,
      store.config.main.materialID,
      store.config.backpanels.materialID,
      store.config.main.grid,
      store.config.feet.id,
    ],
    shallow
  )
  const shelfThickness = shelfMaterialModel[shelfMaterialID].thickness
  const backpanelThickness = backpanelMaterials[backpanelMaterialID].thickness
  const gridSize = (shelfWidth - shelfThickness) / grid
  const backpanels: {key: string; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof backpanelMaterials; type: 'backpanel'}[] = []
  const wallMounted = feetID === 'wallMounted'

  //Create Backpanels for wallMounted
  if (wallMounted) {
    let yPos = shelfThickness - blueprint.backpanelSideInset
    boards.forEach((board, y) => {
      yPos += board.height + shelfThickness
      const visibleColumns: number[] = [...columns[y]]
      visibleColumns.push(0, grid)
      visibleColumns.sort((a, b) => a - b)
      return [...Array(visibleColumns.length - 1)].forEach((_, x) => {
        const xPos = visibleColumns[x] * gridSize + shelfThickness - blueprint.backpanelSideInset
        const nextColumn = Math.min(...visibleColumns.filter((el) => el > visibleColumns[x]))
        const width = (nextColumn - visibleColumns[x]) * gridSize - shelfThickness + 2 * blueprint.backpanelSideInset
        backpanels.push({
          key: y + '.' + x,
          xPos: xPos,
          yPos: yPos - board.height - shelfThickness,
          zPos: blueprint.backpanelInset.wallMounted,
          length: width,
          depth: board.height + blueprint.backpanelSideInset,
          thickness: backpanelThickness,
          material: backpanelMaterialID,
          type: 'backpanel',
        })
      })
    })
  } else {
    //Create Backpanel for each Backpanel in StoreList
    backpanelList.forEach((backpanel) => {
      const yPos = boards.slice(0, backpanel.pos.y).reduce((pv, cv) => pv + cv.height + shelfThickness, shelfThickness - blueprint.backpanelSideInset)
      const zPos = blueprint.backpanelInset.standard
      const height = boards[backpanel.pos.y].height + blueprint.backpanelSideInset
      if (backpanel.cover === 'full') {
        const xPos = shelfThickness - blueprint.backpanelSideInset
        const width = shelfWidth - shelfThickness * 2 + 2 * blueprint.backpanelSideInset
        backpanels.push({
          key: backpanel.pos.y + '-full',
          xPos: xPos,
          yPos: yPos,
          zPos: zPos,
          length: width,
          depth: height,
          thickness: backpanelThickness,
          material: backpanelMaterialID,
          type: 'backpanel',
        })
      } else {
        const columnList = columns[backpanel.pos.y].filter((i) => i > backpanel.pos.x)
        columnList.push(grid)
        const nextColumn = Math.min(...columnList)
        const multiplier = nextColumn - backpanel.pos.x
        const width = gridSize * multiplier - shelfThickness + 2 * blueprint.backpanelSideInset
        const xPos = backpanel.pos.x * gridSize + shelfThickness - blueprint.backpanelSideInset
        backpanels.push({
          key: backpanel.pos.x + '-' + backpanel.pos.y,
          xPos: xPos,
          yPos: yPos,
          zPos: zPos,
          length: width,
          depth: height,
          thickness: backpanelThickness,
          material: backpanelMaterialID,
          type: 'backpanel',
        })
      }
    })
  }

  return backpanels
}

import {Outlet} from 'react-router-dom'
import {ShelfStructure} from '../../Shelf/ShelfStructure'
import {SlidingDoors} from '../../Doors/SlidingDoors'
import {FeetHeight} from '../../Feet/FeetHeight'
import {AccordionElement, ParentButton, MenuBox} from 'components/material_interfaces/materialInterfaces'
import {Drawers} from '../../Drawers/Drawers'
import {Backpanels} from '../../Back/Backpanels'
import {useStore} from 'products/analog/store/store'
import {useCommonStore} from 'common/Store/CommonStore'
import {useEffect} from 'react'
import {Contact} from 'components/contact/Contact'

export function IndexView() {
  const [expanded, setExpanded] = useCommonStore((state) => [state.expandedFirst, state.setExpandedFirst])

  const setDoorVisibility = useStore((store) => store.doors.setVisible)
  const setDrawerVisibility = useStore((store) => store.drawers.setVisible)
  const setEditBackpanels = useStore((store) => store.backpanels.setEdit)
  const setEditDoors = useStore((store) => store.doors.setEdit)
  const setEditDrawers = useStore((store) => store.drawers.setEdit)
  const setEditStructure = useStore((store) => store.setBoardsEdit)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    switch (expanded) {
      case 'structure':
        setDoorVisibility(!expanded)
        setDrawerVisibility(true)
        setEditBackpanels(false)
        setEditDoors(false)
        setEditDrawers(false)
        setEditStructure(expanded === 'structure')
        break
      case 'slidingdoors':
        setDoorVisibility(true)
        setDrawerVisibility(true)
        setEditBackpanels(false)
        setEditDoors(expanded === 'slidingdoors')
        setEditDrawers(false)
        setEditStructure(false)
        break
      case 'drawers':
        setDoorVisibility(!expanded)
        setDrawerVisibility(true)
        setEditBackpanels(false)
        setEditDoors(false)
        setEditDrawers(expanded === 'drawers')
        setEditStructure(false)
        break
      case 'back':
        setDoorVisibility(!expanded)
        setDrawerVisibility(!expanded)
        setEditBackpanels(expanded === 'back')
        setEditDoors(false)
        setEditDrawers(false)
        setEditStructure(false)
        break
      case 'feet':
      default:
        setDoorVisibility(true)
        setDrawerVisibility(true)
        setEditBackpanels(false)
        setEditDoors(false)
        setEditDrawers(false)
        setEditStructure(false)
        break
    }
    return () => {
      setDoorVisibility(true)
      setDrawerVisibility(true)
      setEditBackpanels(false)
      setEditDoors(false)
      setEditDrawers(false)
      setEditStructure(false)
    }
  }, [expanded])

  return (
    <>
      <MenuBox>
        <AccordionElement id="structure" title="Regalkorpus" expanded={expanded === 'structure'} handleChange={handleChange}>
          <ShelfStructure />
        </AccordionElement>
        <AccordionElement id="slidingdoors" title="Schiebetüren" expanded={expanded === 'slidingdoors'} handleChange={handleChange}>
          <SlidingDoors />
        </AccordionElement>
        <AccordionElement id="drawers" title="Schubladen" expanded={expanded === 'drawers'} handleChange={handleChange}>
          <Drawers />
        </AccordionElement>
        <AccordionElement id="back" title="Rückwände" expanded={expanded === 'back'} handleChange={handleChange}>
          <Backpanels />
        </AccordionElement>
        <AccordionElement id="feet" title="Stellfüße / Wandmontage" expanded={expanded === 'feet'} handleChange={handleChange}>
          <FeetHeight />
        </AccordionElement>
        <ParentButton to="print">Drucken</ParentButton>
        <ParentButton to="saveload">Speichern & Laden</ParentButton>
        <AccordionElement id="contact" title="Sonderanfertigung / Frage" expanded={expanded === 'contact'} handleChange={handleChange}>
          <Contact />
        </AccordionElement>
      </MenuBox>
      <Outlet />
    </>
  )
}

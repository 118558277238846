import {ISetProduce, IStore} from '../store'
import {flapMaterials, slidingDoorMaterials} from '../datamodels'
import {GetState} from 'zustand'

export interface ISlidingDoorSlice {
  slidingDoors: {
    setSlidingDoor: (pos: {x: number; y: number}) => void
    setEdit: (edit: boolean) => void
    setMaterialID: (newMaterial: keyof typeof flapMaterials) => void
    setVisible: (show: boolean) => void
    clearSlidingDoors: () => {pos: {x: number; y: number}}[]
  }
}

export const createSlidingDoorSlice = (setProduce: ISetProduce<IStore>, get: GetState<IStore>) => {
  const slidingDoors = {
    setSlidingDoor: (pos: {x: number; y: number}) =>
      setProduce((store) => {
        const index = store.config.slidingDoors.list.findIndex((flap) => flap.pos.x === pos.x && flap.pos.y === pos.y)
        index === -1 ? store.config.slidingDoors.list.push({pos: pos, face: 'front'}) : store.config.slidingDoors.list.splice(index, 1)
        for (let i = 0; i < store.config.drawers.list.length; i++) {
          const drawerIndex = store.config.drawers.list.findIndex((drawer) => drawer.pos.x === pos.x && Math.floor(drawer.pos.y) === pos.y)
          drawerIndex !== -1 && store.config.drawers.list.splice(drawerIndex, 1)
        }
      }),
    setEdit: (edit: boolean) =>
      setProduce((store) => {
        store.view.slidingDoors.edit = edit
      }),
    setMaterialID: (materialID: keyof typeof slidingDoorMaterials) =>
      setProduce((store) => {
        store.config.slidingDoors.materialID = materialID
      }),
    setVisible: (visible: boolean) =>
      setProduce((store) => {
        store.view.slidingDoors.visible = visible
      }),
    clearSlidingDoors: () => {
      const clearedslidingDoors = get().config.slidingDoors.list.filter((flap) => flap.pos.y < get().config.rows.list.length && flap.pos.x < get().config.main.grid)
      clearedslidingDoors.sort((a, b) => a.pos.y - b.pos.y || a.pos.x - b.pos.x)
      get().config.main.shelfTyp === 2 && clearedslidingDoors.splice(0, clearedslidingDoors.length)
      return clearedslidingDoors
    },
  }
  return {
    slidingDoors,
  }
}

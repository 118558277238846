import {IVector} from 'common/threeComponents/FiberBoard'
import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {shelfMaterials} from '../../store/datamodels'
import {ColumnList} from 'products/metrum/partLists/columnList'
import {MetrumColumn} from './MetrumColumn'

export const ConnectedColumns = () => {
  const columnList = ColumnList()
  const materialID = columnList[0].material
  const materialReference = shelfMaterials[materialID].matReference
  const materialObject = MaterialSingleton.getMaterials()
  const material = materialObject[materialReference]?.big || materialObject[materialReference]
  return (
    <>
      {columnList.map((column) => {
        const size = [column.length, column.thickness, column.depth] as IVector
        return <MetrumColumn {...column} size={size} material={material} />
      })}
    </>
  )
}

import {slidingDoorMaterials} from '../../store/datamodels'
import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {SlidingDoorList} from 'products/kubatur/partLists/slidingDoorList'
import {SlidingDoorFC} from './SlidingDoorFC'
import {useStore} from 'products/kubatur/store/store'

export const ConnectedSlidingDoors = (): any => {
  const doorList = SlidingDoorList()
  const visible = useStore((store) => store.view.slidingDoors.visible)
  if (doorList.length > 0) {
    const materialID = doorList[0].material
    const materialReference = slidingDoorMaterials[materialID].matReference
    const materialObject = MaterialSingleton.getMaterials()
    var material = materialObject[materialReference]?.big || materialObject[materialReference]
    var innerMaterial = materialObject[7].small_1
  }
  return (
    <>
      {visible &&
        doorList.map((door) => {
          return <SlidingDoorFC {...door} innerMaterial={innerMaterial} doorMaterial={material} />
        })}
    </>
  )
}

import {forwardRef, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {ThreeEvent} from 'react-three-fiber'
import {BoxGeometry, Euler, Mesh} from 'three'

export type IVector = [number, number, number]
export type IVector3 = {x: number; y: number; z: number}

export interface IBoard {
  size: IVector //length thickness, depth
  material?: THREE.Material | THREE.Material[]
  onClick?: (event: ThreeEvent<MouseEvent>) => void
  onPointerOver?: (event: ThreeEvent<PointerEvent>) => void
  onPointerOut?: (event: ThreeEvent<PointerEvent>) => void
  // position: IVector
  xPos: number
  yPos: number
  zPos: number
  rotation?: IVector
  translate?: IVector
  cursor?: string
}

export const Board = forwardRef((props: IBoard, ref: any) => {
  // This reference will give us direct access to the mesh
  const position = [props.xPos, props.yPos, props.zPos] as IVector
  const rotation = props.rotation ? new Euler(props.rotation[0] * Math.PI, props.rotation[1] * Math.PI, props.rotation[2] * Math.PI) : undefined //check if that works
  // const mesh = useRef<Mesh>(null!)
  const box = useRef<BoxGeometry>(null!)

  //NEEDS TO BE LAYOUTEFFECT to make sure box is set
  useLayoutEffect(() => {
    box.current.rotateY(Math.PI * Math.round(Math.random()))
    props.translate ? box.current.translate(...props.translate) : box.current.translate(0.5, 0.5, 0.5)
  }, [])

  // useEffect(() => {
  //   if (props.parentRef) props.parentRef.current = mesh.current
  // }, [])

  return (
    <mesh ref={ref} scale={props.size || [1, 1, 1]} position={position || [0, 0, 0]} material={props.material} rotation={rotation || [0, 0, 0]}>
      <boxBufferGeometry args={[1, 1, 1]} ref={box} />
      {/* <meshStandardMaterial color={"orange"} /> */}
    </mesh>
  )
})

export const Selector = (props: IBoard) => {
  const rotation = props.rotation ? new Euler(props.rotation[0] * Math.PI, props.rotation[1] * Math.PI, props.rotation[2] * Math.PI) : undefined //check if that works
  // This reference will give us direct access to the mesh
  const mesh = useRef<Mesh>(null!)
  const box = useRef<BoxGeometry>(null!)
  const position = [props.xPos - 0.001, props.yPos - 0.001, props.zPos - 0.001] as IVector
  const size = [props.size[0] + 0.002, props.size[1] + 0.002, props.size[2] + 0.002] as IVector
  const [active, setActive] = useState(false)
  const onPointerOver = (e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation()
    setActive(true)
    document.body.style.cursor = props.cursor || 'pointer'
    if (props.onPointerOver) props.onPointerOver(e)
  }
  const onPointerOut = (e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation() //prevents clickthrough :)
    setActive(false)
    document.body.style.cursor = 'auto'
    if (props.onPointerOut) props.onPointerOut(e)
  }
  const onClick = (e: ThreeEvent<MouseEvent>) => {
    setActive(false)
    e.stopPropagation()
    if (props.onClick) {
      props.onClick(e)
    }
    // bounds.refresh().clip().fit() // needs some time to calculate actually so lets do that elsewhere
  }

  useEffect(() => {
    props.translate ? box.current.translate(...props.translate) : box.current.translate(0.5, 0.5, 0.5)
  }, [])

  return (
    <mesh
      ref={mesh}
      onClick={onClick}
      onPointerOver={onPointerOver}
      onPointerOut={onPointerOut}
      scale={size || [1, 1, 1]}
      position={position || [0, 0, 0]}
      material={props.material}
      rotation={rotation || [0, 0, 0]}>
      <boxBufferGeometry args={[1, 1, 1]} ref={box} />
      {!props.material && <meshStandardMaterial color={'blue'} opacity={active ? 0.3 : 0.1} transparent={true} depthWrite={false} />}
    </mesh>
  )
}

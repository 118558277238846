import {Selector} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/tektur/store/store'
import shallow from 'zustand/shallow'
import {slidingDoorMaterials, shelfMaterials, regalModel} from 'products/tektur/store/datamodels'
import {Fragment} from 'react'

export const ConnectedSlidingDoorSelectors = () => {
  const visible = useStore((store) => store.view.slidingDoors.edit)
  const [boards, width, grid, depth, drawers, slidingDoors] = useStore(
    (store) => [store.config.rows, store.config.main.width, store.config.main.grid, store.config.main.depth, store.config.drawers.list, store.config.slidingDoors.list],
    shallow
  )
  const shelfThickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const slidingDoorThickness = slidingDoorMaterials[useStore((store) => store.config.slidingDoors.materialID)].thickness
  const gridSize = (width - 2 * shelfThickness - regalModel.shadowJoint) / grid
  const setSlidingDoor = useStore((store) => store.slidingDoors.setSlidingDoor)
  let yPos = shelfThickness
  return (
    <>
      {visible &&
        boards.list.map((board, y) => {
          yPos += board.height + shelfThickness
          const drawersInRow = Array.from(new Set(drawers.filter((drawer) => Math.floor(drawer.pos.y) === y).map((el) => el.pos.x))) // get the positions of drawers in the current board
          drawersInRow.push(0) // add the position of the left side of the board
          drawersInRow.sort((a, b) => a - b) // sort the positions
          return drawersInRow.map((el, x) => {
            const nextPos = drawersInRow[x + 1] !== undefined ? drawersInRow[x + 1] : grid // get the position of the next drawer or the right side of the board
            const numOfPossibleSlidingDoors = nextPos - el - (x === 0 ? 1 : 2) // calculate the number of possible sliding doors in the current part of the board
            const slidingDoorsInPart = slidingDoors.filter((slidingDoor) => slidingDoor.pos.y === y && slidingDoor.pos.x >= el && slidingDoor.pos.x < nextPos).map((el) => el.pos.x) // get the positions of sliding doors in the current part
            const length = gridSize - regalModel.shadowJoint // calculate the length of the selector
            const numOfSelectors = numOfPossibleSlidingDoors + (x > 0 ? 2 : 1) // calculate the number of selectors in the current part
            return [...Array(numOfSelectors)].map((_, i) => {
              const xPos = (el + i) * gridSize + shelfThickness + regalModel.shadowJoint // calculate the x position of the selector
              const isDrawer = drawersInRow.includes(el + i) && x !== 0 // check if there is a drawer in the current position
              const isSlidingDoor = slidingDoorsInPart.includes(el + i) // check if there is a sliding door in the current position
              const isPossible = numOfPossibleSlidingDoors - slidingDoorsInPart.length > 0 // check if there is still space for a sliding door
              return (
                <Fragment key={y + '.' + (x + i)}>
                  {(isPossible || isSlidingDoor || isDrawer) && ( // if there is a sliding door or there is still space for one, render the selector
                    <Selector
                      key={y + '.' + (x + i)}
                      size={[board.height, slidingDoorThickness, length]}
                      xPos={xPos}
                      yPos={yPos - board.height - shelfThickness}
                      zPos={depth - 0.001 - slidingDoorThickness}
                      onClick={() => setSlidingDoor({x: el + i, y: y})}
                      rotation={[0, 0.5, 0.5]}
                      translate={[0.5, 0.5, 0.5]}
                    />
                  )}
                </Fragment>
              )
            })
          })
        })}
    </>
  )
}

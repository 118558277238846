import {useStore} from 'products/metrum/store/store'
import {shelfMaterials, footModel} from 'products/metrum/store/datamodels'
import {IVector} from 'common/threeComponents/FiberBoard'

export const FootList = () => {
  const columns = useStore((store) => store.config.columns.list)
  const feetID = useStore((store) => store.config.feet.id)
  const depth = useStore((store) => store.config.main.depth)
  const shelfThickness = useStore((store) => shelfMaterials[store.config.main.materialID].thickness)
  const feet: {key: string; pos: IVector; size: IVector; material: string; type: keyof typeof footModel}[] = []

  columns.forEach((el, index) => {
    const xPos = columns.slice(0, index).reduce((acc, el) => acc + el.width + shelfThickness, shelfThickness)
    feet.push({key: index + '.VL', pos: [xPos + 0.02 || 0, 0, depth - 0.06], size: [0.0275, 0.043, 0.0275], material: 'solidBlack', type: feetID})
    feet.push({key: index + '.VR', pos: [xPos + el.width - 0.02 || 0, 0, depth - 0.06], size: [0.0275, 0.043, 0.0275], material: 'solidBlack', type: feetID})
    feet.push({key: index + '.HL', pos: [xPos + 0.02 || 0, 0, 0.06], size: [0.0275, 0.043, 0.0275], material: 'solidBlack', type: feetID})
    feet.push({key: index + '.HR', pos: [xPos + el.width - 0.02 || 0, 0, 0.06], size: [0.0275, 0.043, 0.0275], material: 'solidBlack', type: feetID})
  })

  return feet
}

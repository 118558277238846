import create from 'zustand'
import {mountStoreDevtool} from 'simple-zustand-devtools'
import {API_DOMAIN, perspectives} from 'utils/datamodels'
import {saveConfig} from 'utils/API/components/SaveLoadModal'
import {useStore as useAnalogStore} from 'products/analog/store/store'
import {useStore as useDigitalStore} from 'products/digital/store/store'
import {useStore as useDasregalStore} from 'products/dasregal/store/store'
import {useStore as useKubaturStore} from 'products/kubatur/store/store'
import {useStore as useTekturStore} from 'products/tektur/store/store'
import {useStore as useMetrumStore} from 'products/metrum/store/store'

export enum ELocalFactorID {
  DE_NETTO,
  DE,
  CH,
  AT,
  IT,
  EXPORT,
}

interface IStore {
  localFactorID: ELocalFactorID
  perspective: keyof typeof perspectives
  expandedFirst: string | false
  expandedSecond: string | false
  extended: boolean
  setExtended: (extended: boolean) => void
  setExpandedFirst: (newExpanded: string | false) => void
  setExpandedSecond: (newExpanded: string | false) => void
  setLocalFactorID: (newLocalFactorID: ELocalFactorID) => void
  fetchConfig: (id: string) => Promise<any>
  saveConfig: (reference: string) => Promise<any>
  setPerspective: (newPerspective: keyof typeof perspectives) => void
}

export const useCommonStore = create<IStore>((set) => {
  const localFactorID: ELocalFactorID = localStorage.getItem('sj-local') !== null ? parseInt(localStorage.getItem('sj-local')!) : ELocalFactorID.DE
  const perspective = 'front'
  const extended = false

  const setExtended = (extended: boolean) => {
    set({extended: extended})
  }

  const setPerspective = (newPerspective: keyof typeof perspectives) => {
    set({perspective: newPerspective})
  }

  const setLocalFactorID = (localFactorID: ELocalFactorID) => {
    const localFactorIDString = localFactorID.toString()
    localStorage.setItem('sj-local', localFactorIDString)
    set({localFactorID: localFactorID})
  }

  const expandedFirst = false
  const setExpandedFirst = (newExpanded: string | false) => {
    set({expandedFirst: newExpanded})
  }

  const expandedSecond = false
  const setExpandedSecond = (newExpanded: string | false) => {
    set({expandedSecond: newExpanded})
  }

  const useProductStores = {
    analog: useAnalogStore,
    digital: useDigitalStore,
    dasregal: useDasregalStore,
    kubatur: useKubaturStore,
    metrum: useMetrumStore,
    tektur: useTekturStore,
  }

  const fetchConfig = async (uri: string) => {
    // const response = await fetch(uri)
    console.log(`${API_DOMAIN}/product/${uri}`)

    const product = await fetch(`${API_DOMAIN}/product/${uri}`)
      .then((r) => r.json())
      .then((data) => {
        const config = JSON.parse(data.configuration)
        const reference = data.reference
        const uri = data.uri
        useProductStores[reference as keyof typeof useProductStores].setState({config: config, uri: uri, savedConfig: config})
        return {config, reference, uri}
      })
      .catch((err) => console.log(err))
    //set({config: await response.json()})
    return product
  }

  //We repeat that in every store ? TODO -> DRY it up
  const _saveConfig = async (reference: string) => {
    let uri = ''
    const clearConfig = useProductStores[reference as keyof typeof useProductStores].getState().clearConfig()
    const savedConfig = useProductStores[reference as keyof typeof useProductStores].getState().savedConfig
    if (JSON.stringify(clearConfig) !== JSON.stringify(savedConfig)) {
      console.log('saving config', savedConfig, clearConfig)
      const baseUrl = `${API_DOMAIN}/product` //API URL
      const jsonConfig = JSON.stringify(clearConfig) //json the current config
      const body = {reference: reference, configuration: jsonConfig}
      uri = await saveConfig(baseUrl, body).then((data) => {
        const newUri = data.uri //get uri from response
        useProductStores[reference as keyof typeof useProductStores].setState({uri: newUri}) // set uri in state
        return data // return data to tmpData for further use
      })
      useProductStores[reference as keyof typeof useProductStores].setState({savedConfig: clearConfig as any})
    } else {
      return {uri: useProductStores[reference as keyof typeof useProductStores].getState().uri}
    }
    return uri // return tmpData
  }

  return {
    localFactorID,
    perspective,
    expandedFirst,
    expandedSecond,
    extended,
    setExtended,
    setExpandedFirst,
    setExpandedSecond,
    saveConfig: _saveConfig,
    fetchConfig,
    setLocalFactorID,
    setPerspective,
  }
})

if (process.env.NODE_ENV === 'development') {
  //@ts-ignore
  mountStoreDevtool('CommonStore', useCommonStore)
}

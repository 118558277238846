// import Board from "common/threeComponents/Board"
import {Board, IVector} from 'common/threeComponents/FiberBoard'
import {BackpanelList} from 'products/metrum/partLists/backpanelList'
import {backpanelMaterials} from '../../store/datamodels'
import {MaterialSingleton} from '../../../../common/threeComponents/Materials'

export const ConnectedBackpanels = () => {
  const backpanelList = BackpanelList()
  const materialID = backpanelList[0].material
  const materialReference = backpanelMaterials[materialID].matReference
  const materialObject = MaterialSingleton.getMaterials()
  const material = [materialObject[materialReference]?.small_1, materialObject[materialReference]?.small_2] || materialObject[materialReference]
  return (
    <>
      {backpanelList.map((board) => {
        const size = [board.length, board.thickness, board.depth] as IVector
        return <Board {...board} size={size} material={material[Math.round(Math.random())]} rotation={[0, 0.5, 0.5]} />
      })}
    </>
  )
}

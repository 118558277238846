import {Vector3} from 'three'

import Ash from 'common/static/textures/ash.jpg'
import Oak from 'common/static/textures/oak.jpg'
import WalnutEuro from 'common/static/textures/walnut-euro.jpg'
import Maple from 'common/static/textures/maple.jpg'
import Birch from 'common/static/textures/birch.jpg'
import Beech from 'common/static/textures/beech.jpg'
import Cherry from 'common/static/textures/cherry.jpg'
import WalnutAmi from 'common/static/textures/walnut-ami.jpg'
import WalnutBlack from 'common/static/textures/walnut-black.jpg'
import Fir from 'common/static/textures/fir.jpg'
import Pine from 'common/static/textures/pine.jpg'

export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || 'https://back.sanktjohanser.net'

export const localFactors = [
  {id: 0, factor: 1.0, name: 'DE', fullname: 'Deutschland', vat: 'netto'},
  {id: 1, factor: 1.19, name: 'DE', fullname: 'Deutschland', vat: 'inkl. 19% Mwst'},
  {id: 2, factor: 1.0, name: 'CH', fullname: 'Schweiz', vat: 'netto'},
  {id: 3, factor: 1.0, name: 'AT', fullname: 'Österreich', vat: 'netto'},
  {id: 4, factor: 1.0, name: 'IT', fullname: 'Italien', vat: 'netto'},
  {id: 5, factor: 1.1, name: 'EXPORT', fullname: 'Export', vat: 'netto'},
]

// prettier-ignore
export const materialModel = {
  h2SWBC: {id: 'h2SWBC', price: 56, matReference: 'hplWhite', thickness: 0.002, treatment: false, type: 2, name: 'weiss', color: '#ebebeb', insideFront: true},
  h2SB: {id: 'h2SB', price: 56, matReference: 'hplBlack', thickness: 0.002, treatment: false, type: 2, name: 'schwarz', color: '#000000', insideFront: true},
  h2Al: {id: 'h2Al', price: 66, matReference: 'hplAlu', thickness: 0.002, treatment: false, type: 2, name: 'Aluminium', color: '#a9a9a9', insideFront: true},
  h3Ei: {id: 'h3Ei', price: 147, matReference: 'hplOak', thickness: 0.003, treatment: true, type: 2, name: 'Eiche furniert', src: Oak, insideFront: true},
  h3aN: {id: 'h3aN', price: 147, matReference: 'hplNut', thickness: 0.003, treatment: true, type: 2, name: 'Nussbaum furniert', src: WalnutAmi, insideFront: true},

  h6TWBC: {id: 'h6TWBC', matReference: 'hplWhite', thickness: 0.006, price: 58, maxWidth: 2.8, drawerInnerMaterial: 'solidWhite', treatment: false, type: 2, name: 'struktur weiss, schw. Kern', color: '#ebebeb'}, //
  h6SWBC: {id: 'h6SWBC', matReference: 'hplWhite', thickness: 0.006, price: 94, maxWidth: 2.8, drawerInnerMaterial: 'solidWhite', treatment: false, type: 2, name: 'softtouch weiss, schw. Kern', color: '#ebebeb'}, //
  h6TB: {id: 'h6TB', matReference: 'hplBlack', thickness: 0.006, price: 58, maxWidth: 2.8, drawerInnerMaterial: 'solidBlack', treatment: false, type: 2, name: 'struktur schwarz', color: '#000000'},
  h6TW: {id: 'h6TW', matReference: 'hplPureWhite', thickness: 0.006, price: 101, maxWidth: 2.8, drawerInnerMaterial: 'solidWhite', treatment: false, type: 2, name: 'struktur weiss', color: '#ebebeb'},
  h6SB: {id: 'h6SB', matReference: 'hplBlack', thickness: 0.006, price: 94, maxWidth: 2.8, drawerInnerMaterial: 'solidBlack', treatment: false, type: 2, name: 'softtouch schwarz', color: '#000000'},
  h6SW: {id: 'h6SW', matReference: 'hplPureWhite', thickness: 0.006, price: 116, maxWidth: 2.8, drawerInnerMaterial: 'solidWhite', treatment: false, type: 2, name: 'softtouch weiss', color: '#ebebeb'},

  mdf8W: {id: 'mdf8W', matReference: 7, thickness: 0.008, price: 10, maxWidth: 2.8, treatment: false, type: 4, name: 'melamin weiss', shortName:'weiss melam.', color: '#ebebeb'},
  h8TB: {id: 'h8TB', matReference: 'hplBlack', thickness: 0.008, price: 94, maxWidth: 2.8, drawerInnerMaterial: 'solidBlack', treatment: false, type: 2, name: 'struktur schwarz', color: '#000000'},
  f9Ah: {id: 'f9Ah', price: 65, matReference: 0, thickness: 0.008, treatment: true, shelfReference: ['m18Ah', 's19Ah'], type:5, name: 'Ahorn funiert', src: Maple},
  f9Bi: {id: 'f9Bi', price: 65, matReference: 1, thickness: 0.008, treatment: true, shelfReference: ['m18Bi', 's19Bi'], type:5, name: 'Birke funiert', src: Birch},
  f9Bu: {id: 'f9Bu', price: 65, matReference: 2, thickness: 0.008, treatment: true, shelfReference: ['m18Bu', 's19Bu'], type:5, name: 'Buche funiert', src: Beech},
  f9Ei: {id: 'f9Ei', price: 65, matReference: 3, thickness: 0.008, treatment: true, shelfReference: ['m18Ei', 's19Ei'], type:5, name: 'Eiche funiert', src: Oak},
  f9Es: {id: 'f9Es', price: 65, matReference: 4, thickness: 0.008, treatment: true, shelfReference: ['m18Es', 's19Es'], type:5, name: 'Esche funiert', src: Ash},
  f9Ki: {id: 'f9Ki', price: 75, matReference: 5, thickness: 0.008, treatment: true, shelfReference: ['m18Ki', 's19Ki'], type:5, name: 'Kirschbaum funiert', src: Cherry},
  f9aN: {id: 'f9aN', price: 75, matReference: 6, thickness: 0.008, treatment: true, shelfReference: ['m18aN', 's19aN'], type:5, name: 'Nussbaum funiert', src: WalnutAmi},

  h10TWBC: {id: 'h10TWBC', matReference: 'hplWhite', thickness: 0.010, price: 60, maxWidth: 2.8, drawerInnerMaterial: 'solidWhite', treatment: false, type: 2, name: 'struktur weiss mit schwarzem Kern', color: '#ebebeb'},
  h10SWBC: {id: 'h10SWBC', matReference: 'hplWhite', thickness: 0.010, price: 60, maxWidth: 2.8, drawerInnerMaterial: 'solidWhite', treatment: false, type: 2, name: 'softtouch weiss mit schwarzem Kern', color: '#ebebeb'},
  h10TB: {id: 'h10TB', matReference: 'hplBlack', thickness: 0.010, eModul: 14000, weight: 1400, price: 79, maxWidth: 2.8, drawerInnerMaterial: 'solidBlack', treatment: false, type: 2, name: 'struktur schwarz', color: '#000000'},
  h10TW: {id: 'h10TW', matReference: 'hplPureWhite', thickness: 0.010, eModul: 14000, weight: 1400, price: 154, maxWidth: 2.8, drawerInnerMaterial: 'solidWhite', treatment: false, type: 2, name: 'struktur weiss', color: '#ebebeb'},
  h10SB: {id: 'h10SB', matReference: 'hplBlack', thickness: 0.010, eModul: 14000, weight: 1400, price: 118, maxWidth: 2.8, drawerInnerMaterial: 'solidBlack', treatment: false, type: 2, name: 'softtouch schwarz', color: '#000000'},
  h10SW: {id: 'h10SW', matReference: 'hplPureWhite', thickness: 0.010, eModul: 14000, weight: 1400, price: 153, maxWidth: 2.8, drawerInnerMaterial: 'solidWhite', treatment: false, type: 2, name: 'softtouch weiss', color: '#ebebeb'},
  h12SB: {id: 'h12SB', matReference: 'hplBlack', thickness: 0.012, eModul: 14000, weight: 1400, price: 142, maxWidth: 4, drawerInnerMaterial: 'solidBlack', treatment: false, type: 2, name: 'softtouch schwarz', color: '#000000'},
  h12SW: {id: 'h12SW', matReference: 'hplPureWhite', thickness: 0.012, eModul: 14000, weight: 1400, price: 180, maxWidth: 4, drawerInnerMaterial: 'solidWhite', treatment: false, type: 2, name: 'softtouch weiss', color: '#ebebeb'},

  m12Ei: {id: 'm12Ei', matReference: 3, thickness: 0.012, eModul: 10720, weight: 680, price: 110, maxWidth: 3.58, fallback: 's13Ei', drawerInnerMaterial: 'm12Ei', treatment: true, type: 0, name: 'eiche', src: Oak},
  m12MW: {id: 'm12MW', matReference: 7, thickness: 0.012, eModul: 10720, weight: 680, price: 54, maxWidth: 2.98, fallback: 's13Ei', drawerInnerMaterial: 'm12MW', treatment: false, type: 0, name: 'melamin weiss', shortName:'weiss melam.', color: '#ebebeb'},
  m12PB: {id: 'm12PB', matReference: 9, thickness: 0.012, eModul: 10720, weight: 680, price: 37, maxWidth: 2.98, fallback: 's13Ei', drawerInnerMaterial: 'm12PB', treatment: false, type: 0, name: 'phenol schwarz', color: '#050505'},
  m12CB: {id: 'm12CB', matReference: 9, thickness: 0.012, eModul: 10720, weight: 680, price: 66, maxWidth: 2.98, fallback: 's13Ei', drawerInnerMaterial: 'solidBlack', treatment: false, type: 0, name: 'cpl schwarz', color: '#050505'},
  s13Ah: {id: 's13Ah', matReference: 11, thickness: 0.013, price: {2.49: 125, 2.99: 125, 3.65: 235}, maxWidth: 2.98, fallback: 'm12Ah', drawerInnerMaterial: 's13Ah', treatment: true, type: 1, name: 'ahorn', src: Maple},
  s13Bi: {id: 's13Bi', matReference: 12, thickness: 0.013, price: {2.49: 79, 2.99: 79, 3.65: 200}, maxWidth: 2.98, fallback: 'm12Bi', drawerInnerMaterial: 's13Bi', treatment: true, type: 1, name: 'birke', src: Birch},
  s13Bu: {id: 's13Bu', matReference: 13, thickness: 0.013, price: {2.49: 79, 2.99: 79, 3.65: 200}, maxWidth: 2.98, fallback: 'm12Bu', drawerInnerMaterial: 's13Bu', treatment: true, type: 1, name: 'buche', src: Beech},
  s13Ei: {id: 's13Ei', matReference: 14, thickness: 0.013, eModul: 13000, weight: 710, price: 160, maxWidth: 2.98, fallback: 'm12Ei', drawerInnerMaterial: 's13Ei', treatment: true, type: 1, name: 'eiche', src: Oak},
  s13Es: {id: 's13Es', matReference: 15, thickness: 0.013, price: {2.49: 125, 2.99: 125, 3.65: 235}, maxWidth: 2.98, fallback: 'm12Es', drawerInnerMaterial: 's13Es', treatment: true, type: 1, name: 'esche', src: Ash},
  s13Ki: {id: 's13Ki', matReference: 16, thickness: 0.013, price: {2.49: 153, 2.99: 153, 3.65: 153}, maxWidth: 2.98, fallback: 'm12Ki', drawerInnerMaterial: 's13Ki', treatment: true, type: 1, name: 'kirschbaum', src: Cherry},
  s13aN: {id: 's13aN', matReference: 17, thickness: 0.013, price: {2.49: 243, 2.99: 153, 3.65: 243}, maxWidth: 2.98, fallback: 'm12aN', drawerInnerMaterial: 's13aN', treatment: true, type: 1, name: 'nussbaum amerikanisch', src: WalnutAmi},

  mdf19W: {id: 'mdf19W', matReference: 7, thickness: 0.019, price: 12, maxWidth: 2.8, treatment: false, type: 4, name: 'melamin weiss', color: '#ebebeb'},
  m18Ah: {id: 'm18Ah', matReference: 0, thickness: 0.018, price: {2.49: 66, 2.99: 110, 3.65: 121}, maxWidth: 3.58, fallback: 's19Ah', drawerInnerMaterial: 's13Ah', treatment: true, type: 0, name: 'ahorn', src: Maple},
  m18Bi: {id: 'm18Bi', matReference: 1, thickness: 0.018, price: {2.49: 66, 2.99: 110, 3.65: 121}, maxWidth: 3.58, fallback: 's19Bi', drawerInnerMaterial: 's13Bi', treatment: true, type: 0, name: 'birke', src: Birch},
  m18Bu: {id: 'm18Bu', matReference: 2, thickness: 0.018, price: {2.49: 66, 2.99: 110, 3.65: 121}, maxWidth: 3.58, fallback: 's19Bu', drawerInnerMaterial: 's13Bu', treatment: true, type: 0, name: 'buche', src: Beech},
  m18Ei: {id: 'm18Ei', matReference: 3, thickness: 0.018, price: {2.49: 66, 2.99: 110, 3.65: 121}, maxWidth: 3.58, fallback: 's19Ei', drawerInnerMaterial: 'm12Ei', treatment: true, type: 0, name: 'eiche', src: Oak},
  m18Es: {id: 'm18Es', matReference: 4, thickness: 0.018, price: {2.49: 66, 2.99: 110, 3.65: 121}, maxWidth: 3.58, fallback: 's19Es', drawerInnerMaterial: 's13Es', treatment: true, type: 0, name: 'esche', src: Ash},
  m18Ki: {id: 'm18Ki', matReference: 5, thickness: 0.018, price: {2.99: 110, 3.65: 121}, maxWidth: 3.58, fallback: 's19Ki', drawerInnerMaterial: 's13Ki', treatment: true, type: 0, name: 'kirschbaum', src: Cherry},
  m18aN: {id: 'm18aN', matReference: 6, thickness: 0.018, price: {2.99: 110, 3.65: 121}, maxWidth: 3.58, fallback: 's19aN', drawerInnerMaterial: 's13aN', treatment: true, type: 0, name: 'amerik. nussbaum', src: WalnutEuro},
  m18MW: {id: 'm18MW', matReference: 7, thickness: 0.018, price: {2.49: 65, 2.99: 65}, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'm12MW', treatment: false, type: 0, name: 'melamin weiss', shortName: 'mel weiss', color: '#ebebeb'},
  m18SW: {id: 'm18SW', matReference: 8, thickness: 0.019, price: {2.49: 108, 2.99: 108}, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'solidWhite', treatment: false, type: 0, name: 'hpl softtouch weiss', color: '#ebebeb'},
  m18CB: {id: 'm18CB', matReference: 9, thickness: 0.018, price: {2.49: 50, 2.99: 50}, maxWidth: 2.98, fallback: 's19Ei', drawerInnerMaterial: 'solidBlack', treatment: false, type: 0, name: 'cpl schwarz', color: '#050505'},
  m18SB: {id: 'm18SB', matReference: 10, thickness: 0.019, price: {2.49: 108, 2.99: 108, 3.5:180}, maxWidth: 3.5, fallback: 's19Ei', drawerInnerMaterial: 'solidBlack', treatment: false, type: 0, name: 'hpl softtouch schwarz', color: '#090909'},
  s19Ah: {id: 's19Ah', matReference: 11, thickness: 0.019, price: {2.99: 125, 3.65: 235}, maxWidth: 3.5, fallback: 'm18Ah', drawerInnerMaterial: 's13Ah', treatment: true, type: 1, name: 'ahorn', src: Maple},
  s19Bi: {id: 's19Bi', matReference: 12, thickness: 0.019, price: {2.99: 79, 3.65: 200}, maxWidth: 3.5, fallback: 'm18Bi', drawerInnerMaterial: 's13Bi', treatment: true, type: 1, name: 'birke', src: Birch},
  s19Bu: {id: 's19Bu', matReference: 13, thickness: 0.019, price: {2.99: 79, 3.65: 200}, maxWidth: 3.5, fallback: 'm18Bu', drawerInnerMaterial: 's13Bu', treatment: true, type: 1, name: 'buche', src: Beech},
  s19Ei: {id: 's19Ei', matReference: 14, thickness: 0.019, price: {2.99: 125, 3.65: 235}, maxWidth: 3.5, fallback: 'm18Ei', drawerInnerMaterial: 's13Ei', treatment: true, type: 1, name: 'eiche', src: Oak},
  s19Es: {id: 's19Es', matReference: 15, thickness: 0.019, price: {2.99: 125, 3.65: 235}, maxWidth: 3.5, fallback: 'm18Es', drawerInnerMaterial: 's13Es', treatment: true, type: 1, name: 'esche', src: Ash},
  s19Ki: {id: 's19Ki', matReference: 16, thickness: 0.019, price: 153, maxWidth: 2.98, fallback: 'm18Ki', drawerInnerMaterial: 's13Ki', treatment: true, type: 1, name: 'kirschbaum', src: Cherry},
  s19aN: {id: 's19aN', matReference: 17, thickness: 0.019, price: 243, maxWidth: 2.98, fallback: 'm18aN', drawerInnerMaterial: 's13aN', treatment: true, type: 1, name: 'nussbaum amerikanisch', src: WalnutAmi},
  s19eN: {id: 's19eN', matReference: 18, thickness: 0.019, price: 267, maxWidth: 2.98, fallback: 'm18aN', drawerInnerMaterial: 's13aN', treatment: true, type: 1, name: 'nussbaum europäisch', src: WalnutEuro},
  s19sN: {id: 's19sN', matReference: 19, thickness: 0.019, price: 340, maxWidth: 2.98, fallback: 'm18aN', drawerInnerMaterial: 's13aN', treatment: true, type: 1, name: 'schwarznuss', src: WalnutBlack},
  s19Ta: {id: 's19Ta', matReference: 'sTa', thickness: 0.019, price: 100, maxWidth: 2.98, fallback: 'm18Ei', drawerInnerMaterial: 's19Ta', treatment: true, type: 1, name: 'Tanne', src: Fir},
  s19Zi: {id: 's19Zi', matReference: 'sZi', thickness: 0.019, price: 162, maxWidth: 2.98, fallback: 'm18Ei', drawerInnerMaterial: 's19Zi', treatment: true, type: 1, name: 'Zirbe', src: Pine},

  solidWhite: {id: 'solidWhite', matReference: 'solidWhite', thickness: 0.013, price: 0, maxWidth: 2.98, fallback: 'm18MW', drawerInnerMaterial: 'm12MW', treatment: false, type: 2, name: 'weiss'},
  solidBlack: {id: 'solidBlack', matReference: 'solidBlack', thickness: 0.013, price: 0, maxWidth: 2.98, fallback: 'm18CB', drawerInnerMaterial: 'm12CB', treatment: false, type: 2, name: 'schwarz'},
}

export const materialTypes = [
  {id: 0, name: 'multiplex', shortName: 'mpx'},
  {id: 1, name: 'massiv', shortName: ''},
  {id: 2, name: 'hpl'},
  {id: 3, name: '3-Schicht', shortName: '3S'},
  {id: 4, name: 'mdf'},
  {id: 5, name: ''},
]

export const drawerLengths = [0.25, 0.3, 0.32, 0.35, 0.38, 0.4, 0.42, 0.45, 0.48, 0.5, 0.52, 0.55, 0.58, 0.6]
export const drawerSideInset = 0.0205

export const materialTreatments = [
  {id: 0, price: 25, setup: 0, name: 'geölt'},
  {id: 1, price: 25, setup: 0, name: 'hell geölt'},
  {id: 2, price: 32.5, setup: 10, name: 'geseift'},
  {id: 3, price: 35, setup: 30, name: 'lackiert'},
]

// geometry: [{type: "round" | "rect" | "castor", dia?: number, sizeX?: number, sizeZ?:number, height: number | "variable", matReference:"materialSingleton", yPos?: number | -number (sticking on top)}
export const footTypeModel = [
  {id: 0, geometry: [{type: 'round', dia: 0.055, height: 'variable', matReference: 'solidBlack'}], name: 'Stellfuss rund'},
  {id: 1, geometry: [{type: 'round', dia: 0.03, height: 'variable', matReference: 'solidBlack'}], name: 'Stellfuss rund'},
  {id: 2, geometry: [{type: 'rect', sizeX: 0.01, sizeZ: 0.06, height: 'variable', matReference: 'solidBlack'}], name: 'Stellfuss rechteckig'},
  {
    id: 10,
    geometry: [
      {type: 'rect', sizeX: 0.012, sizeZ: 0.018, height: 0.003, matReference: 'solidBlack'},
      {type: 'rect', sizeX: 0.012, sizeZ: 0.018, height: 0.0015, yPos: 0.003, matReference: 'solidAlu'},
      {type: 'round', dia: 0.012, height: 'variable', yPos: 0.005, matReference: 'solidAlu'},
    ],
    name: 'Stellfuss rund',
  },
  {
    id: 11,
    geometry: [
      {type: 'round', dia: 0.03, height: 0.005, matReference: 'solidBlack'},
      {type: 'round', dia: 0.008, height: 'variable', yPos: 0.005, matReference: 'solidAlu'},
      {type: 'rect', sizeX: 0.03, sizeZ: 0.019, height: 0.025, yPos: -0.025, matReference: 'solidBlack'},
    ],
    name: 'Stellfuss rund',
  },
  {id: 20, geometry: [{type: 'castor', sizeX: 0.03, sizeZ: 0.03, yPos: 1, height: 'variable', matReference: 'solidBlack'}], name: 'Rolle'},
]

export const footModel: {[key: string]: {heights: number[]; price: number; range?: number[]; standardHeight?: number; xInset?: number; yInset?: number; sizeXY?: [number, number]; name: string}} = {
  wallMounted: {heights: [0.5], price: 0, name: 'wandmontiert'}, // wallmounted
  s23: {heights: [0.023], range: [-0.004, 0.004], xInset: 0.05, yInset: 0.05, sizeXY: [0.0275, 0.0275], price: 0, name: 'Stellfuss rund'}, // Standard Stellfuss von Häfele
  u308: {heights: [0.043], range: [-0.007, 0.007], xInset: 0.05, price: 0, name: 'Stellfuss rund'}, // Stellfuss in Gewindebuchse für U-Profile
  gn832: {heights: [0.03], range: [-0.006, 0.006], xInset: 0.06, price: 0, name: 'Stellfuss rund'}, // Gelenkfuss schwarz von Ganter
  gn840: {heights: [0.025], range: [-0.01, 0.01], xInset: 0.027, price: 0, name: 'Stellfuss rund'}, // Gelenkfuss schwarz von Ganter
  shv: {heights: [0.018], range: [-0.012, 0.012], xInset: 0.05, price: 0, name: 'Stellfuss'}, // Sockelhöhenversteller von Häfele / Würth
  rnd30: {heights: [0.1, 0.15, 0.2], standardHeight: 1, range: [0, 0.004], xInset: 0.1, price: 12.12, name: 'Rundfuss'}, // Rundfuss von Opo
  rct16: {heights: [0.1, 0.15, 0.2], standardHeight: 1, range: [0, 0.004], xInset: 0.1, price: 12.12, name: 'Rechteckfuss'}, // Rechteckfuss
  castor43: {heights: [0.043], xInset: 0.05, yInset: 0.03, sizeXY: [0.0175, 0.0175], price: 5, name: 'Lenkrolle'}, // Rolle
}

export const cameraPosition = new Vector3(0, 1.25, 8)

export const perspectives = {
  front: {x: 0, y: 0},
  iso: {x: -30, y: 0},
  side: {x: -90, y: 0},
  back: {x: 180, y: 0},
}

export const apertureAngles = {
  doors: [0, 0],
  flaps: [0, -1],
}

const workPrices = {
  Faktor: 3.4,
  Oelen: 25,
  Kante: 1.5,
  Lohn: 65,
  Verschnitt: 1.2,
  Handling: 3.5,
  Zuschnitt: 3.5,
  CNC: 100,
  Werkstueckwechsel: 120,
  Setup: 15,
  Werkzeugwechsel: 25,
  Bohrloch: 1.5,
  Formatieren: 12,
  Fasen: 12,
  Schleifen: 25,
  Nuten: 4,
  KnappBeschlag: 0.4,
  ClamexBeschlag: 1,
  AluZ: 8.1,
  AluFlach: 4,
  Klebeband: 0.8,
  ZeitSTVL: 20,
  ZeitIL: 8,
  BeschlagSK: 27,
  Topfband: 13,
  Klappenband: 5.1,
  SeilzugKlappenHalter: 48,
  ZeitTuer: 50,
  ZeitKlappe: 30,
  CNCZeitTopfband: 45,
  CNCZeitKlappenband: 45,
  ZeitRW: 8,
  ZeitSK: 0,
  WandRegalBoden: 12,
  WandRegalSeite: 6,
  Stahlprofil: 10,
  Kleiderstange: 100 / 3.4,
  AusrichtBeschlag: 28,
  SchiebetuerBeschlag: 95,
  ZeitSchiebetuer: 30,
  SchiebetuerSchiene: 55,
  ZeitSchiebetuerSchiene: 25,
}

export const partNames = {
  top: 'Boden',
  bottom: 'Boden',
  bottomBoard: 'Doppelter Boden',
  board: 'Boden',
  column: 'Aussenseite',
  middleColumn: 'Mittelseite',
  subColumn: 'Zwischenseite',
  backpanel: 'Rückwand',
  door: 'Tür',
  offdoor: 'Schiebetür VL',
  innerdoor: 'Schiebetür IL',
  slidingDoor: 'Schiebetür',
  slidingDoorBase: 'Schiebetür UK',
  slidingDoorFront: 'Schiebetür Front',
  flapFront: 'Klappe',
  tabelar: 'Fachboden',
  drawerInsideFront: 'SK Vorne / Hinten',
  drawerBack: 'SK Vorne / Hinten',
  drawerSide: 'SK Seite',
  drawerBottom: 'SK Boden',
  drawerFront: 'SK Front',
}

export {workPrices as w}

import {Foot} from 'common/threeComponents/Foot'
import {materialModel} from 'utils/datamodels'
import {FootList} from 'products/kubatur/partLists/footList'
import {MaterialSingleton} from 'common/threeComponents/Materials'
import {Board} from 'common/threeComponents/FiberBoard'
import {useStore} from 'products/kubatur/store/store'
import {shelfMaterials, shelfTypes} from 'products/kubatur/store/datamodels'

export const ConnectedFeet = () => {
  const feetList = FootList()
  const shelfMaterialID = useStore((state) => state.config.main.materialID)
  const shelfTyp = useStore((state) => state.config.main.shelfTyp)
  const depth = useStore((state) => state.config.main.depth)
  const shelfThickness = shelfMaterials[shelfMaterialID].thickness
  const columns = useStore((state) => state.config.columns.list)
  const totalWidth = columns.reduce((acc, column) => acc + column.width + shelfThickness, shelfThickness)
  if (feetList.length === 0) return null
  const materialID = feetList[0].material as keyof typeof materialModel
  const materialReference = materialModel[materialID].matReference
  const materialObject = MaterialSingleton.getMaterials()
  const material = materialObject[materialReference] || materialObject[materialReference]
  const shelfMaterialReference = shelfMaterials[shelfMaterialID].matReference
  const shelfMaterial = materialObject[shelfMaterialReference]?.big || materialObject[shelfMaterialReference]
  return (
    <>
      {feetList.map((foot) => {
        return <Foot {...foot} material={material} />
      })}
      <Board size={[shelfTypes[shelfTyp].bottomInset, shelfThickness, depth]} material={shelfMaterial} rotation={[0, 0, 0.5]} translate={[0.5, -0.5, 0.5]} xPos={shelfThickness} yPos={0} zPos={0} />
      <Board
        size={[shelfTypes[shelfTyp].bottomInset, shelfThickness, depth]}
        material={shelfMaterial}
        rotation={[0, 0, 0.5]}
        translate={[0.5, -0.5, 0.5]}
        xPos={totalWidth - 2 * shelfThickness}
        yPos={0}
        zPos={0}
      />
    </>
  )
}

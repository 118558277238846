import shallow from 'zustand/shallow'
import {TekturPrice} from 'products/tektur/utils/price'
import {useStore} from '../store/store'
import {localFactors} from 'utils/datamodels'
import {useCommonStore} from 'common/Store/CommonStore'
import {shelfMaterials, footModel, shelfTyp} from '../store/datamodels'
import {BoardList} from '../partLists/boardList'
import {ColumnList} from '../partLists/columnList'
import {BackpanelList} from '../partLists/backpanelList'
import {DrawerList} from '../partLists/drawerList'
import {FlapList} from '../partLists/flapList'
import {SlidingDoorList} from '../partLists/slidingDoorList'
import {getMaterialName} from 'utils/helpers'

export function InfoBoxPrint() {
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)]
  const [shelfMaterialID, boards, feet, uri, shelfType] = useStore((state) => [state.config.main.materialID, state.config.rows.list, state.config.feet, state.uri, state.config.main.shelfTyp], shallow)
  const shelfMaterial = shelfMaterials[shelfMaterialID]
  const shelfMaterialName = getMaterialName(shelfMaterialID)
  const width = Math.round(useStore((state) => state.config.main.width) * 1000) / 1000
  const depth = Math.round(useStore((state) => state.config.main.depth) * 1000) / 1000
  const wallMounted = feet.id === 'wallMounted'
  const footHeight = !wallMounted ? feet.height : 0
  const fullHeight = Math.round((boards.reduce((acc, board) => acc + board.height + shelfMaterial.thickness, shelfMaterial.thickness) + footHeight) * 1000)
  const footRange = footModel[feet.id].range || [0, 0]
  const fussWand = !wallMounted
    ? footModel[feet.id].name + ' ' + feet.height * 1000 + ' mm (' + (feet.height + footRange[0]) * 1000 + '-' + (feet.height + footRange[1]) * 1000 + ' mm)'
    : 'wandmontiert'

  const partList = () => {
    const drawerPartList = DrawerList()
      .map((drawer) => Object.values(drawer.parts).map((el) => el))
      .flat() as any[]
    const flapPartList = FlapList()
      .map((flap) => Object.values(flap.parts).map((el) => el))
      .flat() as any[]
    const partList = [...BoardList(), ...ColumnList(), ...BackpanelList(), ...SlidingDoorList(), ...drawerPartList, ...flapPartList]
    return partList
  }

  let nrColumns = 0
  let nrBoards = 0
  let nrBackpanels = 0
  let nrDrawers = 0
  let nrFlaps = 0
  let nrSlidingDoors = 0

  partList().forEach((part) => {
    if (part.type === 'column') nrColumns++
    if (part.type === 'middleColumn') nrColumns++
    if (part.type === 'board') nrBoards++
    if (part.type === 'bottom') nrBoards++
    if (part.type === 'top') nrBoards++
    if (part.type === 'backpanel') nrBackpanels++
    if (part.type === 'drawerFront') nrDrawers++
    if (part.type === 'flapFront') nrFlaps++
    if (part.type === 'slidingDoor') nrSlidingDoors++
  })

  return (
    <div className="description">
      <div className="print_title">{shelfTyp[shelfType].name}</div>
      <div className="sub_title">
        <a href={'/get/' + uri}>ID: {uri}</a>
      </div>
      <div className="printcontainer">
        <div className="col">
          <div className="sub_title">Material</div>
          {shelfMaterialName}
          <div className="sub_title">Abmessungen</div>
          {width * 1000} mm x {depth * 1000} mm x {fullHeight} mm
          <div className="sub_title">lichte Fachhöhen</div>
          {boards.map((board, i) => {
            const width = board.height * 1000
            if (i + 1 < boards.length) {
              return <span key={i}>{Math.round(width)} mm, </span>
            } else {
              return <span key={i}>{Math.round(width)} mm</span>
            }
          })}
          <div className="sub_title">Fuss / Wandmontage</div>
          {fussWand}
        </div>
        <div className="col">
          <div className="sub_title">Regal</div>
          <div> {nrColumns} Seiten</div>
          <div> {nrBoards} Regalböden</div>
          <div> {nrBackpanels} Rückwände, </div>
          <div className="sub_title">Ausstattung</div>
          <div> {nrDrawers} Schubläden, Front </div>
          {shelfType === 1 && <div> {nrSlidingDoors} Schiebetüren </div>}
          {shelfType === 2 && <div> {nrFlaps} Klappen </div>}
          <div className="price_title">Preis </div>
          <div className="price">
            <TekturPrice /> €
          </div>
          <div className="left">ohne Lieferung / Montage</div>
          <div className="right">{localFactor.vat}</div>
        </div>
      </div>
    </div>
  )
}

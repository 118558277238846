import {useStore} from 'products/kubatur/store/store'
import shallow from 'zustand/shallow'
import {regalModel, shelfMaterials, frontMaterials} from 'products/kubatur/store/datamodels'
import {accHelper} from 'utils/helpers'

export const DoorList = () => {
  const doorList = useStore((store) => store.config.doors.list)
  const [depth, shelfHeight, columns, grid, shelfMaterial, doorMaterial, shelfTyp] = useStore(
    (store) => [
      store.config.main.depth,
      store.config.main.height,
      store.config.columns.list,
      store.config.main.grid,
      store.config.main.materialID,
      store.config.doors.materialID,
      store.config.main.shelfTyp,
    ],
    shallow
  )
  const shelfThickness = shelfMaterials[shelfMaterial].thickness
  const doorThickness = frontMaterials[doorMaterial].thickness
  const gridSize = (shelfHeight - shelfThickness) / grid
  const shadowJoint = regalModel.shadowJoint
  const doors: {
    key: string
    xPos: number
    yPos: number
    zPos: number
    length: number
    depth: number
    thickness: number
    material: keyof typeof frontMaterials
    side: 'left' | 'right'
    type: string
  }[] = []
  if (shelfTyp === 1) return doors
  doorList.forEach((door, j) => {
    if (accHelper.is_in_column(door.pos.x, columns.length)) {
      const isDoorOnTheLeft = doorList.filter((d) => Math.abs(d.pos.x + d.size.x - Math.floor(door.pos.x)) < 0.01).length > 0
      const isDoorOnTheRight = doorList.filter((d) => Math.floor(door.pos.x + 1) === d.pos.x).length > 0
      const extraWidthLeft = Math.floor(door.pos.x) === 0 ? shelfThickness + shadowJoint : isDoorOnTheLeft ? (shelfThickness + shadowJoint) / 2 : 0
      const extraWidthRight = Math.floor(door.pos.x + 1) === columns.length ? shelfThickness + shadowJoint : isDoorOnTheRight ? (shelfThickness + shadowJoint) / 2 : 0
      const extraHeight = door.pos.y + door.size.y === grid ? shelfThickness + shadowJoint : 0
      const height = door.size.y * gridSize - shadowJoint + extraHeight
      const width = (columns[Math.floor(door.pos.x)].width - shadowJoint + extraWidthLeft + extraWidthRight) * door.size.x - shadowJoint
      const xPos =
        columns
          .slice(0, Math.floor(door.pos.x))
          .reduce(
            (pv, cv) => pv + cv.width + shelfThickness,
            shelfThickness + shadowJoint + (columns[Math.floor(door.pos.x)].width - shadowJoint + extraWidthLeft + extraWidthRight) * (door.pos.x - Math.floor(door.pos.x))
          ) - extraWidthLeft
      const yPos = door.pos.y * gridSize
      doors.push({
        key: xPos + '-' + yPos,
        xPos: xPos,
        yPos: yPos,
        zPos: depth + regalModel.doorDistance,
        length: height,
        depth: width,
        thickness: doorThickness,
        material: doorMaterial,
        side: door.side,
        type: 'door',
      })
    }
  })

  return doors
}

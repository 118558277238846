import {useStore} from 'products/dasregal/store/store'
import shallow from 'zustand/shallow'
import {regalModel, shelfMaterials, frontMaterials} from 'products/dasregal/store/datamodels'
import {accHelper, getDrawerParts} from 'utils/helpers'
import {materialModel, drawerLengths, drawerSideInset} from 'utils/datamodels'
import {IDrawer} from 'common/threeComponents/DrawerFCNew'

export const DrawerList = () => {
  const drawerList = useStore((store) => store.config.drawers.list)
  const [flapList, doorList, backpanels, depth, shelfHeight, columns, boards, subColumns, grid, shelfMaterialID, drawerMaterial, feetID] = useStore(
    (store) => [
      store.config.flaps.list,
      store.config.doors.list,
      store.config.backpanels,
      store.config.main.depth,
      store.config.main.height,
      store.config.columns.list,
      store.config.boards.list,
      store.config.subColumns.list,
      store.config.main.grid,
      store.config.main.materialID,
      store.config.drawers.materialID,
      store.config.feet.id,
    ],
    shallow
  )
  const bodyMaterialID = shelfMaterials[shelfMaterialID].drawerInnerMaterial as keyof typeof materialModel
  const thickness = materialModel[shelfMaterialID].thickness
  const gridSize = (shelfHeight - thickness) / grid
  const shadowJoint = regalModel.shadowJoint
  const wallMounted = feetID === 'wallMounted'
  const grainDir = useStore((store) => store.view.drawers.grainDirection) === 0 ? 'horizontal' : 'vertical'
  const backpanelList = backpanels.list
  const backpanelThickness = materialModel[backpanels.materialID].thickness
  const drawers: IDrawer[] = []

  drawerList.forEach((drawer, j) => {
    if (accHelper.is_in_column(drawer.pos.x, columns.length)) {
      const subColumnList = accHelper.get_SubColumn_List({x: Math.floor(drawer.pos.x), y: Math.floor(drawer.pos.y)}, columns, boards, subColumns)
      const innerXPos = drawer.pos.x - Math.floor(drawer.pos.x)
      const frontThickness = frontMaterials[drawerMaterial].thickness
      const bodyThickness = materialModel[bodyMaterialID].thickness
      const isBackpanel = accHelper.is_Backpanel_at_pos({x: drawer.pos.x, y: drawer.pos.y}, backpanelList, boards)
      const backpanelInset = wallMounted
        ? regalModel.backpanelWallMountedInset + backpanelThickness
        : isBackpanel
        ? backpanelThickness < 0.016
          ? regalModel.backpanelInset + backpanelThickness
          : 0.001 + backpanelThickness
        : 0
      const drawerDepth = Math.max(...drawerLengths.filter((l) => l < depth - 0.001 - frontThickness - 0.013 - 0.008 - backpanelInset)) + frontThickness
      if (subColumnList.indexOf(innerXPos) + 1 === subColumnList.indexOf(innerXPos + drawer.size.x) && drawerDepth > 0) {
        const drawerAbove = accHelper.get_acc_above(drawer, drawerList)
        const drawerBelow = accHelper.get_acc_below(drawer, drawerList)
        const totalWidth = accHelper.get_acc_width(drawerBelow.pos, {x: 1, y: 0}, drawerList)
        const totalWidthBelow = accHelper.get_acc_width_below(drawerBelow.pos, {x: 1, y: 0}, flapList, doorList)
        const accBeside = accHelper.get_acc_beside(drawer.pos, drawer.size, doorList, drawerList)
        const accHeightBesideLeft = accBeside.heightLeft
        const accHeightBesideRight = accBeside.heightRight
        const accAbove = accHelper.get_acc_above(drawer, drawerList, doorList)
        const accBelow = accHelper.get_acc_below(drawer, drawerList, doorList)
        const accEndBesidLeft = accBeside.endLeft
        const accEndBesideRight = accBeside.endRight
        const accStartBesideLeft = accBeside.startLeft
        const accStartBesideRight = accBeside.startRight
        const accAboveEndList = [...accAbove.endList, drawer.pos.y + drawer.size.y]
        const accBelowStartList = [...accBelow.startList, drawer.pos.y]
        const sameEndLeft = accEndBesidLeft ? accBeside.endListLeft.some((r) => accAboveEndList.includes(r)) : false
        const sameEndRight = accEndBesideRight ? accBeside.endListRight.some((r) => accAboveEndList.includes(r)) : false
        const sameStartLeft = accStartBesideLeft !== undefined ? accBeside.startListLeft.some((r) => accBelowStartList.includes(r)) : false
        const sameStartRight = accStartBesideRight !== undefined ? accBeside.startListRight.some((r) => accBelowStartList.includes(r)) : false
        const extraWidthLeft = accHeightBesideLeft === drawer.size.y && sameEndLeft && sameStartLeft ? 1 : 0
        const extraWidthRight = accHeightBesideRight === drawer.size.y && sameEndRight && sameStartRight ? 1 : 0
        const isOffset = totalWidth === 1 && totalWidthBelow === 1
        const yOffset = isOffset ? thickness + shadowJoint : 0
        const divY = Math.floor(drawerAbove.pos.y) - Math.floor(drawerBelow.pos.y)
        const heightUnit = (gridSize - thickness - shadowJoint + divY * gridSize + yOffset) / (divY + 1)
        const height = drawer.size.y * heightUnit - shadowJoint
        const shelfInnerWidth = (columns[Math.floor(drawer.pos.x)].width + thickness) * drawer.size.x - thickness
        const width = shelfInnerWidth - 2 * shadowJoint + (extraWidthLeft * (thickness + shadowJoint)) / 2 + (extraWidthRight * (thickness + shadowJoint)) / 2
        const bodyHeight = Math.floor((height - 0.015 - 0.0175) * 200) / 200
        const topInset = height - bodyHeight - 0.015 + (shadowJoint - 0.003) / 2
        const xPosOffset = extraWidthLeft === 1 ? (thickness + shadowJoint) / 2 : 0
        const leftInset = drawerSideInset - bodyThickness - shadowJoint + xPosOffset
        const xPos =
          columns
            .slice(0, Math.floor(drawer.pos.x))
            .reduce((pv, cv) => pv + cv.width + thickness, thickness + shadowJoint + (columns[Math.floor(drawer.pos.x)].width + thickness) * (drawer.pos.x - Math.floor(drawer.pos.x))) - xPosOffset
        const yPos = thickness + Math.floor(drawerBelow.pos.y) * gridSize + (drawer.pos.y - Math.floor(drawerBelow.pos.y)) * heightUnit + shadowJoint - yOffset
        const bottomMaterialID = 'mdf8W' as keyof typeof materialModel
        const drawerParts = getDrawerParts({
          width: width,
          height: height,
          depth: drawerDepth,
          bodyHeight: bodyHeight,
          shelfInnerWidth: shelfInnerWidth,
          frontMaterialID: drawerMaterial,
          bodyMaterialID: bodyMaterialID,
          bottomMaterialID: bottomMaterialID,
          grainDir: grainDir,
        })
        drawers.push({
          key: drawer.pos.x + '-' + drawer.pos.y,
          xPos: xPos,
          yPos: yPos,
          zPos: depth - 0.001,
          width: width,
          height: height,
          depth: drawerDepth,
          leftInset: leftInset,
          topInset: topInset,
          grainDir: grainDir,
          parts: drawerParts,
          type: 'drawer',
        })
      }
    }
  })

  return drawers
}

import {useStore} from 'products/kubatur/store/store'
import shallow from 'zustand/shallow'
import {shelfMaterials, regalModel, backpanelMaterials, shelfTypes, frontMaterials} from 'products/kubatur/store/datamodels'
import {accHelper} from 'utils/helpers'

export const SubColumnList = () => {
  const subColumnList = useStore((store) => store.config.subColumns.list)
  const [shelfHeight, depth, columns, boards, backpanels, doors, drawers, grid, materialID, shelfTyp] = useStore(
    (store) => [
      store.config.main.height,
      store.config.main.depth,
      store.config.columns.list,
      store.config.boards.list,
      store.config.backpanels,
      store.config.doors,
      store.config.drawers.list,
      store.config.main.grid,
      store.config.main.materialID,
      store.config.main.shelfTyp,
    ],
    shallow
  )
  const thickness = shelfMaterials[materialID].thickness
  const gridSize = (shelfHeight - thickness - shelfTypes[shelfTyp].bottomInset - shelfTypes[shelfTyp].topInset) / grid
  const backpanelThickness = backpanelMaterials[backpanels.materialID].thickness
  const doorThickness = frontMaterials[doors.materialID].thickness
  const subColumns: {key: number; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof shelfMaterials; type: string}[] = []
  subColumnList.forEach((el, i) => {
    const innerXPos = el.pos.x - Math.floor(el.pos.x)
    if (el.pos.y < grid && el.pos.x < columns.length) {
      const isBoard = el.pos.y === 0 ? true : boards[Math.floor(el.pos.x)].includes(el.pos.y)
      if ((0.5 - Math.abs(0.5 - innerXPos)) * columns[Math.floor(el.pos.x)].width > regalModel.minSubColumnWidth && isBoard) {
        const nextBoard = Math.min(...boards[Math.floor(el.pos.x)].filter((y) => y > el.pos.y), grid)
        let multiplier = nextBoard - el.pos.y
        const accHeightInFront = accHelper.get_acc_beside(el.pos, {x: 0, y: multiplier}, drawers).height
        const doorinFront = accHeightInFront === 2 * multiplier
        const isInset = accHelper.acc_is_in_front(el.pos, {x: 0, y: multiplier}) || doorinFront
        const height = multiplier * gridSize - thickness
        const yPos = el.pos.y * gridSize + thickness + shelfTypes[shelfTyp].bottomInset
        const zPos = backpanelThickness < 0.016 ? regalModel.backpanelInset + backpanelThickness : 0.001 + backpanelThickness
        const noDoorInFront = doors.list.filter((door) => Math.floor(door.pos.x) === Math.floor(el.pos.x)).length === 0
        const extraDepth = shelfTyp === 2 && noDoorInFront ? regalModel.doorDistance + doorThickness : 0
        const adjustedDepth = depth - zPos - (isInset ? regalModel.frontInset : 0) + extraDepth
        const xPos = columns
          .slice(0, Math.floor(el.pos.x))
          .reduce((pv, cv) => pv + cv.width + thickness, thickness + (columns[Math.floor(el.pos.x)].width + thickness) * (el.pos.x - Math.floor(el.pos.x)))
        subColumns.push({key: i, xPos: xPos, yPos: yPos, zPos: zPos, length: height, depth: adjustedDepth, thickness: thickness, material: materialID, type: 'subColumn'})
      }
    }
  })
  return subColumns
}

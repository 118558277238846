import {useStore} from 'products/analog/store/store'
import shallow from 'zustand/shallow'
import {blueprint, shelfMaterialModel, doorMaterials} from 'products/analog/store/datamodels'

type DoorPart = {
  length: number
  depth: number
  thickness: number
  material: string
  type: string
}

type Door = {
  length: number
  physicalLength: number
  depth: number
  thickness: number
  material: keyof typeof doorMaterials
  type: string
}

type DoorParts = {
  front: Door
  sideLeft?: DoorPart
  sideRight?: DoorPart
  top?: DoorPart
  bottom?: DoorPart
}

export const DoorList = () => {
  const doorList = useStore((store) => store.doors.clearDoors(), shallow)
  const [depth, shelfWidth, boards, grid, shelfMaterialID, doorMaterialID] = useStore(
    (store) => [store.config.main.depth, store.config.main.width, store.config.rows.list, store.config.main.grid, store.config.main.materialID, store.config.doors.materialID],
    shallow
  )

  let zPosInset = 0
  const shelfThickness = shelfMaterialModel[shelfMaterialID].thickness
  const gridSize = (shelfWidth - shelfThickness) / grid
  const shadowJoint = blueprint.shadowJoint
  const innerMatReference = doorMaterials[doorMaterialID].innerMatReference
  const doors: {key: string; xPos: number; yPos: number; zPos: number; length: number; depth: number; parts: DoorParts; offDoor: boolean; yPosAlu?: number; heightAlu?: number}[] = []
  const maxSizeOfOffDoors = 4 //Math.ceil(grid / 2) // calc max field of offdoors
  const baseDoorLength = (gridSize * maxSizeOfOffDoors + shelfThickness - (maxSizeOfOffDoors - 1) * shadowJoint) / maxSizeOfOffDoors // calc base door length for offdoors

  doorList.forEach((door, i) => {
    const offDoor = door.type === 1
    const doorThickness = doorMaterials[doorMaterialID].thickness
    const doorLength = offDoor ? (door.size.x * (shelfWidth + shadowJoint)) / grid - shadowJoint : door.size.x * gridSize // calc door length
    const baseDoorHeight = boards.slice(door.pos.y, door.pos.y + door.size.y).reduce((acc, board) => acc + board.height + shelfThickness, -shelfThickness) // calc baseheight of door
    const bottom = door.pos.y === 0 // check if door is at bottom
    const top = door.pos.y + door.size.y >= boards.length // check if door is on top
    const topShift = top ? shelfThickness - 0.0005 : (shelfThickness - shadowJoint) / 2 // add extra height if offdoor is on top or bottom
    const bottomShift = bottom ? shelfThickness - 0.0005 : (shelfThickness - shadowJoint) / 2 // add bottom shift for offdoor
    const doorHeight = offDoor ? baseDoorHeight + bottomShift + topShift : baseDoorHeight + 0.006 // calc real door height
    const doorXPos = offDoor ? (door.pos.x * (shelfWidth + shadowJoint)) / grid : door.pos.x * ((shelfWidth - 2 * shelfThickness - gridSize) / (grid - 1)) + shelfThickness // calc door x position
    const doorLevel = boards.slice(0, door.pos.y) //which vertical level/board the door is at
    const basedoorYPos = doorLevel.reduce((acc, curr) => acc + curr.height + shelfThickness, shelfThickness) // calc base yPos
    const doorYPos = offDoor ? basedoorYPos - bottomShift : basedoorYPos - 0.003 // calc real yPos
    const doorZPos = offDoor ? depth + 0.001 : depth - blueprint.innerDoorInset - doorThickness - zPosInset * blueprint.doorGrooveDistance // calc real zPos
    const physicalLength = offDoor ? door.size.x * baseDoorLength + (door.size.x - 1) * shadowJoint : doorLength // calc physical length of door
    const doorParts = getDoorParts({
      width: doorLength,
      height: doorHeight,
      physicalLength: physicalLength,
      doorMaterialID: doorMaterialID,
      innerMatReference: innerMatReference,
      offDoor: door.type === 1,
    })
    !offDoor && (zPosInset = +!zPosInset)

    !offDoor && doors.push({key: door.pos.x + '-' + door.pos.y, xPos: doorXPos, yPos: doorYPos, zPos: doorZPos, length: doorLength, depth: doorHeight, parts: doorParts, offDoor: offDoor})
    offDoor &&
      doors.push({
        key: door.pos.x + '-' + door.pos.y,
        xPos: doorXPos,
        yPos: doorYPos,
        zPos: doorZPos,
        yPosAlu: basedoorYPos,
        length: doorLength,
        depth: doorHeight,
        heightAlu: baseDoorHeight,
        parts: doorParts,
        offDoor: offDoor,
      })
  })

  return doors
}

const getDoorParts = (params: {width: number; height: number; physicalLength: number; doorMaterialID: keyof typeof doorMaterials; innerMatReference: string; offDoor: boolean}) => {
  const {width, height, physicalLength, doorMaterialID, innerMatReference, offDoor} = params
  const doorThickness = doorMaterials[doorMaterialID].thickness
  const door = {length: width, physicalLength: physicalLength, depth: height, thickness: doorThickness, material: doorMaterialID as keyof typeof doorMaterials, type: offDoor ? 'offdoor' : 'innerdoor'}
  if (offDoor) {
    const sideLeft = {length: height - 0.04, depth: 0.02, thickness: 0.005, material: innerMatReference, type: 'aluFlach'}
    const sideRight = {length: height - 0.04, depth: 0.02, thickness: 0.005, material: innerMatReference, type: 'aluFlach'}
    const bottom = {length: width - 0.08, depth: 0.02, thickness: 0.015, material: innerMatReference, type: 'aluZProfil'}
    const top = {length: width - 0.08, depth: 0.02, thickness: 0.015, material: innerMatReference, type: 'aluZProfil'}
    return {front: door, sideLeft: sideLeft, sideRight: sideRight, top: top, bottom: bottom}
  } else {
    return {front: door}
  }
}

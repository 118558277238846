import {Board, IVector} from 'common/threeComponents/FiberBoard'
import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {BoardList} from '../../partLists/boardList'
import {shelfMaterials} from 'products/kubatur/store/datamodels'

export const ConnectedBoards = (): any => {
  const boardList = BoardList()
  const materialID = boardList[0].material
  const materialReference = shelfMaterials[materialID].matReference
  const materialObject = MaterialSingleton.getMaterials()
  const material = [materialObject[materialReference]?.small_1, materialObject[materialReference]?.small_2] || materialObject[materialReference]
  return (
    <>
      {boardList.map((board) => {
        const size = [board.length, board.thickness, board.depth] as IVector
        return <Board {...board} size={size} material={material[Math.round(Math.random())]} />
      })}
    </>
  )
}

import {useStore} from 'products/analog/store/store'
import shallow from 'zustand/shallow'
import {shelfMaterialModel} from '../store/datamodels'

export const BoardList = () => {
  const boardList = useStore((store) => store.config.rows.list)
  const [width, depth, materialID, feetID] = useStore((store) => [store.config.main.width, store.config.main.depth, store.config.main.materialID, store.config.feet.id], shallow)
  const thickness = shelfMaterialModel[materialID].thickness
  let startPos = 0
  const bottomThickness = feetID === 'rnd30' || feetID === 'rct16' ? 0.025 : thickness
  const boards = [{key: 0, xPos: 0, yPos: startPos, zPos: 0, length: width, depth: depth, thickness: thickness, physicalThickness: bottomThickness, material: materialID, type: 'bottom'}] as [
    {key: number; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; physicalThickness?: number; material: keyof typeof shelfMaterialModel; type: string}
  ]

  boardList.forEach((el, index) => {
    startPos += el.height + thickness
    boards.push({key: index + 1, xPos: 0, yPos: startPos, zPos: 0, length: width, depth: depth, thickness: thickness, material: materialID, type: index !== boardList.length - 1 ? 'board' : 'top'})
  })

  return boards
}

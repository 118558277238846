export const Lights = () => {
  return (
    <>
      <pointLight intensity={5.5} distance={10.5} position={[0, 10, 0]} name="top pointlight" />
      <pointLight intensity={3.5} distance={6.5} position={[0, 1.5, 6]} name="front pointlight" />
      <pointLight intensity={2.8} distance={6} position={[3, 1.5, 3]} name="right pointlight" />
      <pointLight intensity={2.8} distance={6} position={[-3, 1.5, 3]} name="left pointlight" />
      <pointLight intensity={3.3} distance={6} position={[3, 1.5, -3]} name="right rear pointlight" />
      <pointLight intensity={3.3} distance={6} position={[-3, 1.5, -3]} name="left rear pointlight" />
      <ambientLight intensity={2} />
    </>
  )
}

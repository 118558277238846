import {Selector} from 'common/threeComponents/FiberBoard'
import {regalModel, shelfMaterials, shelfTypes} from 'products/kubatur/store/datamodels'
import {useStore} from 'products/kubatur/store/store'
import shallow from 'zustand/shallow'

interface IBoardSelectors {
  depth: number
  xPos: number
  col: number
  width: number
  thickness: number
  boardList: number[]
}

export const BoardSelectors = (props: IBoardSelectors) => {
  const toggleBoard = useStore((store) => store.toggleBoard)
  return (
    <>
      {props.boardList.map((el, i) => {
        return <Selector size={[props.width, props.thickness, props.depth]} key={i} xPos={props.xPos} yPos={el} zPos={0} onClick={() => toggleBoard(props.col, i + 1)} />
      })}
    </>
  )
}

export const ConnectedBoardSelectors = () => {
  const visible = useStore((store) => store.view.boards.edit)
  const [columns, depth, height, grid, shelfTyp] = useStore(
    (store) => [store.config.columns, store.config.main.depth, store.config.main.height, store.config.main.grid, store.config.main.shelfTyp],
    shallow
  )
  const thickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const gridsize = (height - thickness - shelfTypes[shelfTyp].topInset - shelfTypes[shelfTyp].bottomInset) / grid

  let startPos = 0
  return (
    <>
      {columns.list.map((el, i) => {
        startPos += el.width + thickness
        let boardList = []
        for (let j = 0; j < grid - 1; j++) {
          boardList.push(shelfTypes[shelfTyp].bottomInset + (j + 1) * gridsize)
        }
        const xPos = startPos - el.width
        if (visible) return <BoardSelectors key={i} xPos={xPos} width={el.width} col={i} depth={depth} thickness={thickness} boardList={boardList} />
        return null
      })}
    </>
  )
}

import {ISetProduce, IStore} from '../store'
import {backpanelMaterials} from '../datamodels'
import {GetState} from 'zustand'
import {accHelper} from 'utils/helpers'

export interface IBackpanelSlice {
  backpanels: {
    setEdit: (edit: boolean) => void
    setBackpanel: (xPos: number, yPos: number, cover: 'full' | 'compartment') => void
    setMaterialID: (materialID: keyof typeof backpanelMaterials) => void
    setVariant: (variant: number) => void
    clearBackpanels: () => {pos: {x: number; y: number}; cover: 'full' | 'compartment'}[]
  }
}

export const createBackpanelSlice = (setProduce: ISetProduce<IStore>, get: GetState<IStore>) => {
  const backpanels = {
    setEdit: (edit: boolean) =>
      setProduce((store) => {
        store.view.backpanels.edit = edit
      }),
    setBackpanel: (xPos: number, yPos: number, cover: 'full' | 'compartment') =>
      setProduce((store: IStore) => {
        const clearedBackpanels = store.config.backpanels.list.filter((backpanel) => {
          const otherXSize = backpanel.cover === 'full' ? store.config.main.grid : 1
          const thisXSize = cover === 'full' ? store.config.main.grid : 1
          if (accHelper.is_overlapping_with_other({x: xPos, y: yPos}, backpanel.pos, {x: thisXSize, y: 1}, {x: otherXSize, y: 1})) return false
          return true
        })
        if (!store.config.backpanels.list.find((backpanels) => backpanels.pos.x === xPos && backpanels.pos.y === yPos && backpanels.cover === cover)) {
          clearedBackpanels.push({pos: {x: xPos, y: yPos}, cover: cover, face: 'back'})
        }
        store.config.backpanels.list = clearedBackpanels
      }),
    setMaterialID: (materialID: keyof typeof backpanelMaterials) =>
      setProduce((store) => {
        store.config.backpanels.materialID = materialID
      }),
    setVariant: function (variant: number): void {
      setProduce((state: IStore) => {
        state.view.backpanels.variant = variant
      })
    },
    clearBackpanels: () => {
      const clearedBackpanels = get().config.backpanels.list.filter((backpanel) => {
        const backpanelIsInShelf = backpanel.pos.y < get().config.rows.list.length && backpanel.pos.x < get().config.main.grid
        const backpanelhasColumn = get().config.columns.list[backpanel.pos.y].includes(backpanel.pos.x) || backpanel.pos.x === 0
        if (backpanelIsInShelf && backpanelhasColumn) return true
        return false
      })
      clearedBackpanels.sort((a, b) => a.pos.y - b.pos.y || a.pos.x - b.pos.x)
      return clearedBackpanels
    },
  }
  return {
    backpanels,
  }
}

import shallow from 'zustand/shallow'
import {Price} from 'products/kubatur/utils/price'
import {useStore} from '../store/store'
import {localFactors, materialTreatments, materialTypes} from 'utils/datamodels'
import {useCommonStore} from 'common/Store/CommonStore'
import {shelfMaterials, baseUrl, footModel, regalModel, backpanelMaterials, frontMaterials} from '../store/datamodels'
import {BoardList} from '../partLists/boardList'
import {ColumnList} from '../partLists/columnList'
import {SubColumnList} from '../partLists/subColumnList'
import {TabelarList} from '../partLists/tabelarList'
import {BackpanelList} from '../partLists/backpanelList'
import {DrawerList} from '../partLists/drawerList'
import {SlidingDoorList} from '../partLists/slidingDoorList'
import {doorMaterials} from 'products/analog/store/datamodels'
import {DoorList} from '../partLists/doorList'

export function InfoBoxPrint() {
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)]
  const [shelfMaterialID, shelfTreatmentID, columns, feetHeight, uri, feet, shelfTyp] = useStore(
    (state) => [state.config.main.materialID, state.config.main.treatmentID, state.config.columns.list, state.config.feet.height, state.uri, state.config.feet, state.config.main.shelfTyp],
    shallow
  )
  const shelfMaterial = shelfMaterials[shelfMaterialID]
  const shelfMaterialName = shelfMaterial.type === 0 ? materialTypes[shelfMaterial.type].name + ' ' + shelfMaterial.name : shelfMaterial.name + ' ' + materialTypes[shelfMaterial.type].name
  const shelfTreatment = materialTreatments[shelfTreatmentID].name
  const width = Math.round(columns.reduce((acc, col) => acc + col.width + shelfMaterial.thickness, shelfMaterial.thickness) * 1000) / 1000
  const depth = useStore((state) => state.config.main.depth)
  const thickness = shelfMaterial.thickness
  const slidingDoorThickness = doorMaterials[useStore((state) => state.config.slidingDoors.materialID)].thickness
  const doorThickness = frontMaterials[useStore((state) => state.config.doors.materialID)].thickness
  const backpanelThickness = backpanelMaterials[useStore((state) => state.config.backpanels.materialID)].thickness
  const extraDepth = shelfTyp === 1 ? 2 * (slidingDoorThickness + regalModel.slidingDoorDistance + thickness) : doorThickness + regalModel.doorDistance
  const totalDepth = Math.round((depth + extraDepth) * 1000)
  const usableDepth = Math.round((depth - backpanelThickness - regalModel.backpanelInset) * 1000)
  const footHeight = feetHeight
  const fullHeight = Math.round((useStore((state) => state.config.main.height) + footHeight) * 1000)
  const footRange = footModel[feet.id].range || [0, 0]
  const fussWand = footModel[feet.id].name + ' ' + feet.height * 1000 + ' mm (' + Math.round((feet.height + footRange[0]) * 1000) + '-' + Math.round((feet.height + footRange[1]) * 1000) + ' mm)'

  const partList = () => {
    const drawerPartList = DrawerList()
      .map((drawer) => Object.values(drawer.parts).map((el) => el))
      .flat() as any[]
    const partList = [...BoardList(), ...ColumnList(), ...SubColumnList(), ...TabelarList(), ...BackpanelList(), ...SlidingDoorList(), ...DoorList(), ...drawerPartList]
    return partList
  }

  let nrColumns = 0
  let nrBoards = 0
  let nrSubColumns = 0
  let nrTabelars = 0
  let nrBackpanels = 0
  let nrDoors = 0
  let nrDrawers = 0
  let nrSlidingDoors = 0

  partList().forEach((part) => {
    if (part.type === 'column') nrColumns++
    if (part.type === 'middleColumn') nrColumns++
    if (part.type === 'board') nrBoards++
    if (part.type === 'bottom') nrBoards++
    if (part.type === 'top') nrBoards++
    if (part.type === 'subColumn') nrSubColumns++
    if (part.type === 'tabelar') nrTabelars++
    if (part.type === 'backpanel') nrBackpanels++
    if (part.type === 'door') nrDoors++
    if (part.type === 'drawerFront') nrDrawers++
    if (part.type === 'slidingDoor') nrSlidingDoors++
  })

  return (
    <div className="description">
      <div className="print_title">Kubatur</div>
      <div className="sub_title">
        <a href={baseUrl + '/get/' + uri}>ID: {uri}</a>
      </div>
      <div className="printcontainer">
        <div className="col">
          <div className="sub_title">Material</div>
          {shelfMaterialName}
          <div className="sub_title">Oberfläche</div>
          {shelfTreatment}
          <div className="sub_title">Abmessungen</div>
          {width * 1000} mm x {totalDepth}/{usableDepth} mm x {fullHeight} mm
          <div className="sub_title">lichte Fachbreiten</div>
          {columns.map((column, i) => {
            const width = column.width * 1000
            if (i + 1 < columns.length) {
              return <span key={i}>{Math.round(width)} mm, </span>
            } else {
              return <span key={i}>{Math.round(width)} mm</span>
            }
          })}
          <div className="sub_title">Fuss / Wandmontage</div>
          {fussWand}
        </div>
        <div className="col">
          <div className="sub_title">Regal</div>
          <div> {nrColumns} Seiten</div>
          <div> {nrBoards} Regalböden</div>
          <div> {nrSubColumns} Zwischenseiten</div>
          <div> {nrTabelars} Fachböden verstellbar</div>
          <div> {nrBackpanels} Rückwände, </div>
          <div className="sub_title">Ausstattung</div>
          <div> {nrDoors} Türen, </div>
          <div> {nrDrawers} Schubläden, Front </div>
          <div> {nrSlidingDoors} Schiebetüren </div>
          <div className="price_title">Preis </div>
          <div className="price">
            <Price /> €
          </div>
          <div className="left">ohne Lieferung / Montage</div>
          <div className="right">{localFactor.vat}</div>
        </div>
      </div>
    </div>
  )
}

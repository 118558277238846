import {Board} from 'common/threeComponents/FiberBoard'
import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {SteelBeamList} from 'products/digital/partLists/steelBeamList'
import {materialModel} from 'utils/datamodels'
import {SteelBeamFiber} from 'common/threeComponents/SteelBeam'
import {Fragment} from 'react'

export const ConnectedSteelBeams = (props: any): any => {
  const steelBeamList = SteelBeamList()
  const materialID = steelBeamList[0].material
  const materialReference = materialModel[materialID].matReference
  const materialObject = MaterialSingleton.getMaterials()
  const material = materialObject[materialReference]?.big || materialObject[materialReference]
  //const matFeetBeam = materialObject['solidBlack']
  return (
    <>
      {steelBeamList.map((steelBeam) => {
        return (
          <Fragment key={steelBeam.key}>
            {steelBeam.type === 'steelBeamFeets' && <SteelBeamFiber {...steelBeam} width={steelBeam.depth} />}
            {steelBeam.type === 'steelBeam' && <Board {...steelBeam} size={[steelBeam.length, steelBeam.thickness, steelBeam.depth]} material={material} />}
          </Fragment>
        )
      })}
    </>
  )
}

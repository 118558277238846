import {InfoBoxPrint} from './infoboxPrint'
import {baseUrl} from '../store/datamodels'
import {DrawerList} from '../partHelper/drawerHelper'
import {DoorList} from '../partHelper/doorHelper'
import {FlapList} from '../partHelper/flapHelper'
import {BackpanelList} from '../partHelper/backpanelHelper'
import {ColumnList} from '../partHelper/columnHelper'
import {SubColumnList} from '../partHelper/subColumnHelper'
import {TabelarList} from '../partHelper/tabelarHelper'
import {BoardList} from '../partHelper/boardHelper'
import {ConnectedDasRegal} from '../threeComponents/DasRegal'
import {Caption, Page, PartList, PrintView} from 'components/print/PrintView'
import {FiberPrintScene} from 'common/threeComponents/FiberScene/FiberScene'
import {useStore} from '../store/store'
import {getReducedPartList} from 'utils/helpers'
import {useCommonStore} from 'common/Store/CommonStore'

export default function ConnectedDasregalPrintView() {
  const footID = useStore((store) => store.config.feet.id)
  const wallMounted = footID === 'wallMounted'
  const feetHeight = useStore((store) => store.config.feet.height)
  const footHeight = !wallMounted ? feetHeight : 0

  const drawerPartList = DrawerList()
    .map((drawer) => Object.values(drawer.parts).map((el) => el))
    .flat() as any[]
  const flapPartList = FlapList()
    .map((flap) => Object.values(flap.parts).map((el) => el))
    .flat() as any[]
  const partList = [...BoardList(), ...ColumnList(), ...SubColumnList(), ...TabelarList(), ...BackpanelList(), ...DoorList(), ...drawerPartList, ...flapPartList]

  const reducedPartList = getReducedPartList(partList)
  const extended = useCommonStore((state) => state.extended)

  return (
    <PrintView reference="dasregal" baseUrl={baseUrl}>
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'front'}>
          <ConnectedDasRegal doors={true} />
        </FiberPrintScene>
        <Caption>{'Ansicht vorne'}</Caption>
        <InfoBoxPrint />
      </>
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'front'}>
          <ConnectedDasRegal doors={false} flaps={false} />
        </FiberPrintScene>
        <Caption>{'Ansicht vorne ohne Türen / Klappen'}</Caption>
        <InfoBoxPrint />
      </>
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'back'}>
          <ConnectedDasRegal doors={false} />
        </FiberPrintScene>
        <Caption>{'Ansicht hinten'}</Caption>
        <InfoBoxPrint />
      </>
      {extended && (
        <Page orientation={'landscape'} logo={false}>
          <PartList partList={reducedPartList} />
        </Page>
      )}
    </PrintView>
  )
}

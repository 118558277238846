import * as THREE from 'three'
import {Board} from 'common/threeComponents/FiberBoard'

export interface IDoor {
  length: number
  depth: number
  thickness: number
  doorMaterial: THREE.Material | THREE.Material[]
  innerMaterial?: THREE.Material | THREE.Material[]
  scene: THREE.Group
  xPos: number
  yPos: number
  zPos: number
  yPosAlu?: number
  heightAlu?: number
  offDoor: boolean
}

export const DoorFC = (props: IDoor) => {
  const {length, depth, thickness, doorMaterial, innerMaterial, xPos, yPos, zPos, yPosAlu, heightAlu, offDoor} = props
  const coreMaterial = new THREE.MeshBasicMaterial({color: 0x666666})
  const deltaXY = 0.001
  const deltaZ = 0.0005

  return (
    <>
      <Board key={'front'} {...props} size={[length, thickness, depth]} material={coreMaterial} rotation={[0.5, 0, 0]} translate={[0.5, 0.5, -0.5]} />
      <Board
        key={'front-core'}
        {...props}
        size={[length - deltaXY * 2, thickness + deltaZ * 2, depth - deltaXY * 2]}
        xPos={xPos + deltaXY}
        yPos={yPos + deltaXY}
        zPos={zPos - deltaZ}
        material={doorMaterial}
        rotation={[0.5, 0, 0]}
        translate={[0.5, 0.5, -0.5]}
      />
      {offDoor && (
        <>
          <Board
            key={'alu-bottom'}
            {...props}
            size={[length - 0.08, 0.005, 0.02]}
            xPos={xPos + 0.04}
            yPos={yPosAlu || 0}
            zPos={zPos - 0.005}
            material={innerMaterial}
            rotation={[0.5, 0, 0]}
            translate={[0.5, 0.5, -0.5]}
          />
          <Board
            key={'alu-top'}
            {...props}
            size={[length - 0.08, 0.005, 0.02]}
            xPos={xPos + 0.04}
            yPos={(yPosAlu || 0) + (heightAlu || 0)}
            zPos={zPos - 0.005}
            material={innerMaterial}
            rotation={[0.5, 0, 0]}
            translate={[0.5, 0.5, 0.5]}
          />
          <Board
            key={'alu-left'}
            {...props}
            size={[0.02, 0.005, heightAlu || 0]}
            xPos={xPos + 0.02}
            yPos={yPosAlu || 0}
            zPos={zPos - 0.005}
            material={innerMaterial}
            rotation={[0.5, 0, 0]}
            translate={[0.5, 0.5, -0.5]}
          />
          <Board
            key={'alu-right'}
            {...props}
            size={[0.02, 0.005, heightAlu || 0]}
            xPos={xPos + length - 0.04}
            yPos={yPosAlu || 0}
            zPos={zPos - 0.005}
            material={innerMaterial}
            rotation={[0.5, 0, 0]}
            translate={[0.5, 0.5, -0.5]}
          />
        </>
      )}
    </>
  )
}

import {useEffect, useRef} from 'react'
import {useThree, Viewport} from 'react-three-fiber'
import {Box3, MathUtils, PerspectiveCamera, Vector3} from 'three'
import {cameraPosition} from 'utils/datamodels'
interface IFitToView {
  minHeight?: number
  minWidth?: number
  paddingTop?: number
  paddingRight?: number
  paddingBottom?: number
  paddingLeft?: number
  children: any
}

export const FitToView = (props: IFitToView) => {
  const ref = useRef<THREE.Group>(null!)
  const {viewport, camera, size}: {viewport: Viewport; camera: PerspectiveCamera; size: any} = useThree()
  const padding = {top: props.paddingTop || 0, right: props.paddingRight || 0, bottom: props.paddingBottom || 0, left: props.paddingLeft || 0}
  const minSize = {width: props.minWidth || 0, height: props.minHeight || 0}

  useEffect(() => {
    const group = ref.current
    const boundingBox = new Box3()
    boundingBox.setFromObject(group)
    boundingBox.min.y = 0 // bottom of the bounding box is the floor
    const bDimension = boundingBox.getSize(new Vector3())
    const distance = Math.abs(cameraPosition.z - bDimension.z / 2)

    const productHeight = Math.max(bDimension.y / (1 - padding.top / size.height - padding.bottom / size.height), minSize.height)
    const productWidth = Math.max(bDimension.x / (1 - padding.left / size.height - padding.right / size.height), minSize.width)

    /**
     *              A
     * (Product Height)
     *    ─ ─ ─ ─ ─ ─ x
     *   ╲            │
     *    ╲           │
     *     ╲          │
     *      ╲         │
     *       ╲        │     B
     *        ╲       │ (distance)
     *         ╲      │
     *          ╲     │
     *           ╲    │
     *            ╲   │
     *             ╲  │
     *              ╲ │
     *               ╲│
     *                ▼
     *    a Radians = Atan(A / B)
     *
     * To get full fov we need to double a, thats why we have 2 * a
     */

    const yFovInRadians = Math.atan(cameraPosition.y / distance) + Math.atan((productHeight - cameraPosition.y) / distance) // get fov for product height which is shifted by padding.bottom + view height
    const xFovInRadians = 2 * Math.atan((productWidth * viewport.height) / viewport.width / 2 / distance) // get fov for product width
    const fov = MathUtils.radToDeg(Math.max(yFovInRadians, xFovInRadians)) // get max fov
    camera.fov = fov // !! this also changes viewport sizes !! -> thats why calculation of bottom offset maybe wrong
    // @ts-ignore
    const currentViewport = viewport.getCurrentViewport()
    const visibleHeight = yFovInRadians > xFovInRadians ? productHeight : (productWidth * currentViewport.height) / currentViewport.width // get visible height
    const viewOffsetY = currentViewport.height / 2 // offset distance to new view's bottom edge
    const bottomPadding = currentViewport.height * (padding.bottom / size.height) // offset distance to new view's bottom edge
    const bottomShift = (cameraPosition.y / visibleHeight) * currentViewport.height // bottom shift for camera position in y direction

    camera.setViewOffset(currentViewport.width, currentViewport.height, 0, -viewOffsetY + bottomPadding + bottomShift, currentViewport.width, currentViewport.height) // to fix it maybe use pixels
    camera.updateProjectionMatrix()
  })

  return <group ref={ref}>{props.children}</group>
}

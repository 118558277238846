import {Board} from 'common/threeComponents/FiberBoard'
import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {shelfMaterials} from '../../store/datamodels'
import {SubColumnList} from 'products/kubatur/partLists/subColumnList'

export const ConnectedSubColumns = (props: any): any => {
  const subColumnList = SubColumnList()
  if (subColumnList.length > 0) {
    const materialID = subColumnList[0].material
    const materialReference = shelfMaterials[materialID].matReference
    const materialObject = MaterialSingleton.getMaterials()
    var material = [materialObject[materialReference]?.small_1, materialObject[materialReference]?.small_2] || materialObject[materialReference]
  }

  return (
    <>
      {subColumnList.map((board) => {
        return <Board {...board} size={[board.length, board.thickness, board.depth]} material={material[Math.round(Math.random())]} rotation={[0, 0, 0.5]} translate={[0.5, 0.5, 0.5]} />
      })}
    </>
  )
}

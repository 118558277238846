import {IDrawerActions, IStore} from '../..'
import {blueprint, drawerMaterials} from '../datamodels'
import {accHelper} from 'utils/helpers'
import {GetState} from 'zustand'

export const createDrawerSlice = (setProduce: any, get: GetState<IStore>) => {
  const drawers: IDrawerActions = {
    setDrawer: (pos: {x: number; y: number}, size: {y: number}) => {
      setProduce((store: IStore) => {
        const clearedDrawerArray = store.config.drawers.list.filter((drawer) => {
          if (accHelper.is_overlapping_with_other(pos, drawer.pos, {x: 1, y: size.y}, {x: 1, y: drawer.size.y})) return false
          return true
        })
        if (!store.config.drawers.list.find((drawer) => accHelper.same_is_at_position(pos, drawer.pos, {x: 1, y: size.y}, {x: 1, y: drawer.size.y}))) {
          clearedDrawerArray.push({pos, size, face: 'front'})
        }
        const newDrawers = drawers._clearDrawers(clearedDrawerArray)
        store.config.drawers.list = newDrawers
      })
    },
    _clearDrawers(drawerList: {pos: {x: number; y: number}; size: {y: number}; face: 'front' | 'back'}[]) {
      let newDrawers = drawerList.filter((drawer) => {
        if (drawer.pos.x < get().config.main.grid) {
          let returnVal = false
          const isBoardAbove = Math.abs(drawer.pos.y + drawer.size.y - Math.round(drawer.pos.y + drawer.size.y)) < 0.01
          for (let i = 0; drawerList.length > i; i++) {
            if (accHelper.is_acc_above({...drawer, size: {x: 1, y: drawer.size.y}}, {...drawerList[i], size: {x: 1, y: drawerList[i].size.y}}) || isBoardAbove) {
              returnVal = true
              break
            }
          }
          return returnVal
        } else {
          return true
        }
      })
      for (let i = 0; newDrawers.length > i; i++) {
        // eslint-disable-next-line no-loop-func
        newDrawers = newDrawers.filter((drawer) => {
          if (drawer.pos.x < get().config.main.grid) {
            let returnVal = false
            const isBoardAbove = Math.abs(drawer.pos.y + drawer.size.y - Math.round(drawer.pos.y + drawer.size.y)) < 0.01
            for (let j = 0; newDrawers.length > j; j++) {
              if (accHelper.is_acc_above({...drawer, size: {x: 1, y: drawer.size.y}}, {...newDrawers[j], size: {x: 1, y: newDrawers[j].size.y}}) || isBoardAbove) {
                returnVal = true
                break
              }
            }
            return returnVal
          } else {
            return true
          }
        })
      }
      return newDrawers
    },
    setVisible: (visible) => {
      setProduce((store: IStore) => {
        store.view.drawers.visible = visible
      })
    },
    setMaterialID: function (materialID: keyof typeof drawerMaterials): void {
      setProduce((store: IStore) => {
        store.config.drawers.materialID = materialID
      })
    },
    setEdit: function (edit: boolean): void {
      setProduce((store: IStore) => {
        store.view.drawers.edit = edit
      })
    },
    setVariant: function (variant: number): void {
      setProduce((store: IStore) => {
        store.view.drawers.variant = variant
      })
    },
    clearDrawers: () => {
      const clearedDrawers = drawers._clearDrawers(get().config.drawers.list).filter((drawer) => {
        const yPosIndex = Math.floor(drawer.pos.y)
        const isInShelf = drawer.pos.y < get().config.rows.list.length && drawer.pos.x < get().config.main.grid
        const height = isInShelf ? drawer.size.y * (get().config.rows.list[yPosIndex].height - blueprint.shadowJoint) - blueprint.shadowJoint : 0
        const isInSizeRange = height > blueprint.minDrawerHeight && height < blueprint.maxDrawerHeight
        const hasColumn = get().config.columns.list[yPosIndex].includes(drawer.pos.x) || drawer.pos.x === 0
        return isInShelf && hasColumn && isInSizeRange
      })
      clearedDrawers.sort((a, b) => a.pos.y - b.pos.y || a.pos.x - b.pos.x)
      return clearedDrawers
    },
  }
  return {
    drawers,
  }
}

import {Route, Routes} from 'react-router-dom'
import {AnalogSlider, DasregalSlider, DigitalSlider, KubaturSlider, MetrumSlider, Slider, TekturSlider} from './slider/Slider'

export function SliderView() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Slider />} />
        <Route path="/analog" element={<AnalogSlider />} />
        <Route path="/kubatur" element={<KubaturSlider />} />
        <Route path="/dasregal" element={<DasregalSlider />} />
        <Route path="/tektur" element={<TekturSlider />} />
        <Route path="/metrum" element={<MetrumSlider />} />
        <Route path="/digital" element={<DigitalSlider />} />
      </Routes>
    </>
  )
}

import {materialModel, footModel as feets} from 'utils/datamodels'

export const regalModel = {
  thickness: 0.006,
  minWidth: 0.4,
  minDepth: 0.25,
  maxDepth: 0.6,
  minColHeight: 0.1,
  maxColHeight: 0.6,
  gridMin: 0.15,
  gridMax: 0.4,
  maxSpan: 1,
  gridSize: 0.199,
  depthList: [0.33, 0.41],
  heightList: [0.134, 0.204, 0.284, 0.374],
  maxFlapHeight: 0.55,
  maxFlapWidth: 1.8,
  minDrawerHeight: 0.1,
  maxDrawerHeight: 0.4,
  maxFootSpan: 0.9,
  maxFootInset: 0.41,
  shadowJoint: 0.003,
  backpanelInset: 0.001,
  backpanelSideInset: 0.0025,
  drawerBodyThickness: 0.013,
}

export const baseUrl = '/digital'

export const shelfMaterials = {
  h6TWBC: {...materialModel.h6TWBC, steelMaterial: 'solidWhite'},
  h6SWBC: {...materialModel.h6SWBC, steelMaterial: 'solidWhite'},
  h6TB: {...materialModel.h6TB, steelMaterial: 'solidBlack'},
  h6TW: {...materialModel.h6TW, steelMaterial: 'solidWhite'},
  h6SB: {...materialModel.h6SB, steelMaterial: 'solidBlack'},
  h6SW: {...materialModel.h6SW, steelMaterial: 'solidWhite'},
}

export const drawerFrontMaterials = {
  ...shelfMaterials,
}

export const flapMaterials = {
  h10TWBC: materialModel.h10TWBC,
  h10SWBC: materialModel.h10SWBC,
  h10TB: materialModel.h10TB,
  h10TW: materialModel.h10TW,
  h10SB: materialModel.h10SB,
  h10SW: materialModel.h10SW,
}

export const backpanelMaterials = {
  m18MW: {...materialModel['m18MW'], shelfReference: []},
  m18SW: {...materialModel['m18SW'], shelfReference: []},
  m18CB: {...materialModel['m18CB'], shelfReference: []},
  m18SB: {...materialModel['m18SB'], shelfReference: []},
}

export const backpanelTypeModel = [
  {id: 0, name: 'fachgröße'},
  {id: 1, name: 'etagenbreite'},
]

export const drawerTypeModel = [
  {id: 0, name: 'volle Höhe', height: 1},
  {id: 1, name: 'halbe Höhe', height: 1 / 2},
  {id: 2, name: '1/3 Höhe', height: 1 / 3},
]

export const footModel = {
  u308: feets.u308,
  rnd30: {...feets.rnd30, heights: [0.125, 0.175, 0.225], price: 2 * feets.rnd30.price},
  rct16: {...feets.rct16, heights: [0.125, 0.175, 0.225], price: 2 * feets.rct16.price},
}

import {LocaleToLocalStorage} from 'common/LocaleToLocalStorage/LocaleToLocalStorage'
import {useCommonStore} from 'common/Store/CommonStore'
import {LocaleModal} from 'components/material_interfaces/material_interfaces'
import {Price} from 'products/metrum/utils/price'
import {Route, Routes} from 'react-router-dom'
import {LoadModal} from 'utils/API/components/LoadModal'
import {SaveConfigModal} from 'utils/API/components/SaveConfigModal'
import {SaveLoadModal} from 'utils/API/components/SaveLoadModal'
import shallow from 'zustand/shallow'
import {ViewContainer} from '../../../../components/ViewContainer/ViewContainer'
import {localFactors} from '../../../../utils/datamodels'
import {baseUrl, regalModel, shelfMaterials} from '../../store/datamodels'
import {useStore} from '../../store/store'
import {ConnectedMetrum} from '../../threeComponents/Metrum'
import {ShelfStructureModals} from '../Shelf/ShelfStructureModals'
import {IndexView} from './components/IndexView'
import {PerspectiveControls} from './components/PerspectiveControls/PerspectiveControls'
import ConnectedMetrumPrintView from 'products/metrum/print/PrintView'
import {FiberScene} from 'common/threeComponents/FiberScene/FiberScene'
import ApiFetch from 'utils/API/ApiFetch'

export const MetrumView = () => {
  document.title = 'SANKTJOHANSER | METRUM'
  return (
    <Routes>
      <Route path="/" element={<ConnectedMetrumContainer />}>
        <Route index element={<IndexView />} />
        <Route element={<IndexView />}>
          <Route path="locale" element={<LocaleModal title="Standort" options={localFactors} onSaveto={baseUrl} />} />
          <Route path="load" element={<LoadModal baseUrl={baseUrl} />} />
          <Route path="save" element={<SaveConfigModal reference="metrum" />} />
          <Route path="saveload" element={<SaveLoadModal toSave="../save" toLoad="../load" toAbort={baseUrl} />} />
          <Route path="structure/*" element={<ShelfStructureModals />} />
        </Route>
      </Route>
      <Route path="print" element={<ConnectedMetrumPrintView />} />
      <Route path="get/:id" element={<ApiFetch />} />
    </Routes>
  )
}

const ConnectedMetrumContainer = () => {
  const shelfMaterialId = useStore((store) => store.config.main.materialID)
  const [shelfHeight, footHeight, columns] = useStore((store) => [store.config.main.height, store.config.feet.height, store.config.columns.list], shallow)

  const thickness = shelfMaterials[shelfMaterialId].thickness
  const extraHeight = shelfHeight < 1.57 ? footHeight + regalModel.shadowJoint + thickness : footHeight
  const fullHeight = Math.round((shelfHeight + extraHeight) * 1000) / 1000
  const props = {
    shelfMaterialId: shelfMaterialId,
    treatmentId: useStore((store) => store.config.main.treatmentID),
    materialSelector: 'single',
    width: Math.round(columns.reduce((acc, col) => acc + col.width + thickness, thickness) * 1000) / 1000,
    fullHeight: fullHeight,
    footHeight: footHeight,
    depth: useStore((store) => store.config.main.depth),
    localFactor: localFactors[useCommonStore((store) => store.localFactorID)],
    baseUrl: baseUrl,
  }

  return (
    <ViewContainer {...props} price={<Price />} title="Metrum">
      <FiberScene footHeight={footHeight}>
        <ConnectedMetrum />
      </FiberScene>
      <PerspectiveControls />
      <LocaleToLocalStorage />
    </ViewContainer>
  )
}

import './overview.scss'
import dasregal_1 from 'common/static/overview/dasregal-1.jpg'
import dasregal_2 from 'common/static/overview/dasregal-2.jpg'
import dasregal_3 from 'common/static/overview/dasregal-3.jpg'
import dasregal_4 from 'common/static/overview/dasregal-4.jpg'
import dasregal_5 from 'common/static/overview/dasregal-5.jpg'
import dasregal_6 from 'common/static/overview/dasregal-6.jpg'
import dasregal_7 from 'common/static/overview/dasregal-7.jpg'
import dasregal_8 from 'common/static/overview/dasregal-8.jpg'
import dasregal_9 from 'common/static/overview/dasregal-9.jpg'
import dasregal_10 from 'common/static/overview/dasregal-10.jpg'
import dasregal_11 from 'common/static/overview/dasregal-11.jpg'
import dasregal_12 from 'common/static/overview/dasregal-12.jpg'
import dasregal_13 from 'common/static/overview/dasregal-13.jpg'
import {ProductOverView} from './ProductOverView'

export function ConnectedDasregalOverView() {
  return <ProductOverView title={'dasregal'} products={dasregal} />
}

export const dasregal = [
  {img: <img className="overview-image" src={dasregal_1} alt="dasregal" />, to: 'get/dasregal-1'},
  {img: <img className="overview-image" src={dasregal_2} alt="dasregal" />, to: 'get/dasregal-2'},
  {img: <img className="overview-image" src={dasregal_3} alt="dasregal" />, to: 'get/dasregal-3'},
  {img: <img className="overview-image" src={dasregal_5} alt="dasregal" />, to: 'get/dasregal-5'},
  {img: <img className="overview-image" src={dasregal_4} alt="dasregal" />, to: 'get/dasregal-4'},
  {img: <img className="overview-image" src={dasregal_13} alt="dasregal" />, to: 'get/dasregal-13'},
  {img: <img className="overview-image" src={dasregal_7} alt="dasregal" />, to: 'get/dasregal-7'},
  {img: <img className="overview-image" src={dasregal_8} alt="dasregal" />, to: 'get/dasregal-8'},
  {img: <img className="overview-image" src={dasregal_9} alt="dasregal" />, to: 'get/dasregal-9'},
  {img: <img className="overview-image" src={dasregal_12} alt="dasregal" />, to: 'get/dasregal-12'},
  {img: <img className="overview-image" src={dasregal_11} alt="dasregal" />, to: 'get/dasregal-11'},
  {img: <img className="overview-image" src={dasregal_10} alt="dasregal" />, to: 'get/dasregal-10'},
]

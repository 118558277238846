import {IStore, ITabelarActions} from '../..'
import {accHelper} from 'utils/helpers'
import {GetState} from 'zustand'
import {blueprint} from '../datamodels'

export const createTablearSlice = (setProduce: any, get: GetState<IStore>) => {
  const tabelars: ITabelarActions = {
    create: function (pos: {x: number; y: number}): void {
      setProduce((state: IStore) => {
        const tabelarList = state.config.tabelars.list
        const indexOfPos = tabelarList.findIndex((tabelar) => accHelper.same_is_at_position(pos, tabelar.pos, {x: 1, y: 1}, {x: 1, y: 1}))
        indexOfPos === -1 ? state.config.tabelars.list.push({pos}) : state.config.tabelars.list.splice(indexOfPos, 1)
      })
    },
    clearTabelars: () => {
      const clearedTabelars = get().config.tabelars.list.filter((tabelar) => {
        const innerYPos = tabelar.pos.y - Math.floor(tabelar.pos.y)
        const tabelarIsInShelf = tabelar.pos.y < get().config.rows.list.length && tabelar.pos.x < get().config.main.grid
        const tabelarhasColumn = get().rows.clearColumns()[Math.floor(tabelar.pos.y)]?.includes(tabelar.pos.x) || tabelar.pos.x === 0
        const tabelarminHeight = tabelarIsInShelf && get().config.rows.list[Math.floor(tabelar.pos.y)].height * (0.5 - Math.abs(0.5 - innerYPos)) > blueprint.minColHeight
        if (tabelarIsInShelf && tabelarhasColumn && tabelarminHeight) return true
        return false
      })
      clearedTabelars.sort((a, b) => a.pos.y - b.pos.y || a.pos.x - b.pos.x)
      return clearedTabelars
    },
  }
  return {
    tabelars,
  }
}

import {Selector} from 'common/threeComponents/FiberBoard'
import {regalModel, shelfMaterials, shelfTypes} from 'products/kubatur/store/datamodels'
import {useStore} from 'products/kubatur/store/store'
import {accHelper} from 'utils/helpers'
import shallow from 'zustand/shallow'

export const ConnectedClothingRailSelectors = () => {
  const visible = useStore((store) => store.view.clothingRails.edit)
  const [columns, boards, depth, height, grid, shelfTyp] = useStore(
    (store) => [store.config.columns.list, store.config.boards.list, store.config.main.depth, store.config.main.height, store.config.main.grid, store.config.main.shelfTyp],
    shallow
  )
  const subColumns = useStore((store) => store.config.subColumns.list)
  const thickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const toggleClothingRail = useStore((store) => store.clothingRails.toggleClothingRail)
  const gridsize = (height - thickness - shelfTypes[shelfTyp].bottomInset - shelfTypes[shelfTyp].topInset) / grid
  let startPos = thickness

  return (
    <>
      {visible &&
        columns.map((el, x) => {
          startPos += el.width + thickness
          const visibleBoards = [...boards[x], grid]
          return visibleBoards.map((yPos) => {
            const subColumnList = accHelper.get_SubColumn_List({x: x, y: yPos}, columns, boards, subColumns)
            return [...Array(subColumnList.length - 1)].map((_, k) => {
              const width = (el.width + thickness) * (subColumnList[k + 1] - subColumnList[k]) - thickness
              return (
                <Selector
                  key={k}
                  size={[width, 0.03, 0.03]}
                  onClick={() => toggleClothingRail(x, yPos)}
                  xPos={startPos - (1 - subColumnList[k]) * (el.width + thickness)}
                  yPos={yPos * gridsize + shelfTypes[shelfTyp].bottomInset - 0.0475 - 0.015}
                  zPos={depth / 2}
                />
              )
            })
          })
        })}
    </>
  )
}

import {materialModel, w, localFactors, materialTreatments} from '../../../utils/datamodels'
import {BoardList} from '../partLists/boardList'
import {ColumnList} from '../partLists/columnList'
import {price_CNC, price_Material, price_Salary, drawerPrice} from 'utils/priceHelper'
import {useCommonStore} from 'common/Store/CommonStore'
import {BackpanelList} from '../partLists/backpanelList'
import {DrawerList} from '../partLists/drawerList'
import {DoorList} from '../partLists/doorList'
import {useStore} from 'products/analog/store/store'
import {TabelarList} from '../partLists/tabelarList'
import {footModel} from '../store/datamodels'
import {FootList} from '../partLists/footList'

export const Price = () => {
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)]
  const treatmentID = useStore((state) => state.config.main.treatmentID)
  const treatment = materialTreatments[treatmentID]
  const treatmentPrice = treatment.price
  const treatmentSetup = treatment.setup
  const wallMounted = useStore((state) => state.config.feet.id) === 'wallMounted'
  const partList = getPartList()
  let priceMaterial = 0
  let priceWork = 0
  let priceCNC = 0
  let priceFeet = 0
  let priceDrawers = 0
  partList.forEach((part) => {
    priceMaterial += getMaterialPrice(part)
    priceWork += getWorkPrice(part, treatmentPrice, wallMounted)
    priceCNC += getCNCPrice(part)
  })
  const footList = FootList()
  footList.forEach((foot) => {
    priceFeet += footModel[foot.type].price
  })
  DrawerList().forEach((drawer) => {
    priceDrawers += drawerPrice(drawer, treatmentPrice).totalPrice
  })
  const price = Math.round(localFactor.factor * 10 * Math.round((w.Faktor * (treatmentSetup + priceMaterial + priceWork + priceCNC + priceDrawers + priceFeet)) / 10))
  return <>{price}</>
}

const getPartList = () => {
  const doorPartList = DoorList()
    .map((door) => Object.values(door.parts).filter((el) => el.type === 'offdoor' || el.type === 'innerdoor'))
    .flat() as any[]
  const partList = [...BoardList(), ...ColumnList(), ...BackpanelList(), ...TabelarList(), ...doorPartList]
  return partList
}

const getMaterialPrice = (part: {material: keyof typeof materialModel; length: number; type: any; depth: any}) => {
  let price = 0
  switch (part.type) {
    case 'bottom':
    case 'board':
    case 'top':
      price = price_Material({length: part.length, width: part.depth, materialID: part.material})
      break
    case 'column':
    case 'middleColumn':
      price = price_Material({length: part.length, width: part.depth, materialID: part.material, rest: [5 * w.KnappBeschlag]})
      break
    case 'offdoor':
      price = price_Material({
        length: part.length,
        width: part.depth,
        materialID: part.material,
        rest: [w.AluFlach * 2 * part.depth, w.AluZ * 2 * part.length, w.Klebeband * 2 * (part.length + part.depth)],
      })
      break
    case 'innerdoor':
      price = price_Material({length: part.length, width: part.depth, materialID: part.material, rest: [w.AluFlach * 2 * part.depth, w.Klebeband * 2 * part.depth]})
      break
    default:
      price = price_Material({length: part.length, width: part.depth, materialID: part.material})
      break
  }
  return price
}

const getWorkPrice = (part: {length: number; depth: number; material: keyof typeof materialModel; type: string}, treatmentPrice: number, wallMounted?: boolean) => {
  let price = 0
  const material = materialModel[part.material]
  switch (part.type) {
    case 'bottom':
    case 'board':
    case 'top':
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment, rest: [wallMounted ? w.WandRegalBoden : 0]})
      break
    case 'column':
    case 'middleColumn':
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment, rest: [wallMounted ? w.WandRegalSeite : 0]})
      break
    case 'backpanel':
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment, rest: [w.ZeitRW]})
      break
    case 'offdoor':
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment, rest: [w.ZeitSTVL]})
      break
    case 'innerdoor':
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment, rest: [w.ZeitIL]})
      break
    default:
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment})
      break
  }
  return price
}

const getCNCPrice = (part: {type: any; length: number; depth: any}) => {
  let price = 0
  switch (part.type) {
    case 'top':
    case 'bottom':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrGrooves: 2, format: true, bevel: true})
      break
    case 'board':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 2, nrToolChanges: 3, nrGrooves: 4, format: true, bevel: true})
      break
    case 'column':
    case 'middleColumn':
      price = price_CNC({
        length: part.length,
        width: part.depth,
        nrSetups: 1,
        nrToolChanges: 3.4,
        nrDrills: 20 + 4 * Math.floor(((part.length - 0.25) * 1000) / 64),
        nrGrooves: 5,
        format: true,
        bevel: true,
      })
      break
    case 'backpanel':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 1, format: true})
      break
    case 'offdoor':
    case 'innerdoor':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 1, format: true})
      break
    case 'tabelar':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrDrills: 4, nrGrooves: 2, format: true, bevel: true})
      break
    default:
      console.log('no CNCprice for part type: ' + part.type)
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrDrills: 12, format: true, bevel: true})
      break
  }
  return price
}

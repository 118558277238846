import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import './App.scss'
import {AnalogView} from './products/analog/views/AnalogView/AnalogView'
import {RegalView} from './products/dasregal/views/RegalView/RegalView'
import {DigitalView} from 'products/digital/views/DigitalView/DigitalView'
import {KubaturView} from 'products/kubatur/views/KubaturView/KubaturView'
import {MetrumView} from 'products/metrum/views/MetrumView/MetrumView'
import {TekturView} from './products/tektur/views/TekturView/TekturView'
import ApiFetch from 'utils/API/ApiFetch'
import CookieConsent, {Cookies, getCookieConsentValue} from 'react-cookie-consent'
import ReactGA from 'react-ga4'
import {useEffect} from 'react'
import {OverView} from 'components/overView/OverView'
import {SliderView} from 'components/overView/SliderView'

const App = () => {
  const handleAcceptCookie = () => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.initialize('G-0X89KDD5ET')
    }
  }

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove('_ga')
    Cookies.remove('_gat')
    Cookies.remove('_gid')
  }

  useEffect(() => {
    const isConsent = getCookieConsentValue()
    if (isConsent === 'true') {
      handleAcceptCookie()
    }
  }, [])

  const isSlider = window.location.pathname.startsWith('/slider')

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<OverView />} />
          <Route path="/analog/*" element={<AnalogView />} />
          <Route path="/dasregal/*" element={<RegalView />} />
          <Route path="/digital/*" element={<DigitalView />} />
          <Route path="/kubatur/*" element={<KubaturView />} />
          <Route path="/metrum/*" element={<MetrumView />} />
          <Route path="/tektur/*" element={<TekturView />} />
          <Route path="get/:id" element={<ApiFetch />} />
          <Route path="slider/*" element={<SliderView />} />
        </Routes>
      </BrowserRouter>
      {!isSlider && (
        <CookieConsent enableDeclineButton onAccept={handleAcceptCookie} onDecline={handleDeclineCookie} buttonText={'Cookies erlauben'} declineButtonText={'Cookies ablehnen'}>
          Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“.{' '}
          <a href="https://www.sanktjohanser.net/datenschutz/" target={'_blank'} rel="noreferrer" style={{color: '#ffffff'}}>
            Mehr erfahren
          </a>
        </CookieConsent>
      )}
    </div>
  )
}

export default App

import {materialModel} from 'utils/datamodels'
import {backpanelMaterials, footModel, frontMaterials, shelfMaterials} from './datamodels'

const initialColumns = [
  {
    width: 0.582,
    face: 'front' as 'front' | 'back' | 'side',
  },
  {
    width: 0.582,
    face: 'front' as 'front' | 'back' | 'side',
  },
  {
    width: 0.582,
    face: 'front' as 'front' | 'back' | 'side',
  },
]

export const initialConfig = {
  main: {
    grid: 7,
    height: 2.2,
    materialID: 'm12MW' as keyof typeof shelfMaterials,
    depth: 0.6,
    treatmentID: 0,
  },
  boards: {
    list: [
      [1, 2, 6],
      [1, 2, 3, 4, 5, 6],
      [2, 6],
    ],
  },
  backpanels: {
    materialID: 'mdf19W' as keyof typeof backpanelMaterials,
  },
  columns: {
    list: initialColumns,
  },
  doors: {
    materialID: 'm12MW' as keyof typeof frontMaterials,
    list: [
      {
        pos: {x: 0, y: 0},
        size: {y: 7},
        side: 'left' as 'left' | 'right',
      },
      {
        pos: {x: 1, y: 0},
        size: {y: 7},
        side: 'right' as 'left' | 'right',
      },
      {
        pos: {x: 2, y: 2},
        size: {y: 5},
        side: 'right' as 'left' | 'right',
      },
    ],
  },
  drawers: {
    materialID: 'm12MW' as keyof typeof frontMaterials,
    list: [
      {
        pos: {x: 2, y: 0},
        size: {y: 2 / 3},
      },
      {
        pos: {x: 2, y: 2 / 3},
        size: {y: 2 / 3},
      },
      {
        pos: {x: 2, y: 4 / 3},
        size: {y: 2 / 3},
      },
    ],
  },
  tabelars: {
    list: [] as {pos: {x: number; y: number[]}}[],
  },
  clothingRails: {
    list: [{pos: {x: 0, y: 6}}, {pos: {x: 2, y: 6}}],
    materialID: 's13Ei' as keyof typeof materialModel,
  },
  feet: {
    height: 0.03,
    id: 'gn832' as keyof typeof footModel,
    materialID: 'solidBlack' as keyof typeof materialModel,
  },
}

export type IRegalConfig = typeof initialConfig

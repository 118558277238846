import {useState} from 'react'
import clsx from 'clsx'
import './overview.scss'
import logo from 'common/static/sanktjohanser_schriftzug.svg'
import {ConnectedAnalogOverView} from './ConnectedAnalogOverView'
import {ConnectedDasregalOverView} from './ConnectedDasregalOverView'
import {ConnectedTekturOverView} from './ConnectedTekturOverView'
import {ConnectedDigitalOverView} from './ConnectedDigitalOverView'
import {ConnectedMetrumOverView} from './ConnectedMetrumOverView'
import {ConnectedKubaturOverView} from './ConnectedKubaturOverView'
import {useLayoutEffect} from 'react'

export function OverView() {
  const ids = ['analog', 'dasregal', 'tektur', 'digital', 'metrum', 'kubatur']
  const activeId = useScrollspy(ids, 400) // 54 is navigation height
  return (
    <div>
      <div className="overview-center-box">
        <div className="overview-logo-box">
          <a href="https://www.sanktjohanser.net">
            <img src={logo} alt="Sanktjohanser" />
          </a>
        </div>
        <div className="overview-menu-box">
          {ids.map((id) => (
            <li key={`menu-item-${id}`} className="menu-item">
              <a href={`#${id}`} className={clsx('menu-link', id === activeId && 'menu-link-active')}>
                {capitalize(id)}
              </a>
            </li>
          ))}
        </div>
        <ConnectedAnalogOverView />
        <ConnectedDasregalOverView />
        <ConnectedTekturOverView />
        <ConnectedDigitalOverView />
        <ConnectedMetrumOverView />
        <ConnectedKubaturOverView />
      </div>
    </div>
  )
}

// helpers
const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.substr(1)

const clamp = (value: number) => Math.max(0, value)

const isBetween = (value: number, floor: number, ceil: number) => value >= floor && value <= ceil

// hooks
const useScrollspy = (ids: string[], offset: number = 0) => {
  const [activeId, setActiveId] = useState('')

  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset

      const position = ids
        .map((id) => {
          const element = document.getElementById(id)

          if (!element) return {id, top: -1, bottom: -1}

          const rect = element.getBoundingClientRect()
          const top = clamp(rect.top + scroll - offset)
          const bottom = clamp(rect.bottom + scroll - offset)

          return {id, top, bottom}
        })
        .find(({top, bottom}) => isBetween(scroll, top, bottom))

      setActiveId(position?.id || '')
    }

    listener()

    window.addEventListener('resize', listener)
    window.addEventListener('scroll', listener)

    return () => {
      window.removeEventListener('resize', listener)
      window.removeEventListener('scroll', listener)
    }
  }, [ids, offset])

  return activeId
}

import {ChevronLeftOutlined, ChevronRightOutlined} from '@mui/icons-material'
import {Accordion, AccordionDetails, AccordionSummary, Button, ClickAwayListener, Input, InputAdornment, ListItem, Slider} from '@mui/material'
import {Link} from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import React, {useCallback, useEffect, useState} from 'react'

const useStyles = makeStyles({
  card: {
    minWidth: 326,
    position: 'absolute',
    right: -16,
    top: 250,
    minHeight: 312,
  },
  menubox: {
    width: 300,
    minWidth: 300,
    position: 'absolute',
    paddingRight: 20,
    right: -20,
    top: 250,
    minHeight: 312,
    height: 'calc(100vh - 300px)',
    overflow: 'scroll',
  },
  header: {
    backgroundSize: '100%, auto',
    backgroundPositionY: '65%',
    //borderBottom: '1px solid lightGray',
    height: 28,
    textAlign: 'left',
    padding: '0 0px',
  },
  title: {
    fontSize: '14px !important',
    fontWeight: '700 !important',
    textTransform: 'uppercase',
  },
  formControl: {
    width: 310,
    textAlign: 'left',
    display: 'flex',
    marginTop: 0,
    marginLeft: 10,
    paddingTop: 0,
    textTransform: 'lowercase',
  },
  listText: {
    minHeight: 17,
    textTransform: 'lowercase',
  },
  dimension: {
    position: 'absolute',
    top: 8,
    right: 0,
    textTransform: 'lowercase',
  },
  footDimension: {
    '&:hover': {
      textDecoration: 'underline !important',
    },
  },

  selected: {
    textDecoration: 'underline',
  },
  parentButton: {
    textTransform: 'uppercase',
  },
})

export function NextButton(props: {to: any; children: any; prev?: boolean; next?: boolean}) {
  const {to, children, prev, next} = props
  return (
    <Button
      component={Link}
      size="small"
      startIcon={prev && <ChevronLeftOutlined style={{width: 15}} />}
      endIcon={next && <ChevronRightOutlined style={{width: 15}} />}
      to={to}
      style={prev ? {position: 'absolute', left: 5, fontSize: 14, textTransform: 'lowercase'} : {position: 'absolute', right: 5, fontSize: 14, textTransform: 'lowercase'}}>
      {children}
    </Button>
  )
}

export function AccordionElement(props: {id: string; title: string; expanded: boolean; children?: any; handleChange: any}) {
  const {id, title, children, expanded, handleChange} = props
  return (
    <Accordion expanded={expanded} disableGutters onChange={handleChange(id)}>
      <AccordionSummary id={title}>
        <ParentButton>{title}</ParentButton>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

export function ListButton(props: {title?: string; val?: string | number | any; units?: string; to?: string; selected?: boolean; onClick?: () => any}) {
  const {title, val, units, to = '', selected = false, onClick} = props
  const classes = useStyles()
  return (
    <ListItem component={Link} to={to} selected={selected} onClick={onClick}>
      <span className={classes.listText}>{title}</span>
      <span className={classes.dimension}>
        {val} {units}
      </span>
    </ListItem>
  )
}

export function ParentButton(props: {to?: string; children: any; onClick?: () => any}) {
  const {to = '', children, onClick = () => null} = props
  const classes = useStyles()

  return (
    <ListItem component={Link} to={to} onClick={onClick}>
      <span className={classes.parentButton}>{children}</span>
    </ListItem>
  )
}

export function MenuBox(props: {children: any}) {
  const classes = useStyles()
  return <div className={classes.menubox}>{props.children}</div>
}

export function AccordionMaterialSelector(props: {
  title: string
  id?: string
  value: string | number
  expanded: string | false
  handleChange: any
  options: any[]
  onClick: (e: any) => void
  last?: boolean
}) {
  const {title, id = title, value, expanded, handleChange, options, onClick, last} = props
  const [subTitle, setSubTitle] = useState(options.find((option) => option.id === value)?.name)

  const onMouseOver = useCallback((test: string) => {
    setSubTitle(test)
  }, [])

  const onMouseOut = useCallback(() => {
    setSubTitle(options.find((option) => option.id === value)?.name)
  }, [options, value])

  useEffect(() => {
    setSubTitle(options.find((option) => option.id === value)?.name)
  }, [options, value])
  return (
    // <ClickAwayListener
    //   onClickAway={() => {
    //     handleChange(expanded)(title, !(expanded === title))
    //   }}>
    <Accordion expanded={expanded === id} disableGutters onChange={handleChange(id)}>
      <AccordionSummary id={id}>
        <ListButton title={title} val={subTitle} />
      </AccordionSummary>
      <AccordionDetails className={last ? 'details' : undefined}>
        <div className="materialSelector">
          {options.map(
            (option, i) =>
              (!option.color && !option.icon && (
                <img key={i} onMouseOver={() => onMouseOver(option.name)} onMouseOut={onMouseOut} onMouseDown={() => onClick(option.id)} src={option.src} alt={option.name} />
              )) ||
              (option.color && <div key={i} onMouseOver={() => onMouseOver(option.name)} onMouseOut={onMouseOut} onMouseDown={() => onClick(option.id)} style={{backgroundColor: option.color}} />) ||
              (option.icon && (
                <div key={i} onMouseOver={() => onMouseOver(option.name)} onMouseOut={onMouseOut} onMouseDown={() => onClick(option.id)}>
                  {option.icon}
                </div>
              ))
          )}
        </div>
      </AccordionDetails>
    </Accordion>
    // </ClickAwayListener>
  )
}

export function AccordionListSelector(props: {title: string; id?: string; value: number; expanded: string | false; handleChange: any; options: any[]; onClick: (e: any) => void; last?: boolean}) {
  const {title, id = title, value, expanded, handleChange, options, onClick, last} = props
  const [subTitle, setSubTitle] = useState(options.find((option) => option.id === value)?.name)

  useEffect(() => {
    const name = options.find((option) => option.id === value)?.name
    setSubTitle(name)
  }, [options, value])

  return (
    // <ClickAwayListener
    //   onClickAway={() => {
    //     handleChange(expanded)(title, !(expanded === title))
    //   }}>
    <Accordion expanded={expanded === id} disableGutters onChange={handleChange(id)}>
      <AccordionSummary id={id}>
        <ListButton title={title} to="" val={subTitle} />
      </AccordionSummary>
      <AccordionDetails className={last ? 'details' : undefined}>
        {options.map((option, i) => (
          <ListButton selected={value === option.id} key={i} val={option.name} onClick={() => onClick(option.id)} />
        ))}
      </AccordionDetails>
    </Accordion>
    // </ClickAwayListener>
  )
}

export function AccordionSizeSlider(props: {
  title: string
  id?: string
  value: number
  min: number
  max: number
  expanded: string | false
  handleChange: any
  options?: any[]
  clickRange?: number
  step?: number
  onClick: (e: any) => void
  readonly?: boolean
  last?: boolean
}) {
  const {title, id = title, value, min, max, expanded, handleChange, options = [], onClick, last, clickRange = 50, step = 10, readonly = false} = props

  const [value1, setValue] = useState<number | string | Array<number | string>>(value)

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    const test = options.find((option) => Math.abs(option * 1000 - (newValue as number)) < clickRange) * 1000
    setValue(test || newValue)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(Number(event.target.value))) {
      setValue(event.target.value === '' ? '' : Number(event.target.value))
    }
  }

  const handleInputCommitted = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (value1 < min) {
        setValue(min)
        onClick(min / 1000)
      } else if (value1 > max) {
        setValue(max)
        onClick(max / 1000)
      } else {
        onClick((value1 as number) / 1000)
      }
    }
  }

  const handleBlur = () => {
    if (value1 < min) {
      setValue(min)
    } else if (value1 > max) {
      setValue(max)
    }
  }

  useEffect(() => {
    setValue(value)
  }, [value])

  return (
    <div className="sizeSlider">
      <Accordion expanded={expanded === id} disableGutters onChange={handleChange(id)}>
        <AccordionSummary id={id}>
          <ListButton title={title} to="" />
        </AccordionSummary>
        <AccordionDetails className={last ? 'details' : undefined}>
          <Slider
            className="slider"
            track={false}
            min={min}
            max={max}
            step={step}
            size={'small'}
            value={typeof value1 === 'number' ? value1 : 0}
            onChangeCommitted={() => onClick((value1 as number) / 1000)}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            marks={options.map((option) => ({value: option * 1000, label: option * 1000}))}
          />
        </AccordionDetails>
      </Accordion>
      <Input
        className="input"
        value={value1}
        size="small"
        onChange={handleInputChange}
        onBlur={handleBlur}
        onKeyDown={handleInputCommitted}
        type="number"
        endAdornment={<InputAdornment position="end">mm</InputAdornment>}
        disableUnderline
        readOnly={readonly}
        inputProps={{
          step: step,
          min: min,
          max: max,
          type: 'text',
          pattern: '[0-9]*',
          inputMode: 'numeric',
          'aria-labelledby': 'input-slider',
        }}
      />
    </div>
  )
}

export function AccordionSlider(props: {
  title: string
  id?: string
  value: number
  min: number
  max: number
  expanded: string | false
  handleChange: any
  options: {id: number; name: string | number; longName: string}[]
  onClick: (e: any) => void
  last?: boolean
}) {
  const {title, id = title, value, min, max, expanded, handleChange, options, onClick, last} = props

  const [subTitle, setSubTitle] = useState(options.find((option) => option.id === value)?.longName)

  const [value1, setValue] = useState<number | string | Array<number | string>>(value)

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue)
  }

  useEffect(() => {
    setSubTitle(options.find((option) => option.id === value)?.longName)
    setValue(value)
  }, [options, value])
  return (
    // <ClickAwayListener
    //   onClickAway={() => {
    //     handleChange(expanded)(title, !(expanded === title))
    //   }}>
    <Accordion expanded={expanded === id} disableGutters onChange={handleChange(id)}>
      <AccordionSummary id={id}>
        <ListButton title={title} val={subTitle} />
      </AccordionSummary>
      <AccordionDetails className={last ? 'details' : undefined}>
        <Slider
          className="AccordionSlider"
          track={false}
          min={min}
          max={max}
          step={null}
          size={'small'}
          value={typeof value1 === 'number' ? value1 : 0}
          onChangeCommitted={() => onClick(value1 as number)}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
          marks={options.map((option) => ({value: option.id, label: option.name}))}
        />
      </AccordionDetails>
    </Accordion>
    // </ClickAwayListener>
  )
}

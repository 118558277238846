import {useStore} from 'products/digital/store/store'
import shallow from 'zustand/shallow'
import {shelfMaterials} from 'products/digital/store/datamodels'
import {materialModel} from 'utils/datamodels'

export const SteelBeamList = () => {
  const boardList = useStore((store) => store.config.rows.list)
  const [width, depth, materialID, feetID] = useStore((store) => [store.config.main.width, store.config.main.depth, store.config.main.materialID, store.config.feet.id], shallow)
  const thickness = shelfMaterials[materialID].thickness
  let startPos = 0
  const steelBeams: {key: string; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof materialModel; type: string}[] = []

  boardList.forEach((el, index) => {
    startPos += el.height + thickness
    steelBeams.push({
      key: 'back - ' + index,
      xPos: thickness / 2,
      yPos: startPos - 0.025,
      zPos: 0.1,
      length: width - thickness,
      depth: 0.015,
      thickness: 0.025,
      material: shelfMaterials[materialID].steelMaterial as keyof typeof materialModel,
      type: 'steelBeam',
    })
    steelBeams.push({
      key: 'front - ' + index,
      xPos: thickness / 2,
      yPos: startPos - 0.025,
      zPos: depth - 0.1 - 0.015,
      length: width - thickness,
      depth: 0.015,
      thickness: 0.025,
      material: shelfMaterials[materialID].steelMaterial as keyof typeof materialModel,
      type: 'steelBeam',
    })
  })

  feetID === 'u308' &&
    steelBeams.push({
      key: 'backFeetSteel',
      xPos: 0.005,
      yPos: 0,
      zPos: 0.05,
      length: width - 0.01,
      depth: 0.02,
      thickness: 0.02,
      material: 'solidBlack' as keyof typeof materialModel,
      type: 'steelBeamFeets',
    })
  feetID === 'u308' &&
    steelBeams.push({
      key: 'frontFeetSteel',
      xPos: 0.005,
      yPos: 0,
      zPos: depth - 0.05,
      length: width - 0.01,
      depth: 0.02,
      thickness: 0.02,
      material: 'solidBlack' as keyof typeof materialModel,
      type: 'steelBeamFeets',
    })

  return steelBeams
}

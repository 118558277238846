import {Selector} from 'common/threeComponents/FiberBoard'
import {regalModel, shelfMaterials, shelfTypes} from 'products/kubatur/store/datamodels'
import {useStore} from 'products/kubatur/store/store'
import {accHelper} from 'utils/helpers'
import shallow from 'zustand/shallow'

export const ConnectedTabelarSelectors = () => {
  const visible = useStore((store) => store.view.tabelars.edit)
  const [columns, boards, depth, height, grid, shelfTyp] = useStore(
    (store) => [store.config.columns.list, store.config.boards.list, store.config.main.depth, store.config.main.height, store.config.main.grid, store.config.main.shelfTyp],
    shallow
  )
  const subColumns = useStore((store) => store.config.subColumns.list)
  const thickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  let startPos = thickness
  const toggleTabelar = useStore((store) => store.tabelar.toggleTabelar)
  const maxCols = grid * (Math.round((height - thickness - shelfTypes[shelfTyp].bottomInset - shelfTypes[shelfTyp].topInset) / (grid * 0.065)) + 1)
  const maxColGrid = (height - thickness - shelfTypes[shelfTyp].bottomInset - shelfTypes[shelfTyp].topInset) / maxCols

  return (
    <>
      {visible &&
        columns.map((el, i) => {
          startPos += el.width + thickness
          return [...Array(maxCols - 3)].map((_, j) => {
            const posIndex = Math.floor((j + 2) / (maxCols / grid))
            const subColumnList = accHelper.get_SubColumn_List({x: i, y: posIndex}, columns, boards, subColumns)
            const board = (j + 2) % (maxCols / grid) === 0 && boards[i].includes(posIndex)
            return (
              !board &&
              [...Array(subColumnList.length - 1)].map((_, k) => {
                const width = (el.width + thickness) * (subColumnList[k + 1] - subColumnList[k]) - thickness
                return (
                  <Selector
                    key={k}
                    size={[width, thickness, depth]}
                    onClick={() => toggleTabelar(i + subColumnList[k], j + 2)}
                    xPos={startPos - (1 - subColumnList[k]) * (el.width + thickness)}
                    yPos={(j + 2) * maxColGrid + shelfTypes[shelfTyp].bottomInset}
                    zPos={0}
                  />
                )
              })
            )
          })
        })}
    </>
  )
}

import {LocaleToLocalStorage} from 'common/LocaleToLocalStorage/LocaleToLocalStorage'
import {useCommonStore} from 'common/Store/CommonStore'
import {LocaleModal} from 'components/material_interfaces/material_interfaces'
import {Price} from 'products/kubatur/utils/price'
import {Route, Routes} from 'react-router-dom'
import {LoadModal} from 'utils/API/components/LoadModal'
import {SaveConfigModal} from 'utils/API/components/SaveConfigModal'
import {SaveLoadModal} from 'utils/API/components/SaveLoadModal'
import shallow from 'zustand/shallow'
import {ViewContainer} from '../../../../components/ViewContainer/ViewContainer'
import {localFactors} from '../../../../utils/datamodels'
import {backpanelMaterials, baseUrl, frontMaterials, regalModel, shelfMaterials, shelfTypes, slidingDoorMaterials} from '../../store/datamodels'
import {useStore} from '../../store/store'
import {ConnectedKubatur} from '../../threeComponents/Kubatur'
import {ShelfStructureModals} from '../Shelf/ShelfStructureModals'
import {IndexView} from './components/IndexView'
import {PerspectiveControls} from './components/PerspectiveControls/PerspectiveControls'
import ConnectedKubaturPrintView from 'products/kubatur/print/PrintView'
import {FiberScene} from 'common/threeComponents/FiberScene/FiberScene'
import ApiFetch from 'utils/API/ApiFetch'

export const KubaturView = () => {
  document.title = 'SANKTJOHANSER | KUBATUR'
  return (
    <Routes>
      <Route path="/" element={<ConnectedKubaturContainer />}>
        <Route index element={<IndexView />} />
        <Route element={<IndexView />}>
          <Route path="locale" element={<LocaleModal title="Standort" options={localFactors} onSaveto="/kubatur" />} />
          <Route path="load" element={<LoadModal baseUrl={baseUrl} />} />
          <Route path="save" element={<SaveConfigModal reference="kubatur" />} />
          <Route path="saveload" element={<SaveLoadModal toSave="../save" toLoad="../load" toAbort={baseUrl} />} />
          <Route path="structure/*" element={<ShelfStructureModals />} />
        </Route>
      </Route>
      <Route path="print" element={<ConnectedKubaturPrintView />} />
      <Route path="get/:id" element={<ApiFetch />} />
    </Routes>
  )
}

const ConnectedKubaturContainer = () => {
  const [shelfHeight, footHeight, columns, shelfTyp] = useStore((store) => [store.config.main.height, store.config.feet.height, store.config.columns.list, store.config.main.shelfTyp], shallow)

  const thickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const slidingDoorThickness = slidingDoorMaterials[useStore((store) => store.config.slidingDoors.materialID)].thickness
  const doorThickness = frontMaterials[useStore((store) => store.config.doors.materialID)].thickness
  const backpanelThickness = backpanelMaterials[useStore((store) => store.config.backpanels.materialID)].thickness
  const extraDepth = shelfTyp === 1 ? 2 * (slidingDoorThickness + regalModel.slidingDoorDistance + thickness) : regalModel.doorDistance + doorThickness
  const props = {
    shelfMaterialId: useStore((store) => store.config.main.materialID),
    treatmentId: useStore((store) => store.config.main.treatmentID),
    materialSelector: 'seperate',
    width: Math.round(columns.reduce((acc, col) => acc + col.width + thickness, thickness) * 1000) / 1000,
    fullHeight: Math.round((shelfHeight + footHeight) * 1000) / 1000,
    footHeight: footHeight,
    depth:
      Math.round(useStore((store) => store.config.main.depth + extraDepth) * 1000) +
      '/' +
      Math.round(useStore((store) => store.config.main.depth - backpanelThickness - regalModel.backpanelInset) * 1000),
    localFactor: localFactors[useCommonStore((store) => store.localFactorID)],
    baseUrl: baseUrl,
  }

  return (
    <ViewContainer {...props} price={<Price />} title="Kubatur">
      <FiberScene footHeight={footHeight + shelfTypes[shelfTyp].bottomInset}>
        <ConnectedKubatur />
      </FiberScene>
      <PerspectiveControls />
      <LocaleToLocalStorage />
    </ViewContainer>
  )
}

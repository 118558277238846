import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {ClothingRail} from 'common/threeComponents/ClothingRail'
import {ClothingRailList} from 'products/metrum/partLists/clothingRailList'
import {materialModel} from 'utils/datamodels'

export const ConnectedClothingRails = () => {
  const clothingRailList = ClothingRailList()
  if (clothingRailList.length > 0) {
    const materialID = clothingRailList[0].material
    const materialReference = materialModel[materialID].matReference
    const materialObject = MaterialSingleton.getMaterials()
    var material1 = materialObject[materialReference]?.small_1 || materialObject[materialReference]
  }
  return (
    <>
      {clothingRailList.map((tabelar) => {
        return <ClothingRail {...tabelar} material={material1} />
      })}
    </>
  )
}

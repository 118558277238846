import {Route, Routes} from 'react-router-dom'
import shallow from 'zustand/shallow'
import {GridModal, HeightModal, ModalInput, OwnValue, ShelfTypModal} from '../../../../components/material_interfaces/material_interfaces'
import {regalModel, shelfMaterials, shelfTyp} from 'products/tektur/store/datamodels'
import {getMinGrid} from '../../../../utils/helpers'
import {useStore} from '../../store/store'
import {getGridOptions} from 'products/tektur/utils/helpers'

export function ShelfStructureModals(props: any) {
  const {boards, width, depth, grid} = useStore((store) => ({boards: store.config.rows.list, width: store.config.main.width, depth: store.config.main.depth, grid: store.config.main.grid}), shallow)
  const shelfMaterial = useStore((store) => store.config.main.materialID)
  const setWidth = useStore((store) => store.setWidth)
  const thickness = shelfMaterials[shelfMaterial].thickness
  const minGrid = getMinGrid(width, regalModel.gridMax)
  const setDepth = useStore((store) => store.setDepth)
  const setGrid = useStore((store) => store.setGrid)
  const setShelfTyp = useStore((store) => store.setShelfTyp)
  const setRowHeight = useStore((store) => store.boards.setHeight)
  const currentTyp = useStore((store) => store.config.main.shelfTyp)
  const widthList = []
  for (let i = 1; i <= Math.floor(shelfMaterials[shelfMaterial].maxWidth / regalModel.gridSize); i++) {
    widthList.push(Math.round((i * regalModel.gridSize + thickness + 0.001) * 1000) / 1000)
  }
  const gridOptions = getGridOptions(width, depth, shelfMaterials[shelfMaterial])
  const typOptions = [...Object.values(shelfTyp)].map((typ) => {
    return {id: typ.id, name: typ.name}
  })

  return (
    <>
      <Routes>
        <Route
          path="width"
          element={
            <OwnValue
              title="Regalbreite"
              val={width * 1000}
              callback={(newVal: number) => setWidth(newVal / 1000)}
              min={regalModel.minWidth * 1000}
              max={shelfMaterials[shelfMaterial].maxWidth * 1000}
            />
          }
        />
        <Route
          path="depth"
          element={
            <ModalInput
              title="Regaltiefe"
              val={depth * 1000}
              defaultValues={regalModel.depthList}
              callback={(newVal: number) => setDepth(newVal / 1000)}
              min={regalModel.minDepth * 1000}
              max={regalModel.maxDepth * 1000}
            />
          }
        />
        <Route path="grid" element={<GridModal title="Rastermaß" val={gridOptions[grid - minGrid]} options={gridOptions} callback={(newVal: number) => setGrid(newVal)} />} />
        <Route path="typ" element={<ShelfTypModal title="Regaltyp" val={currentTyp} options={typOptions} callback={(newTyp: number) => setShelfTyp(newTyp)} />} />
        <Route
          path="row/:rowNum"
          element={
            <HeightModal
              title="Fachhöhe"
              val={boards}
              defaultValues={regalModel.heightList}
              callback={(newVal: number, rowNum: number) => setRowHeight(newVal / 1000, rowNum)}
              min={regalModel.minColHeight * 1000}
              max={regalModel.maxColHeight * 1000}
            />
          }
        />
      </Routes>
    </>
  )
}

import {useCommonStore} from 'common/Store/CommonStore'
import {AccordionListSelector, AccordionMaterialSelector, AccordionSizeSlider, AccordionSlider, ListButton} from 'components/material_interfaces/materialInterfaces'
import {backpanelMaterials, frontMaterials, regalModel, shelfMaterials, shelfTypes, slidingDoorMaterials} from 'products/kubatur/store/datamodels'
import {useStore} from 'products/kubatur/store/store'
import {materialTreatments, materialTypes} from 'utils/datamodels'
import {getMaxGrid, getMinGrid} from 'utils/helpers'

export function ShelfStructure() {
  const [shelfMaterial, treatmentID, grid, height, depth] = useStore((store) => [
    store.config.main.materialID,
    store.config.main.treatmentID,
    store.config.main.grid,
    store.config.main.height,
    store.config.main.depth,
  ])
  const thickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const shelfTyp = useStore((store) => store.config.main.shelfTyp)
  const maxColGrid = Math.round(
    ((height - thickness - shelfTypes[shelfTyp].topInset - shelfTypes[shelfTyp].bottomInset) /
      (grid * (Math.round((height - thickness - shelfTypes[shelfTyp].topInset - shelfTypes[shelfTyp].bottomInset) / (grid * 0.065)) + 1))) *
      1000
  )
  const shelfTypName = shelfTypes[useStore((store) => store.config.main.shelfTyp)].name
  const setMaterialType = useStore((store) => store.setMaterialType)
  const setShelfMaterialSpecies = useStore((store) => store.setMaterialSpecies)
  const setMaterialTreatment = useStore((store) => store.setMaterialTreatment)
  const setHeight = useStore((store) => store.setHeight)
  const setDepth = useStore((store) => store.setDepth)
  const setGrid = useStore((store) => store.setGrid)
  const materialHasTreatment = shelfMaterials[shelfMaterial].treatment
  const footHeight = useStore((store) => store.config.feet.height)
  const fullHeight = Math.round((height + footHeight) * 1000) / 1000
  const slidingDoorThickness = slidingDoorMaterials[useStore((store) => store.config.slidingDoors.materialID)].thickness
  const doorThickness = frontMaterials[useStore((store) => store.config.doors.materialID)].thickness
  const backpanelThickness = backpanelMaterials[useStore((store) => store.config.backpanels.materialID)].thickness
  const extraDepth = shelfTyp === 1 ? 2 * (slidingDoorThickness + regalModel.slidingDoorDistance + thickness) : doorThickness + regalModel.doorDistance
  const fullDepth = depth + extraDepth
  const usableDepth = depth - backpanelThickness - regalModel.backpanelInset

  const [expanded, setExpanded] = useCommonStore((store) => [store.expandedSecond, store.setExpandedSecond])

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }
  const regalMaterialType = shelfMaterials[useStore((store) => store.config.main.materialID)].type
  const materialTypeList = Object.values(shelfMaterials).map((material) => material.type)
  const typeList = materialTypes.filter((type) => materialTypeList.includes(type.id))

  const materialOptions = [...Object.values(shelfMaterials)].filter((material) => material.type === regalMaterialType)

  const treatmentOptions = [...Object.values(materialTreatments)]

  const minGrid = getMinGrid(height, regalModel.gridMax)
  const maxGrid = getMaxGrid(height, regalModel.gridMin)

  const gridOptions = []
  for (let i = minGrid; i <= maxGrid; i++) {
    const gridSize = (height - thickness - shelfTypes[shelfTyp].topInset - shelfTypes[shelfTyp].bottomInset) / i
    gridOptions.push({id: i, name: i + `x${Math.round(gridSize * 1000)}`, longName: i + ` x ${Math.round(gridSize * 1000)} mm`})
  }

  const typeOptions = typeList.map((type) => {
    const thickness =
      shelfMaterials[shelfMaterial].type === type.id ? shelfMaterials[shelfMaterial].thickness : shelfMaterials[shelfMaterials[shelfMaterial].fallback as keyof typeof shelfMaterials].thickness
    return {...type, name: type.name + ', ' + Math.round(thickness * 1000) + ' mm'}
  })

  return (
    <>
      <AccordionListSelector id="type" options={typeOptions} onClick={setMaterialType} title={'Werkstoff'} value={regalMaterialType} expanded={expanded} handleChange={handleChange} />
      <AccordionMaterialSelector
        id="species"
        options={materialOptions}
        onClick={setShelfMaterialSpecies}
        title={'Holzart / Farbe'}
        value={shelfMaterial}
        expanded={expanded}
        handleChange={handleChange}
      />
      {materialHasTreatment && (
        <AccordionListSelector id="treatment" options={treatmentOptions} onClick={setMaterialTreatment} title={'Oberfläche'} value={treatmentID} expanded={expanded} handleChange={handleChange} />
      )}
      <AccordionSizeSlider
        min={regalModel.minHeight * 1000}
        max={shelfMaterials[shelfMaterial].maxWidth * 1000}
        onClick={setHeight}
        title={'Schrankhöhe'}
        id={'height'}
        value={fullHeight * 1000}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionSizeSlider
        min={regalModel.minDepth * 1000}
        max={regalModel.maxDepth * 1000}
        onClick={(newVal: number) => setDepth(newVal - extraDepth)}
        id={'depth'}
        title={'Schranktiefe / Nutztiefe: ' + Math.round(usableDepth * 1000) + ' mm'}
        value={Math.round(fullDepth * 1000)}
        clickRange={20}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <AccordionSlider
        id={'grid'}
        options={gridOptions}
        min={minGrid - 0.3}
        max={maxGrid + 0.3}
        onClick={setGrid}
        title={'Grobraster (Feinraster: ' + maxColGrid + 'mm)'}
        value={grid}
        expanded={expanded}
        handleChange={handleChange}
        last
      />
      <ListButton title="Schranktyp" val={shelfTypName} to="structure/typ" units={undefined} selected={undefined}></ListButton>
    </>
  )
}

import {GetState} from 'zustand'
import {IDoorActions, IStore} from '../..'
import {accHelper} from '../../../../utils/helpers'
import {doorMaterials} from '../datamodels'
import {ISetProduce} from '../store'

export const createDoorSlice = (setProduce: ISetProduce<IStore>, get: GetState<IStore>) => {
  const doors: IDoorActions = {
    setVariant: function (variant) {
      setProduce((state: IStore) => {
        state.view.doors.variant = variant
        if (variant === 0) state.view.doors.editSize = {x: state.view.doors.editSize.x, y: 1}
      })
    },
    setVisible: function (show: boolean): void {
      setProduce((state: IStore) => {
        state.view.doors.visible = show
      })
    },
    clickedAt: function (width, height, pos): void {
      // nicer way: state.doors.length === clearedArray.length -> not cleard, so add new door :)
      setProduce((state: IStore) => {
        const clearedArray = state.config.doors.list.filter((door) => {
          if (accHelper.is_overlapping_with_other(pos, door.pos, {x: width, y: height}, door.size)) return false
          return true
        })
        if (state.config.doors.list.find((door) => accHelper.same_is_at_position(pos, door.pos, {x: width, y: height}, door.size))) {
          state.config.doors.list = clearedArray
        } else {
          state.config.doors.list = [
            ...clearedArray,
            {
              pos: pos,
              size: {x: width, y: height},
              type: state.view.doors.variant as 0 | 1,
              face: 'front',
            },
          ]
        }
        state.config.doors.list.sort((a, b) => (a.pos.y > b.pos.y ? 1 : a.pos.y === b.pos.y ? (a.pos.x > b.pos.x ? 1 : -1) : -1))
      })
    },
    create: function (newDoors): void {
      setProduce((state: IStore) => {
        state.config.doors.list = newDoors
      })
    },
    setEdit: function (edit: boolean): void {
      setProduce((store: IStore) => {
        store.view.doors.edit = edit
      })
    },
    setEditSize: function (size): void {
      setProduce((state: IStore) => {
        state.view.doors.editSize = size
      })
    },
    changeGrid: (newGrid) =>
      setProduce((state: IStore) => {
        const newDoors = state.config.doors.list.filter((door) => {
          if (door.pos.y + door.size.y - 1 > state.config.rows.list.length) return false
          if (door.pos.x + door.size.x > newGrid) return false
          return true
        })
        state.config.doors.list = newDoors
      }),

    setMaterialID: (newMaterialID: keyof typeof doorMaterials): void =>
      setProduce((state: IStore) => {
        state.config.doors.materialID = newMaterialID
      }),
    clearDoors: () => {
      const clearedDoors = get()
        .config.doors.list.filter((door) => {
          const doorIsInShelf = door.pos.y < get().config.rows.list.length && door.pos.x < get().config.main.grid
          if (doorIsInShelf) return true
          return false
        })
        .map((door) => {
          return {
            ...door,
            size: {
              x: door.pos.x + door.size.x > get().config.main.grid ? get().config.main.grid - door.pos.x : door.size.x,
              y: door.pos.y + door.size.y > get().config.rows.list.length ? get().config.rows.list.length - door.pos.y : door.size.y,
            },
          }
        })
      return clearedDoors
    },
  }

  return {
    doors,
  }
}

import {Route, Routes} from 'react-router-dom'
import shallow from 'zustand/shallow'
import {ColsModal, HeightModal, ModalInput} from '../../../../components/material_interfaces/material_interfaces'
import * as lookup from '../../store/datamodels'
import {getMaxGrid, getMinGrid} from '../../../../utils/helpers'
import {useStore} from '../../store/store'

export const ShelfStructureModals = () => {
  const {width, depth, grid} = useStore((store) => ({width: store.config.main.width, depth: store.config.main.depth, grid: store.config.main.grid}), shallow)
  const boards = useStore((store) => store.config.rows)
  const shelfMaterial = useStore((store) => store.config.main.materialID)
  const setWidth = useStore((store) => store.setWidth)
  const minGrid = getMinGrid(width, lookup.blueprint.gridMax)
  const maxGrid = getMaxGrid(width, lookup.blueprint.gridMin)
  const setDepth = useStore((store) => store.setDepth)
  const setGrid = useStore((store) => store.setGrid)
  const setRowHeight = useStore((store) => store.rows.setHeight)

  return (
    <>
      <Routes>
        <Route
          path="/width"
          element={
            <ModalInput
              title="Regalbreite"
              val={width * 1000}
              defaultValues={lookup.blueprint.width}
              callback={(newVal: number) => setWidth(newVal / 1000)}
              min={lookup.blueprint.minWidth * 1000}
              max={lookup.shelfMaterialModel[shelfMaterial]?.maxWidth * 1000}
            />
          }
        />
        <Route
          path="/depth"
          element={
            <ModalInput
              title="Regaltiefe"
              defaultValues={lookup.blueprint.depth}
              val={depth * 1000}
              callback={(newVal: number) => setDepth(newVal / 1000)}
              min={lookup.blueprint.minDepth * 1000}
              max={lookup.blueprint.maxDepth * 1000}
            />
          }
        />
        <Route path="/cols" element={<ColsModal title="Rastermaß" val={grid} min={minGrid} max={maxGrid} callback={(newVal: number) => setGrid(newVal)} />} />
        <Route
          path="/row/:rowNum"
          element={
            <HeightModal
              title="Fachhöhe"
              val={boards.list}
              defaultValues={lookup.blueprint.colHeight}
              callback={(newVal: number, rowNum: number) => setRowHeight(newVal / 1000, rowNum)}
              min={lookup.blueprint.minColHeight * 1000}
              max={lookup.blueprint.maxColHeight * 1000}
            />
          }
        />
      </Routes>
    </>
  )
}

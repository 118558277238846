import React, {useState, useRef, useEffect} from 'react'
import {Button, TextField, Paper, Select, FormControl, MenuItem, ListItem, Dialog, DialogTitle, DialogContent, DialogActions, Typography, ListItemIcon, List} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {ChevronLeftOutlined, ChevronRightOutlined} from '@mui/icons-material'
import {useCommonStore} from 'common/Store/CommonStore'

export const useStyles = makeStyles({
  card: {
    minWidth: 326,
    position: 'absolute',
    right: -16,
    top: 250,
    minHeight: 312,
  },
  menubox: {
    width: 326,
    minWidth: 326,
    position: 'absolute',
    right: -16,
    top: 250,
    minHeight: 312,
  },
  header: {
    backgroundSize: '100%, auto',
    backgroundPositionY: '65%',
    //borderBottom: '1px solid lightGray',
    height: 28,
    textAlign: 'left',
    padding: '0 16px',
  },
  title: {
    fontSize: '14px !important',
    fontWeight: '700 !important',
    textTransform: 'uppercase',
  },
  formControl: {
    width: 310,
    textAlign: 'left',
    display: 'flex',
    marginTop: 0,
    marginLeft: 10,
    paddingTop: 0,
    textTransform: 'lowercase',
  },
  listText: {
    width: 250,
    minHeight: 17,
    textTransform: 'lowercase',
  },
  dimension: {
    position: 'absolute',
    top: 8,
    right: 0,
  },
  footDimension: {
    '&:hover': {
      textDecoration: 'underline !important',
    },
  },

  selected: {
    textDecoration: 'underline',
  },
  parentButton: {
    textTransform: 'uppercase',
  },
})

export const MenuBox = ({title, next = null, prev = null, children = []}) => {
  return (
    <CardBox>
      {(prev || next) && (
        <NextButtonContainer>
          {prev && (
            <NextButton prev to={prev.url}>
              {prev.name}
            </NextButton>
          )}
          {next && (
            <NextButton next to={next.url}>
              {next.name}
            </NextButton>
          )}
        </NextButtonContainer>
      )}
      <List disablePadding>{children}</List>
    </CardBox>
  )
}

export function CardBox(props) {
  const classes = useStyles()
  return (
    <Paper className={classes.card} elevation={0} square>
      {props.children}
    </Paper>
  )
}

export function Header({title, imgUrl = undefined}) {
  const classes = useStyles()
  return (
    <div className={classes.header} style={imgUrl && {backgroundImage: imgUrl}}>
      <Typography className={classes.title}>{title}</Typography>
    </div>
  )
}

export function ParentButton({to, children}) {
  const classes = useStyles()

  return (
    <ListItem component={Link} to={to}>
      <span className={classes.parentButton}>{children}</span>
    </ListItem>
  )
}

export function MaterialElement({title, callback, selected, val, units}) {
  const classes = useStyles()

  return (
    // <MaterialListButton title={title} val={val} units={units} to={to} selected={id === idNr} />
    <ListItem
      selected={selected}
      onClick={() => {
        callback()
      }}>
      <span className={classes.listText}>{title}</span>
      <span className={classes.dimension}>
        {val} {units}
      </span>
    </ListItem>
  )
}

export function FootList({title, callback, setHeight, selected, val, units, footHeight}) {
  const classes = useStyles()

  return (
    // <MaterialListButton title={title} val={val} units={units} to={to} selected={id === idNr} />
    <ListItem
      selected={selected}
      onClick={() => {
        callback()
      }}>
      <span className={classes.listText}>{title}</span>
      <span className={classes.dimension}>
        {val &&
          val.map((el, i, {length}) =>
            i === length - 1 ? (
              <span key={i} onClick={() => setHeight(el)}>
                <span className={selected && el === footHeight ? classes.selected : classes.footDimension}>{el * 1000}</span>{' '}
              </span>
            ) : (
              <span key={i} onClick={() => setHeight(el)}>
                <span className={selected && el === footHeight ? classes.selected : classes.footDimension}>{el * 1000}</span> | {''}
              </span>
            )
          )}
        {val && units}
      </span>
    </ListItem>
  )
}

export function ListButton({title, val, units, to, selected}) {
  selected = selected || false
  const classes = useStyles()
  return (
    <ListItem button component={to && Link} to={to} selected={selected}>
      <span className={classes.listText}>{title}</span>
      <span className={classes.dimension}>
        {val} {units}
      </span>
    </ListItem>
  )
}

export function MaterialSelector({variantModel, doorVariant, callback}) {
  const classes = useStyles()
  const s = useRef()

  const handleChangeType = (e) => {
    const newVal = e.target.value
    callback(newVal)
  }
  return (
    <FormControl variant="standard" className={classes.formControl} ref={s}>
      <Select variant="standard" labelId="door-select-helper-label" id="door-select-helper" value={doorVariant} onChange={handleChangeType}>
        {variantModel.map((variant) => (
          <MenuItem key={variant.id} value={variant.id}>
            {variant.name}
          </MenuItem>
        ))}
      </Select>
      {/* <FormHelperText>Some important helper text</FormHelperText> */}
    </FormControl>
  )
}

export function DoorListButton(props) {
  const classes = useStyles()
  return (
    <ListItem onClick={props.callback} component={Link} to="#" selected={props.selected}>
      <ListItemIcon style={{minWidth: 35}}>{props.icon}</ListItemIcon>
      <span className={classes.listText}>{props.title}</span>
    </ListItem>
  )
}

export function ListCallbackButton({title, val, callback, selected}) {
  selected = selected || false
  const classes = useStyles()
  return (
    <ListItem onClick={callback} selected={selected}>
      <span className={classes.listText}>{title}</span>
      {val && <span className={classes.dimension}>{val}</span>}
    </ListItem>
  )
}

export function ModalInput({title, val, defaultValues, callback, min, max}) {
  let navigate = useNavigate()
  const [custom, setCustom] = useState(false)
  const [value, setValue] = useState(val)
  const defaultValues_mm = defaultValues.map((val) => val * 1000)

  useEffect(() => {
    const keyDownHandler = (e) => {
      if (e.key === 'Escape') {
        navigate(-1)
      } else if (e.key === 'Enter') {
        callback(value)
        navigate(-1)
      }
    }
    document.addEventListener('keydown', keyDownHandler, true)
    return () => {
      document.removeEventListener('keydown', keyDownHandler, true)
    }
  })

  const handleChange = (e) => {
    const newVal = e.target.value
    if (newVal === 'own') {
      setCustom(true)
    } else {
      setValue(Number(newVal) || '')
      const newValue = newVal > max ? max : newVal < min ? min : newVal
      if (!custom) callback(newValue)
    }
  }

  const handleSave = () => {
    let newValue = value
    newValue = newValue > max ? max : newValue < min ? min : newValue
    if (callback && value !== val) callback(newValue)
    navigate(-1)
  }

  return (
    <Dialog open>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {!custom && (
          <FormControl variant="standard" className="" fullWidth>
            <Select variant="standard" value={value} onChange={handleChange}>
              {defaultValues_mm.includes(val) === false && <MenuItem value={val}>{val} mm</MenuItem>}
              {defaultValues_mm.map((el, index) => (
                <MenuItem key={index} value={el}>
                  {el} mm
                </MenuItem>
              ))}
              <MenuItem value="own">Eigene</MenuItem>
            </Select>
          </FormControl>
        )}
        {custom && <TextField variant="standard" autoFocus margin="dense" helperText={min + ' - ' + max + ' mm'} label={title} onChange={handleChange} value={value} type="number" fullWidth />}
        <DialogActions>
          <Button onClick={() => navigate(-1)}>abbrechen</Button>
          <Button onClick={() => handleSave()}>OK</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export function ShelfTypModal({title, val, options, callback}) {
  let navigate = useNavigate()
  const [value, setValue] = useState(val)

  const handleChange = (e) => {
    const newVal = e.target.value
    setValue(newVal)
  }

  const handleSave = () => {
    let newValue = value
    const callbackValue = options[newValue]?.callbackValue === undefined ? newValue : options[newValue].callbackValue
    if (callback && value !== val) callback(callbackValue)
    navigate(-1)
  }

  return (
    <Dialog open>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FormControl variant="standard" className="" fullWidth>
          <Select variant="standard" value={value} onChange={handleChange}>
            {options.map((el, i) => (
              <MenuItem key={i} value={el.id}>
                {el.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <DialogActions>
          <Button onClick={() => navigate(-1)}>abbrechen</Button>
          <Button onClick={() => handleSave()}>OK</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export function OwnValue({title, val, callback, min, max}) {
  let navigate = useNavigate()
  const [value, setValue] = useState(val)

  useEffect(() => {
    const keyDownHandler = (e) => {
      if (e.key === 'Escape') {
        navigate(-1)
      } else if (e.key === 'Enter') {
        callback(value)
        navigate(-1)
      }
    }
    document.addEventListener('keydown', keyDownHandler, true)
    return () => {
      document.removeEventListener('keydown', keyDownHandler, true)
    }
  })

  const handleChange = (e) => {
    const newVal = e.target.value
    setValue(Number(newVal) || '')
  }

  const handleSave = () => {
    let newValue = value
    newValue = newValue > max ? max : newValue < min ? min : newValue
    if (callback && value !== val) callback(newValue)
    navigate(-1)
  }

  return (
    <Dialog open>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {<TextField variant="standard" autoFocus margin="dense" helperText={min + ' - ' + max + ' mm'} onChange={handleChange} value={value} type="number" />}
        <DialogActions>
          <Button onClick={() => navigate(-1)}>abbrechen</Button>
          <Button onClick={() => handleSave()}>OK</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export function HeightModal({title, val, defaultValues, callback, min, max}) {
  let {rowNum} = useParams()
  const colHeight = val[rowNum].height * 1000
  const defaultOutervalues = defaultValues.map((val) => val)

  return <ModalInput title={title} val={colHeight} defaultValues={defaultOutervalues} callback={(newVal) => callback(newVal, rowNum)} min={min} max={max} />
}

export function WidthModal({title, val, defaultValues, callback, min, max}) {
  let {colNum} = useParams()
  const colHeight = val[colNum].width * 1000

  return <OwnValue title={title} val={colHeight} callback={(newVal) => callback(newVal, colNum)} min={min} max={max} />
}

export function ColsModal({title, val, min, max, callback}) {
  let navigate = useNavigate()
  const classes = useStyles()
  const [value, setValue] = useState(val)
  const options = []
  for (let i = min; i <= max; i++) {
    options.push(i)
  }

  const handleChange = (e) => {
    setValue(Number(e.target.value) || '')
  }
  const handleSave = () => {
    if (callback && value !== val) callback(value)
    navigate(-1)
  }

  return (
    <GenericDialog title={title} open={true} onSave={() => handleSave(value)} onAbort={() => navigate(-1)}>
      <FormControl variant="standard" className={classes.formControl}>
        <Select variant="standard" value={value} onChange={handleChange}>
          {options.map((el, i) => (
            <MenuItem key={i} value={el}>
              {el} Elemente
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </GenericDialog>
  )
}

export function GridModal({title, val, options, callback}) {
  let navigate = useNavigate()
  const [value, setValue] = useState(val)
  const classes = useStyles()

  const handleChange = (e) => {
    setValue(e.target.value)
  }
  const handleSave = () => {
    if (callback && value !== val) callback(value.col)
    navigate(-1)
  }

  return (
    <GenericDialog title={title} open={true} onSave={() => handleSave(value)} onAbort={() => navigate(-1)}>
      <FormControl className={classes.formControl}>
        <Select value={value} onChange={handleChange}>
          {options.map((el, i) => (
            <MenuItem key={i} value={el}>
              {el.col} Elemente, max. {el.maxLoad} kg / Fach
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </GenericDialog>
  )
}

// TODO: Locale should be set for whole configurator, not just per product
export function LocaleModal({title, options, onSaveto}) {
  let navigate = useNavigate()
  const classes = useStyles()

  const localFactorID = useCommonStore((store) => store.localFactorID)
  const setLocalFactorID = useCommonStore((store) => store.setLocalFactorID)
  const [value, setValue] = useState(localFactorID | 0)
  //debugger

  const handleChange = (e) => {
    setValue(e.target.value)
  }
  const handleSave = () => {
    // if (value !== localFactor)
    setLocalFactorID(value)
  }

  return (
    <LocalDialog title={title} open={true} onSave={() => handleSave(value)} onAbort={() => navigate(-1)} onSaveto={onSaveto}>
      <FormControl variant="standard" className={classes.formControl}>
        <Select variant="standard" value={value} onChange={handleChange}>
          {options.map((el, i) => (
            <MenuItem key={i} value={el.id}>
              {el.fullname} {el.vat}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </LocalDialog>
  )
}

function LocalDialog({open, title, onSave, onAbort, children, onSaveto}) {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={() => onAbort()}>abbrechen</Button>
        <Button component={Link} to={onSaveto} onClick={() => onSave()}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function GenericDialog({open, title, onSave, onAbort, children}) {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={() => onAbort()}>abbrechen</Button>
        <Button onClick={() => onSave()}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

export function NextButton({to, prev, next, children}) {
  return (
    <Button
      component={Link}
      size="small"
      startIcon={prev && <ChevronLeftOutlined style={{width: 15}} />}
      endIcon={next && <ChevronRightOutlined style={{width: 15}} />}
      to={to}
      style={{position: 'absolute', left: prev && 5, right: next && 5, fontSize: 14, textTransform: 'lowercase'}}>
      {children}
    </Button>
  )
}

export function NextButtonContainer({children}) {
  return (
    <div
      style={{
        position: 'relative',
        padding: 0,
        height: 33,
      }}>
      {children}
    </div>
  )
}

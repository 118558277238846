import './overview.scss'
import metrum_1 from 'common/static/overview/metrum-1.jpg'
import metrum_2 from 'common/static/overview/metrum-2.jpg'
import metrum_3 from 'common/static/overview/metrum-3.jpg'
import metrum_4 from 'common/static/overview/metrum-4.jpg'
import metrum_5 from 'common/static/overview/metrum-5.jpg'
import metrum_6 from 'common/static/overview/metrum-6.jpg'
import metrum_7 from 'common/static/overview/metrum-7.jpg'
import metrum_8 from 'common/static/overview/metrum-8.jpg'
import metrum_9 from 'common/static/overview/metrum-9.jpg'
import metrum_10 from 'common/static/overview/metrum-10.jpg'
import metrum_11 from 'common/static/overview/metrum-11.jpg'
import {ProductOverView} from './ProductOverView'

export function ConnectedMetrumOverView() {
  return <ProductOverView title={'metrum'} products={metrum} />
}

export const metrum = [
  {img: <img className="overview-image" src={metrum_9} alt="metrum" />, to: 'get/metrum-9'},
  {img: <img className="overview-image" src={metrum_6} alt="metrum" />, to: 'get/metrum-6'},
  {img: <img className="overview-image" src={metrum_8} alt="metrum" />, to: 'get/metrum-8'},
  {img: <img className="overview-image" src={metrum_1} alt="metrum" />, to: 'get/metrum-1'},
  {img: <img className="overview-image" src={metrum_11} alt="metrum" />, to: 'get/metrum-11'},
  {img: <img className="overview-image" src={metrum_7} alt="metrum" />, to: 'get/metrum-7'},
  {img: <img className="overview-image" src={metrum_10} alt="metrum" />, to: 'get/metrum-10'},
  {img: <img className="overview-image" src={metrum_4} alt="metrum" />, to: 'get/metrum-4'},
  {img: <img className="overview-image" src={metrum_5} alt="metrum" />, to: 'get/metrum-5'},
]

import {Board} from 'common/threeComponents/FiberBoard'
import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {shelfMaterialModel} from '../../store/datamodels'
import {ColumnList} from 'products/analog/partLists/columnList'

export const ConnectedColumns = () => {
  const columnList = ColumnList()
  const materialID = columnList[0].material
  const materialReference = shelfMaterialModel[materialID].matReference
  const materialObject = MaterialSingleton.getMaterials()
  const material = materialObject[materialReference] || materialObject[materialReference]
  return (
    <>
      {columnList.map((column) => {
        return (
          <Board
            {...column}
            size={[column.length, column.thickness, column.depth]}
            material={Math.round(Math.random()) === 0 ? material.small_1 : material.small_2}
            rotation={[0, 0, 0.5]}
            translate={[0.5, -0.5, 0.5]}
          />
        )
      })}
    </>
  )
}

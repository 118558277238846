import {useStore} from 'products/analog/store/store'
import shallow from 'zustand/shallow'
import {backpanelMaterials, blueprint, shelfMaterialModel} from 'products/analog/store/datamodels'

export const TabelarList = () => {
  const tabelarList = useStore((store) => store.tabelars.clearTabelars(), shallow)
  const [columns, backpanels, boards, shelfWidth, shelfDepth, shelfMaterialID, backpanelMaterialID, grid, feetID] = useStore(
    (store) => [
      store.rows.clearColumns(),
      store.config.backpanels,
      store.config.rows.list,
      store.config.main.width,
      store.config.main.depth,
      store.config.main.materialID,
      store.config.backpanels.materialID,
      store.config.main.grid,
      store.config.feet.id,
    ],
    shallow
  )
  const shelfThickness = shelfMaterialModel[shelfMaterialID].thickness
  const backpanelThickness = backpanelMaterials[backpanelMaterialID].thickness
  const gridSize = (shelfWidth - shelfThickness) / grid
  const wallMounted = feetID === 'wallMounted'
  const tabelars: {key: string; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof shelfMaterialModel; type: 'tabelar'}[] = []

  tabelarList.forEach((tabelar) => {
    const yPosIndex = Math.floor(tabelar.pos.y)
    const innerYPos = tabelar.pos.y - yPosIndex
    const yPos = boards.slice(0, yPosIndex).reduce((pv, cv) => pv + cv.height + shelfThickness, 0) + (boards[yPosIndex].height + shelfThickness) * innerYPos
    const fullBackpanel = backpanels.list.find((backpanel) => backpanel.pos.y === yPosIndex && backpanel.cover === 'full')
    const isBackpanel = fullBackpanel || backpanels.list.find((backpanel) => backpanel.pos.x === tabelar.pos.x && backpanel.pos.y === Math.floor(tabelar.pos.y)) || wallMounted
    const isInset = isBackpanel ? 1 : 0
    const backpanelInset = wallMounted ? blueprint.backpanelInset.wallMounted : blueprint.backpanelInset.standard
    const adjustedDepth = shelfDepth - blueprint.frontInset - (backpanelThickness + backpanelInset) * isInset
    const zPos = (backpanelThickness + backpanelInset) * isInset
    const columnList = columns[yPosIndex].filter((i) => i > tabelar.pos.x)
    columnList.push(grid)
    const nextColumn = Math.min(...[...columns[yPosIndex].filter((col) => col > tabelar.pos.x), grid])
    const multiplier = nextColumn - tabelar.pos.x
    const width = gridSize * multiplier - shelfThickness
    const xPos = tabelar.pos.x * gridSize + shelfThickness
    tabelars.push({
      key: tabelar.pos.x + '-' + tabelar.pos.y,
      xPos: xPos,
      yPos: yPos,
      zPos: zPos,
      length: width,
      depth: adjustedDepth,
      thickness: shelfThickness,
      material: shelfMaterialID,
      type: 'tabelar',
    })
  })

  return tabelars
}

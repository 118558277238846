import {useStore} from 'products/kubatur/store/store'
import shallow from 'zustand/shallow'
import {shelfMaterials, backpanelMaterials, regalModel, shelfTypes, frontMaterials} from 'products/kubatur/store/datamodels'

export const BoardList = () => {
  const columns = useStore((store) => store.config.columns.list)
  const [height, depth, materialID, grid, boardList, doors, backpanels, shelfTyp] = useStore(
    (store) => [
      store.config.main.height,
      store.config.main.depth,
      store.config.main.materialID,
      store.config.main.grid,
      store.config.boards.list,
      store.config.doors,
      store.config.backpanels,
      store.config.main.shelfTyp,
    ],
    shallow
  )
  const thickness = shelfMaterials[materialID].thickness
  let startPos = thickness
  const totalWidth = columns.reduce((acc, col) => acc + col.width + thickness, thickness)
  const gridsize = (height - thickness - shelfTypes[shelfTyp].topInset - shelfTypes[shelfTyp].bottomInset) / grid
  const backpanelThickness = backpanelMaterials[backpanels.materialID].thickness
  const doorThickness = frontMaterials[doors.materialID].thickness
  const boards = []

  if (shelfTyp === 1) {
    boards.push({
      key: 'bottom',
      xPos: thickness,
      yPos: shelfTypes[shelfTyp].bottomInset,
      zPos: 0,
      length: totalWidth - 2 * thickness,
      depth: depth,
      thickness: thickness,
      material: materialID,
      type: 'bottom',
    })
    boards.push({
      key: 'top',
      xPos: thickness,
      yPos: height - shelfTypes[shelfTyp].topInset - thickness,
      zPos: 0,
      length: totalWidth - 2 * thickness,
      depth: depth,
      thickness: thickness,
      material: materialID,
      type: 'top',
    })
  }

  columns.forEach((el, index) => {
    startPos += el.width + thickness
    const noDoorInFront = doors.list.filter((door) => Math.floor(door.pos.x) === index).length === 0
    const zPos = backpanelThickness < 0.016 ? regalModel.backpanelInset + backpanelThickness : 0.001 + backpanelThickness
    const xPos = startPos - el.width - thickness
    const boardListExtended = [...boardList[index].filter((i) => i < grid && i > 0)]
    shelfTyp === 2 && boardListExtended.push(0, grid)
    const extraDepth = shelfTyp === 2 && noDoorInFront ? regalModel.doorDistance + doorThickness : 0
    boardListExtended.forEach((i) => {
      const adjustedDepth = i === 0 || i === grid ? depth + extraDepth : depth - zPos + extraDepth
      boards.push({
        key: index + '.' + i,
        xPos: xPos,
        yPos: shelfTypes[shelfTyp].bottomInset + i * gridsize,
        zPos: i === 0 || i === grid ? 0 : zPos,
        length: el.width,
        depth: adjustedDepth,
        thickness: thickness,
        material: materialID,
        type: 'board',
      })
    })
  })

  return boards
}

import React from 'react'
import {Price} from '../utils/price'
import {footModel, shelfMaterialModel} from '../store/datamodels'
import {useStore} from '../store/store'
import {localFactors, materialTreatments} from '../../../utils/datamodels'
import {useCommonStore} from 'common/Store/CommonStore'
import {TabelarList} from 'products/analog/partLists/tabelarList'
import {DrawerList} from 'products/analog/partLists/drawerList'
import {BackpanelList} from 'products/analog/partLists/backpanelList'
import {ColumnList} from '../partLists/columnList'
import {getMaterialName} from 'utils/helpers'

export function InfoBoxPrint() {
  const width = useStore((store) => store.config.main.width)
  const depth = useStore((store) => store.config.main.depth)
  const grid = useStore((store) => store.config.main.grid)
  const uri = useStore((store) => store.uri)
  const materialID = useStore((store) => store.config.main.materialID)
  const material = shelfMaterialModel[materialID]
  const shelfMaterialName = getMaterialName(materialID)
  const treatment = materialTreatments[useStore((store) => store.config.main.treatmentID)]
  const boards = useStore((store) => store.config.rows.list)
  const backpanels = useStore((store) => store.config.backpanels)
  const drawers = useStore((store) => store.config.drawers)
  const doors = useStore((store) => store.config.doors)
  const feet = useStore((store) => store.config.feet)
  const colModel = useStore((store) => store.config.columns.list)
  const cleanColModel = colModel
    .map((col) => {
      col.filter((item) => item < grid)
      const newCol = [...col, 0, grid]
      return newCol
    })
    .slice(0, boards.length)
  const thickness = material.thickness
  const localFactor = localFactors[useCommonStore((store) => store.localFactorID)]

  const widths = getWidths(cleanColModel)
  console.log(widths)

  const wallMounted = feet.id === 'wallMounted'
  const footHeight = !wallMounted ? feet.height : 0
  const treatmentName = material.treatment ? treatment.name : '-'
  const fullHeight = Math.round((boards.reduce((pv, cv) => pv + cv.height + thickness, 0) + thickness + footHeight) * 1000)
  const drawerFrontMaterialName = getMaterialName(drawers.materialID)
  const footRange = footModel[feet.id].range || [0, 0]
  const fussWand = !wallMounted
    ? footModel[feet.id].name + ' ' + feet.height * 1000 + ' mm (' + (feet.height + footRange[0]) * 1000 + '-' + (feet.height + footRange[1]) * 1000 + ' mm)'
    : 'wandmontiert'
  const doorMaterialName = getMaterialName(doors.materialID)
  const backpanelMaterialName = getMaterialName(backpanels.materialID)

  let totalCols = ColumnList().length
  let totalTabelars = TabelarList().length
  let totalBackpanels = BackpanelList().length
  let totalDrawers = DrawerList().length
  let totalInnerDoors = 0
  let totalOuterDoors = 0

  for (let d = 0; d < doors.list.length; d++) {
    if (doors.list[d].type === 1) totalOuterDoors++
    if (doors.list[d].type === 0) totalInnerDoors++
  }

  return (
    <div className="description">
      <div className="print_title">Analog</div>
      <div className="sub_title">
        <a href={'/get/' + uri}>ID: {uri}</a>
      </div>
      <div className="printcontainer">
        <div className="col">
          <div className="sub_title">Material</div>
          {shelfMaterialName}
          <div className="sub_title">Oberfläche</div>
          {treatmentName}
          <div className="sub_title">Abmessungen</div>
          {width * 1000} mm x {depth * 1000} mm x {fullHeight} mm
          <div className="sub_title">lichte Etagenhöhen</div>
          {boards.map((board, i) => {
            const height = Math.round(board.height * 1000)
            if (i + 1 < boards.length) {
              return <span key={i}>{height} mm, </span>
            } else {
              return <span key={i}>{height} mm</span>
            }
          })}
          <div className="sub_title">lichte Fachbreiten</div>
          {widths.map((el, i) => {
            const innerWidth = Math.round(((el * (width - thickness)) / grid - thickness) * 1000)
            if (i + 1 < widths.length) {
              return <span key={i}>{innerWidth} mm, </span>
            } else {
              return <span key={i}>{innerWidth} mm</span>
            }
          })}
        </div>
        <div className="col">
          <div className="sub_title">Regal</div>
          <div>{boards.length + 1} Regalböden</div>
          <div>{totalCols} Seiten</div>
          <div>
            {totalBackpanels} Rückwände, {backpanelMaterialName}
          </div>
          <div>{totalTabelars} Fachböden</div>
          <div className="sub_title">Ausstattung</div>
          <div>
            {totalDrawers} Schubladen, Front {drawerFrontMaterialName}
          </div>
          <div>
            {totalInnerDoors} Schiebetüren innenliegend, {doorMaterialName}
          </div>
          <div>
            {totalOuterDoors} Schiebetüren vorlaufend, {doorMaterialName}
          </div>
          <div className="sub_title">Fuss / Wandmontage</div>
          <div>{fussWand}</div>
          <div className="price_title">Preis {localFactor.name}</div>
          <div className="price">
            <Price /> €
          </div>
          <div className="left">ohne Lieferung / Montage</div>
          <div className="right">{localFactor.vat}</div>
        </div>
      </div>
    </div>
  )
}

const getWidths = (colModel: number[][]) => {
  let widths = []
  for (let i = 0; i < colModel.length; i++) {
    colModel[i].sort((a, b) => a - b)
    for (let j = colModel[i].length - 1; j > 0; j--) {
      const width = colModel[i][j] - colModel[i][j - 1]
      if (widths.indexOf(width) === -1) widths.push(width)
    }
  }
  widths.sort((a, b) => a - b)
  return widths
}

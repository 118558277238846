import './infobox.scss'
import {Link} from 'react-router-dom'
import {materialModel, materialTreatments, materialTypes} from 'utils/datamodels'
import {ReactNode} from 'react'
import {useCommonStore} from 'common/Store/CommonStore'

export function InfoBox(props: {
  title: string
  materialId: string
  treatmentId: number
  materialSelector: string
  width: number
  height: number
  depth: number | string
  vat: string
  country: string
  baseUrl: string
  price: ReactNode
}) {
  const {title, materialId, treatmentId, materialSelector, width, height, depth, vat, country, baseUrl, price} = props
  const depthText = typeof depth === 'string' ? depth : Math.round(depth * 1000)
  const setExpandedFirst = useCommonStore((state) => state.setExpandedFirst)
  const setExpandedSecond = useCommonStore((state) => state.setExpandedSecond)

  return (
    <div className="info-container">
      <div className="info-box">
        <div className="info-title">
          <Link to={'/'}>{title}</Link>
        </div>
        <div className="size-container">
          <span className="width-info">
            <span
              onClick={() => {
                setExpandedFirst('structure')
                setExpandedSecond('width')
              }}>
              {Math.round(width * 1000)}
            </span>{' '}
            x{' '}
          </span>
          <span className="depth-info">
            <span
              onClick={() => {
                setExpandedFirst('structure')
                setExpandedSecond('depth')
              }}>
              {depthText}
            </span>{' '}
            x{' '}
          </span>
          <span className="height-info">
            <span
              onClick={() => {
                setExpandedFirst('structure')
                setExpandedSecond('height')
              }}>
              {Math.round(height * 1000)}
            </span>{' '}
            mm
          </span>
        </div>
        <div className="material-container">
          <MaterialInfo materialID={materialId} treatmentID={treatmentId} materialSelector={materialSelector} />
        </div>
      </div>
      <div className="price-container">
        <div className="price-amount">{price} €</div>
        <div className="vat-info">
          <Link to={baseUrl + '/locale'}>{vat}</Link>
        </div>
        <Link to={baseUrl + '/locale'}>{country}</Link>
      </div>
    </div>
  )
}

function MaterialInfo(props: {materialID: string; treatmentID: number; materialSelector: string}) {
  const {materialID, treatmentID, materialSelector} = props
  const setExpandedFirst = useCommonStore((state) => state.setExpandedFirst)
  const setExpandedSecond = useCommonStore((state) => state.setExpandedSecond)
  const material = materialModel[materialID as keyof typeof materialModel]
  const {name, type} = material
  var hasTreatment = false

  if (material.hasOwnProperty('treatment')) {
    //@ts-ignore
    hasTreatment = material.treatment || false
  }

  const treatment = materialTreatments.find((treatment) => treatment.id === treatmentID)?.name

  const speciesId = materialSelector === 'single' ? 'material' : 'species'
  const typeId = materialSelector === 'single' ? 'material' : 'type'

  return (
    <>
      {(type === 0 || type === 2) && (
        <>
          <span className="type-info">
            <span
              className="species-info"
              onClick={() => {
                setExpandedFirst('structure')
                setExpandedSecond(typeId)
              }}>
              {materialTypes[type].name + ' '}
            </span>
          </span>
          <span
            className="species-info"
            onClick={() => {
              setExpandedFirst('structure')
              setExpandedSecond(speciesId)
            }}>
            {name}
          </span>
        </>
      )}
      {type !== 0 && type !== 2 && (
        <>
          <span className="species-info">
            <span
              onClick={() => {
                setExpandedFirst('structure')
                setExpandedSecond(speciesId)
              }}>
              {name + ' '}
            </span>
          </span>
          <span className="type-info">
            <span
              onClick={() => {
                setExpandedFirst('structure')
                setExpandedSecond(typeId)
              }}>
              {materialTypes[type].name}
            </span>
          </span>
        </>
      )}
      {hasTreatment && (
        <span className="treatment-info">
          ,{' '}
          <span
            onClick={() => {
              setExpandedFirst('structure')
              setExpandedSecond('treatment')
            }}>
            {treatment}
          </span>
        </span>
      )}
    </>
  )
}

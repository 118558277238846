import {materialModel, w, localFactors} from './../../../utils/datamodels'
import {BoardList} from '../partLists/boardList'
import {ColumnList} from '../partLists/columnList'
import {drawerPrice, price_CNC, price_Material, price_Salary} from 'utils/priceHelper'
import {useCommonStore} from 'common/Store/CommonStore'
import {BackpanelList} from '../partLists/backpanelList'
import {DrawerList} from '../partLists/drawerList'
import {FlapList} from '../partLists/flapList'
import {FootList} from '../partLists/footList'
import {footModel} from '../store/datamodels'

export const DigitalPrice = () => {
  const localFactor = localFactors[useCommonStore((state) => state.localFactorID)]
  const treatmentPrice = 0
  const partList = getPartList()
  let priceMaterial = 0
  let priceWork = 0
  let priceCNC = 0
  let priceFeet = 0
  let priceDrawers = 0
  partList.forEach((part) => {
    priceMaterial += getMaterialPrice(part)
    priceWork += getWorkPrice(part, treatmentPrice)
    priceCNC += getCNCPrice(part)
  })
  const footList = FootList()
  footList.forEach((foot) => {
    priceFeet += footModel[foot.type].price
  })

  DrawerList().forEach((drawer) => {
    priceDrawers += drawerPrice(drawer, treatmentPrice).totalPrice
  })

  const price = Math.round(localFactor.factor * 10 * Math.round((w.Faktor * (priceMaterial + priceWork + priceCNC + priceFeet + priceDrawers)) / 10))
  return <>{price}</>
}

const getPartList = () => {
  const flapPartList = FlapList()
    .map((flap) => Object.values(flap.parts).map((el) => el))
    .flat() as any[]
  const partList = [...BoardList(), ...ColumnList(), ...BackpanelList(), ...flapPartList]
  return partList
}

const getMaterialPrice = (part: {material: keyof typeof materialModel; length: number; type: any; depth: any}) => {
  let price = 0
  switch (part.type) {
    case 'bottom':
    case 'board':
    case 'top':
      price = price_Material({length: part.length, width: part.depth, materialID: part.material, rest: [w.Stahlprofil * 2 * part.length]})
      break
    case 'flapFront':
      const nrFlapHinges = 2 + (part.length > 0.8 ? Math.round((part.length - 0.8) / 0.4) : 0)
      const nrFlapHolders = part.depth < 0.8 ? 1 : 2
      price = price_Material({length: part.length, width: part.depth, materialID: part.material, rest: [nrFlapHinges * w.Klappenband, nrFlapHolders * w.SeilzugKlappenHalter]})
      break
    default:
      price = price_Material({length: part.length, width: part.depth, materialID: part.material})
      break
  }
  return price
}

const getWorkPrice = (part: {length: number; depth: number; material: keyof typeof materialModel; type: string}, treatmentPrice: number) => {
  let price = 0
  const material = materialModel[part.material]
  switch (part.type) {
    case 'flapFront':
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment, rest: [w.ZeitKlappe]})
      break
    default:
      price = price_Salary({length: part.length, width: part.depth, treatmentPrice: treatmentPrice, hasTreatment: material.treatment})
      break
  }
  return price
}

const getCNCPrice = (part: {type: any; length: number; depth: any}) => {
  let price = 0
  switch (part.type) {
    case 'top':
    case 'bottom':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, format: true, bevel: true})
      break
    case 'board':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 2, nrToolChanges: 3, nrGrooves: 2, format: true, bevel: true})
      break
    case 'column':
    case 'middleColumn':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrDrills: 20 + 4 * Math.floor((part.length - 0.25) / 64), nrGrooves: 5, format: true, bevel: true})
      break
    case 'backpanel':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrDrills: 6, format: true, bevel: true})
      break
    case 'flapFront':
      const nrFlapHinges = 2 + (part.length > 0.8 ? Math.round((part.length - 0.8) / 0.4) : 0)
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, format: true, bevel: true, rest: [nrFlapHinges * w.CNCZeitKlappenband]})
      break
    case 'flapShelf':
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 1, format: true, bevel: true})
      break
    default:
      console.log('no CNCprice for part type: ' + part.type)
      price = price_CNC({length: part.length, width: part.depth, nrSetups: 1, nrToolChanges: 3, nrDrills: 12, format: true, bevel: true})
      break
  }
  return price
}

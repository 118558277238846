import {useStore} from 'products/dasregal/store/store'
import shallow from 'zustand/shallow'
import {regalModel, shelfMaterials, frontMaterials} from 'products/dasregal/store/datamodels'
import {accHelper} from 'utils/helpers'

export const DoorList = () => {
  const doorList = useStore((store) => store.config.doors.list)
  const [flapList, drawerList, depth, shelfHeight, columns, grid, shelfMaterial, doorMaterial] = useStore(
    (store) => [
      store.config.flaps.list,
      store.config.drawers.list,
      store.config.main.depth,
      store.config.main.height,
      store.config.columns.list,
      store.config.main.grid,
      store.config.main.materialID,
      store.config.doors.materialID,
    ],
    shallow
  )
  const shelfThickness = shelfMaterials[shelfMaterial].thickness
  const doorThickness = frontMaterials[doorMaterial].thickness
  const gridSize = (shelfHeight - shelfThickness) / grid
  const shadowJoint = regalModel.shadowJoint
  const doors: {
    key: string
    xPos: number
    yPos: number
    zPos: number
    length: number
    depth: number
    thickness: number
    material: keyof typeof frontMaterials
    side: 'left' | 'right'
    type: string
  }[] = []
  doorList.forEach((door, j) => {
    if (accHelper.is_in_column(door.pos.x, columns.length)) {
      const totalWidth = accHelper.get_acc_width({x: Math.floor(door.pos.x), y: door.pos.y}, {x: 1, y: 0}, flapList, doorList, drawerList)
      const totalWidthBelow = accHelper.get_acc_width_below({x: Math.floor(door.pos.x), y: door.pos.y}, {x: 1, y: 0}, flapList, doorList, drawerList)
      const accHeightBesideDoor = accHelper.get_acc_beside(door.pos, door.size, doorList, drawerList).height
      const extraWidth = accHeightBesideDoor === door.size.y ? 1 : 0
      const isOffset = totalWidth === 1 && totalWidthBelow === 1
      const yOffset = isOffset ? shelfThickness + shadowJoint : 0
      const height = door.size.y * gridSize - shelfThickness - 2 * shadowJoint + yOffset
      const width = (columns[Math.floor(door.pos.x)].width + shelfThickness) * door.size.x - shelfThickness - 2 * shadowJoint + (extraWidth * (shelfThickness + shadowJoint)) / 2
      const xPosOffset = door.pos.x - Math.floor(door.pos.x) > 0 && extraWidth === 1 ? (shelfThickness + shadowJoint) / 2 : 0
      const xPos =
        columns
          .slice(0, Math.floor(door.pos.x))
          .reduce((pv, cv) => pv + cv.width + shelfThickness, shelfThickness + shadowJoint + (columns[Math.floor(door.pos.x)].width + shelfThickness) * (door.pos.x - Math.floor(door.pos.x))) -
        xPosOffset
      const yPos = door.pos.y * gridSize + shelfThickness + shadowJoint - yOffset
      doors.push({
        key: xPos + '-' + yPos,
        xPos: xPos,
        yPos: yPos,
        zPos: depth - doorThickness - 0.001,
        length: height,
        depth: width,
        thickness: doorThickness,
        material: doorMaterial,
        side: door.side,
        type: 'door',
      })
    }
  })

  return doors
}

import {InfoBoxPrint} from './infoboxPrint'
import {baseUrl} from '../store/datamodels'
import {Caption, Page, PartList, PrintView} from 'components/print/PrintView'
import {FiberPrintScene} from 'common/threeComponents/FiberScene/FiberScene'
import {useStore} from '../store/store'
import {ConnectedAnalog} from '../three_components/Analog'
import {DrawerList} from '../partLists/drawerList'
import {BoardList} from '../partLists/boardList'
import {ColumnList} from '../partLists/columnList'
import {TabelarList} from '../partLists/tabelarList'
import {BackpanelList} from '../partLists/backpanelList'
import {DoorList} from '../partLists/doorList'
import {getReducedPartList} from 'utils/helpers'
import {useCommonStore} from 'common/Store/CommonStore'

export default function ConnectedPrintView() {
  const footHeight = useStore((store) => store.config.feet.height)

  const drawerPartList = DrawerList()
    .map((drawer) => Object.values(drawer.parts).map((el) => el))
    .flat() as any[]
  const doorPartList = DoorList().map((door) => {
    return {...door.parts.front, length: door.parts.front.physicalLength}
  }) as any[]
  const partList = [...BoardList(), ...ColumnList(), ...TabelarList(), ...BackpanelList(), ...doorPartList, ...drawerPartList]

  const reducedPartList = getReducedPartList(partList)
  const extended = useCommonStore((state) => state.extended)

  return (
    <PrintView reference="analog" baseUrl={baseUrl}>
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'front'}>
          <ConnectedAnalog />
        </FiberPrintScene>
        <Caption>{'Ansicht vorne'}</Caption>
        <InfoBoxPrint />
      </>
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'front'}>
          <ConnectedAnalog doorsVisible={false} />
        </FiberPrintScene>
        <Caption>{'Ansicht vorne ohne Schiebetüren'}</Caption>
        <InfoBoxPrint />
      </>
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'side'}>
          <ConnectedAnalog />
        </FiberPrintScene>
        <Caption>{'Ansicht seitlich'}</Caption>
        <InfoBoxPrint />
      </>
      <>
        <FiberPrintScene footHeight={footHeight} perspective={'back'}>
          <ConnectedAnalog />
        </FiberPrintScene>
        <Caption>{'Ansicht hinten'}</Caption>
        <InfoBoxPrint />
      </>
      {extended && (
        <Page orientation={'landscape'} logo={false}>
          <PartList partList={reducedPartList} />
        </Page>
      )}
    </PrintView>
  )
}

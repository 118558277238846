import {Board, IVector} from 'common/threeComponents/FiberBoard'
import {MaterialSingleton} from '../../../../common/threeComponents/Materials'
import {shelfMaterials} from 'products/tektur/store/datamodels'
import {TabelarList} from 'products/tektur/partLists/tabelarList'

export const ConnectedTabelars = (): any => {
  const tabelarList = TabelarList()
  if (tabelarList.length === 0) return null
  const materialID = tabelarList[0].material
  const materialReference = shelfMaterials[materialID].matReference
  const materialObject = MaterialSingleton.getMaterials()
  const material = materialObject[materialReference]?.small_2 || materialObject[materialReference]
  return (
    <>
      {tabelarList.map((tabelar) => {
        const size = [tabelar.length, tabelar.thickness, tabelar.depth] as IVector
        return <Board {...tabelar} size={size} material={material} />
      })}
    </>
  )
}

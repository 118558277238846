import {useStore} from 'products/tektur/store/store'
import shallow from 'zustand/shallow'
import {regalModel, shelfMaterials, slidingDoorMaterials} from 'products/tektur/store/datamodels'

export const SlidingDoorList = () => {
  const slidingDoorList = useStore((store) => store.slidingDoors.clearSlidingDoors(), shallow)
  const [depth, shelfWidth, boards, grid, shelfMaterialID, slidingDoorMaterial, shelfType] = useStore(
    (store) => [
      store.config.main.depth,
      store.config.main.width,
      store.config.rows.list,
      store.config.main.grid,
      store.config.main.materialID,
      store.config.slidingDoors.materialID,
      store.config.main.shelfTyp,
    ],
    shallow
  )
  const thickness = shelfMaterials[shelfMaterialID].thickness
  const slidingDoorThickness = slidingDoorMaterials[slidingDoorMaterial].thickness
  const shadowJoint = regalModel.shadowJoint
  const gridSize = (shelfWidth - 2 * thickness - shadowJoint) / grid
  const width = gridSize - shadowJoint
  const physicalLength = (shelfWidth - 2 * regalModel.sideInset - thickness) / grid + shadowJoint
  const slidingDoors: {
    key: string
    xPos: number
    yPos: number
    zPos: number
    length: number
    physicalLength: number
    physicalDepth: number
    depth: number
    material: keyof typeof slidingDoorMaterials
    thickness: number
    type: string
  }[] = []

  shelfType !== 2 &&
    slidingDoorList.forEach((slide, j) => {
      const height = boards[slide.pos.y].height - 2 * shadowJoint
      const yPos = boards.slice(0, slide.pos.y).reduce((pv, cv) => pv + cv.height + thickness, thickness + shadowJoint)
      const xPos = thickness + slide.pos.x * gridSize + shadowJoint
      slidingDoors.push({
        key: slide.pos.x + '-' + slide.pos.y,
        xPos: xPos,
        yPos: yPos,
        zPos: depth - 0.001 - slidingDoorThickness,
        length: width,
        physicalLength: physicalLength,
        depth: height,
        physicalDepth: height + 0.009,
        thickness: slidingDoorThickness,
        material: slidingDoorMaterial,
        type: 'slidingDoor',
      })
    })

  return slidingDoors
}

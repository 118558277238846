//@ts-nocheck
import produce from 'immer'
import {mountStoreDevtool} from 'simple-zustand-devtools'
import create from 'zustand'
import {IStore} from '..'
import {backpanelMaterials, blueprint, doorMaterials, drawerMaterials, shelfMaterialModel} from './datamodels'
import {getMaxGrid, getMinGrid} from '../../../utils/helpers'
import {createBackpanelSlice} from './slices/backpanelSlice'
import {createDoorSlice} from './slices/doorSlice'
import {createDrawerSlice} from './slices/drawerSlice'
import {createFeetSlice} from './slices/feetSlice'
import {createRowSlice} from './slices/rowSlice'
import {createTablearSlice} from './slices/tabelarSlice'
import {initialView, IView} from './initialView'
import {API_DOMAIN, materialModel} from 'utils/datamodels'
import {IAnalogConfig, initialConfig} from './initialConfig'

export type ISetStore<T> = (state: T) => void
export type ISetProduce<T> = (fn: ISetStore<T>) => void

export const useStore = create<IStore>((set, get) => {
  const config: IAnalogConfig = initialConfig // set backup config
  const view: IView = initialView // set default view
  //store Helper Functions
  const setState = (chunk: object) => set(produce(() => chunk)) //even easier
  const setProduce: ISetProduce<IStore> = (fn) => set(produce(fn))

  // Public Functions
  const setBoardsEdit = (edit: boolean) => {
    setProduce((store) => {
      store.view.boards.edit = edit
    })
  }

  const setDepth = (newDepth: number) => {
    setProduce((store) => {
      store.config.main.depth = newDepth
    })
  }
  const setGrid = (newGrid: number) => {
    setProduce((state) => {
      // state.doors.changeGrid(newGrid) //ob das geht 0o??
      state.config.rows.list.forEach((_, y) => {
        const cols = state.config.columns.list[y]
        if (Math.max(...cols) + 2 < newGrid) {
          for (let i = Math.max(...cols, 0) + 1; i < newGrid; i++) {
            i % 2 === 0 && state.config.columns.list[y].push(i)
          }
        }
      })
      state.config.main.grid = newGrid
    })
  }

  const setMaterial = (material: IAnalogConfig['materialID']) => {
    setProduce((state) => {
      state.config.main.materialID = material
    })
  }

  const setWidth = (newWidth: number) => {
    const minGrid = getMinGrid(newWidth, blueprint.gridMax)
    const maxGrid = getMaxGrid(newWidth, blueprint.gridMin)
    const width = get().config.main.width
    const grid = get().config.main.grid
    const shelfThickness = shelfMaterialModel[get().config.main.materialID].thickness
    const colSize = (width - shelfThickness) / grid
    const preferredGrid = Math.round(newWidth / colSize)
    const alternativeGrid = maxGrid - preferredGrid > preferredGrid - minGrid ? minGrid : maxGrid
    const newGrid = maxGrid >= preferredGrid && preferredGrid >= minGrid ? preferredGrid : alternativeGrid //#TODO I think it should be >= -> also in UI Grid selector
    if (newGrid !== grid) setGrid(newGrid)
    setProduce((state) => {
      state.config.main.width = newWidth
    })
  }

  const setMaterialSpecies = (materialID: string) => {
    //const newShelfMaterialReference = shelfMaterialModel[nr].matReference //why are we doing this? future safeness?
    const newShelfMaterial = shelfMaterialModel[materialID]
    const newWidth = get().config.main.width > newShelfMaterial.maxWidth ? newShelfMaterial.maxWidth : get().config.main.width
    if (newWidth !== get().config.main.width) setWidth(newWidth)
    setProduce((state) => {
      const tmpDoorMaterial = doorMaterials[state.config.doors.materialID]
      const tmpDrawerMaterial = drawerMaterials[state.config.drawers.materialID]
      const tmpBackpanelMaterial = backpanelMaterials[state.config.backpanels.materialID]
      state.config.main.materialID = materialID
      if (tmpDoorMaterial.shelfReference.length > 0 && !tmpDoorMaterial.shelfReference.includes(materialID as never)) state.config.doors.materialID = 'h2SWBC'
      if (tmpDrawerMaterial.shelfReference.length > 0 && !tmpDrawerMaterial.shelfReference.includes(materialID as never)) state.config.drawers.materialID = 'h2SWBC'
      if (tmpBackpanelMaterial.shelfReference.length > 0 && !tmpBackpanelMaterial.shelfReference.includes(materialID as never)) state.config.backpanels.materialID = 'mdf8W'
    })
  }

  const setMaterialType = (type: number) => {
    const fallback = shelfMaterialModel[shelfMaterialModel[get().config.main.materialID].fallback]
    const newWidth = get().config.main.width > fallback.maxWidth ? fallback.maxWidth : get().config.main.width
    if (newWidth !== get().config.main.width) setWidth(newWidth)
    const oldType = materialModel[get().config.main.materialID].type
    setProduce((state) => {
      if (type !== oldType) state.config.main.materialID = fallback.id
    })
  }
  const setMaterialTreatment = (treatmentID: number) => {
    setProduce((state) => {
      state.config.main.treatmentID = treatmentID
    })
  }

  const getClearConfig = () => {
    const backpanels = {...get().config.backpanels, list: get().backpanels.clearBackpanels()}
    const columns = {...get().config.columns, list: get().rows.clearColumns()}
    const drawers = {...get().config.drawers, list: get().drawers.clearDrawers()}
    const doors = {...get().config.doors, list: get().doors.clearDoors()}
    const tabelars = {...get().config.tabelars, list: get().tabelars.clearTabelars()}
    return {...get().config, columns: columns, backpanels: backpanels, drawers: drawers, doors: doors, tabelars: tabelars}
  }

  const _updateConfig = async (uri: string) => {
    const baseUrl = `${API_DOMAIN}/product`
    const jConfig = JSON.stringify(get().config)
    const body = JSON.stringify(
      {
        reference: 'analog',
        configuration: jConfig,
      },
      null,
      2
    )
    console.log('Request body: ')
    console.log(body)
    const requestOptions = {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: body,
    }
    await fetch(baseUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((err) => console.log(err))
  }

  return {
    config,
    view,
    setProduce: setProduce,
    setState: setState,
    setBoardsEdit: setBoardsEdit,
    setDepth: setDepth,
    setGrid: setGrid,
    setMaterial: setMaterial,
    setWidth: setWidth,
    setMaterialSpecies: setMaterialSpecies,
    setMaterialType: setMaterialType,
    setMaterialTreatment: setMaterialTreatment,
    clearConfig: getClearConfig,
    ...createRowSlice(setProduce, get),
    ...createDoorSlice(setProduce, get),
    ...createBackpanelSlice(setProduce, get),
    ...createDrawerSlice(setProduce, get),
    ...createFeetSlice(setProduce),
    ...createTablearSlice(setProduce, get),
    uri: '',
    savedConfig: '',
    updateConfig: _updateConfig,
  }
})

if (process.env.NODE_ENV === 'development') {
  //@ts-ignore
  mountStoreDevtool('AnalogStore', useStore)
}

import {useStore} from 'products/tektur/store/store'
import shallow from 'zustand/shallow'
import {regalModel, flapMaterials, shelfMaterials} from 'products/tektur/store/datamodels'
import {materialModel} from 'utils/datamodels'

type FlapShelf = {
  length: number
  depth: number
  thickness: number
  material: keyof typeof flapMaterials
  type: string
}

type Front = {
  length: number
  depth: number
  thickness: number
  material: keyof typeof flapMaterials
  type: string
}

type FlapParts = {
  flapFront: Front
  flapShelf?: FlapShelf
}

export const FlapList = () => {
  const flapList = useStore((store) => store.flaps.clearFlaps(), shallow)
  const [backpanels, depth, shelfWidth, boards, grid, shelfMaterialID, flapMaterial, shelfType] = useStore(
    (store) => [
      store.config.backpanels,
      store.config.main.depth,
      store.config.main.width,
      store.config.rows.list,
      store.config.main.grid,
      store.config.main.materialID,
      store.config.flaps.materialID,
      store.config.main.shelfTyp,
      shallow,
    ],
    shallow
  )
  const thickness = shelfMaterials[shelfMaterialID].thickness
  const gridSize = (shelfWidth - thickness) / grid
  const shadowJoint = regalModel.shadowJoint
  const backpanelThickness = materialModel[backpanels.materialID].thickness
  const flaps: {key: string; xPos: number; yPos: number; zPos: number; width: number; height: number; depth: number; flapShelf: boolean; shadowJoint: number; parts: FlapParts; type: string}[] = []

  flapList.forEach((flap, j) => {
    const height = boards[flap.pos.y].height - 2 * shadowJoint
    const width = gridSize - 2 * shadowJoint - thickness
    const yPos = boards.slice(0, flap.pos.y).reduce((pv, cv) => pv + cv.height + thickness, thickness + shadowJoint)
    const xPos = thickness + flap.pos.x * gridSize + shadowJoint
    const fullBackpanel =
      backpanels.list.find((obj) => {
        return obj.pos.y === flap.pos.y
      })?.cover === 'full'
    const isBackpanel =
      backpanels.list.find((obj) => {
        return obj.pos.x === flap.pos.x && obj.pos.y === flap.pos.y
      }) || fullBackpanel
    const backpanelInset = isBackpanel ? (backpanelThickness < 0.016 ? regalModel.backpanelInset + backpanelThickness : 0.001 + backpanelThickness) : 0
    const adjustedDepth = depth - 0.001 - backpanelInset
    const flapParts = getFlapParts({width: width, height: height, depth: adjustedDepth, shadowJoint: shadowJoint, flapshelf: true, flapMaterial: flapMaterial, flapShelfMaterial: flapMaterial})
    shelfType === 2 &&
      flaps.push({
        key: flap.pos.x + '-' + flap.pos.y,
        xPos: xPos,
        yPos: yPos,
        zPos: depth - 0.001,
        width: width,
        height: height,
        depth: adjustedDepth,
        flapShelf: true,
        shadowJoint: shadowJoint,
        parts: flapParts,
        type: 'flap',
      })
  })

  return flaps
}

const getFlapParts = (params: {
  width: number
  height: number
  depth: number
  shadowJoint: number
  flapshelf: boolean
  flapMaterial: keyof typeof flapMaterials
  flapShelfMaterial: keyof typeof flapMaterials
}) => {
  const {width, height, depth, shadowJoint, flapMaterial, flapShelfMaterial} = params
  const thickness = flapMaterials[flapMaterial].thickness
  const flapFront = {length: width, depth: height, thickness: thickness, material: flapMaterial, type: 'flapFront'}
  const flapShelf = {length: width + 2 * shadowJoint, depth: depth - thickness - 0.02, thickness: thickness, material: flapShelfMaterial, type: 'flapShelf'}
  return {flapFront: flapFront, flapShelf: flapShelf}
}

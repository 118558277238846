import {useStore} from 'products/metrum/store/store'
import shallow from 'zustand/shallow'
import {shelfMaterials} from 'products/metrum/store/datamodels'

export const BaseList = () => {
  const columns = useStore((store) => store.config.columns.list)
  const [depth, materialID] = useStore((store) => [store.config.main.depth, store.config.main.materialID], shallow)
  const thickness = shelfMaterials[materialID].thickness
  let xPos = thickness / 2 + 0.0005
  const baseList: {key: number; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof shelfMaterials; type: string}[] = []

  columns.forEach((el, index) => {
    const zPos = 0.006
    const adjustedDepth = depth - 0.006 - 0.028
    baseList.push({key: index, xPos: xPos, yPos: -thickness, zPos: zPos, length: el.width + thickness - 0.001, depth: adjustedDepth, thickness: thickness, material: materialID, type: 'base'})
    xPos += el.width + thickness
  })

  return baseList
}

import {useStore} from 'products/digital/store/store'
import shallow from 'zustand/shallow'
import {regalModel, shelfMaterials, backpanelMaterials} from 'products/digital/store/datamodels'

export const ColumnList = () => {
  const columnList = useStore((store) => store.columns.clearColumns(), shallow)
  const [width, depth, materialID, grid, boards, backpanels] = useStore(
    (store) => [store.config.main.width, store.config.main.depth, store.config.main.materialID, store.config.main.grid, store.config.rows.list, store.config.backpanels.list],
    shallow
  )
  const thickness = shelfMaterials[materialID].thickness
  let startPos = thickness
  const gridsize = (width - thickness) / grid
  const backpanelThickness = backpanelMaterials[useStore((store) => store.config.backpanels.materialID)].thickness
  const columns: {key: string; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof shelfMaterials; type: string}[] = []

  boards.forEach((el, index) => {
    const fullBackpanel =
      backpanels.find((obj) => {
        return obj.pos.y === index
      })?.cover === 'full'
    const zPos = fullBackpanel ? (backpanelThickness < 0.016 ? regalModel.backpanelInset + backpanelThickness : 0.001 + backpanelThickness) : 0
    const visibleColumns = columnList[index]
    columns.push({key: index + '.' + 0, xPos: 0, yPos: startPos, zPos: 0, length: el.height, depth: depth, thickness: thickness, material: materialID, type: 'column'})
    columns.push({key: index + '.' + grid, xPos: width - thickness, yPos: startPos, zPos: 0, length: el.height, depth: depth, thickness: thickness, material: materialID, type: 'column'})
    visibleColumns.forEach((i) => {
      const adjustedDepth = depth - zPos
      columns.push({key: index + '.' + i, xPos: i * gridsize, yPos: startPos, zPos: zPos, length: el.height, depth: adjustedDepth, thickness: thickness, material: materialID, type: 'middleColumn'})
    })
    startPos += el.height + thickness
  })
  return columns
}

import './overview.scss'
import analog_1 from 'common/static/overview/analog-1.jpg'
import analog_2 from 'common/static/overview/analog-2.jpg'
import analog_3 from 'common/static/overview/analog-3.jpg'
import analog_4 from 'common/static/overview/analog-4.jpg'
import analog_5 from 'common/static/overview/analog-5.jpg'
import analog_6 from 'common/static/overview/analog-6.jpg'
import analog_7 from 'common/static/overview/analog-7.jpg'
import analog_8 from 'common/static/overview/analog-8.jpg'
import analog_9 from 'common/static/overview/analog-9.jpg'
import analog_10 from 'common/static/overview/analog-10.jpg'
import analog_11 from 'common/static/overview/analog-11.jpg'
import analog_12 from 'common/static/overview/analog-12.jpg'
import analog_13 from 'common/static/overview/analog-13.jpg'
import analog_14 from 'common/static/overview/analog-14.jpg'
import analog_15 from 'common/static/overview/analog-15.jpg'
import analog_16 from 'common/static/overview/analog-16.jpg'
import analog_17 from 'common/static/overview/analog-17.jpg'
import analog_18 from 'common/static/overview/analog-18.jpg'
import analog_19 from 'common/static/overview/analog-19.jpg'
import analog_20 from 'common/static/overview/analog-20.jpg'
import analog_21 from 'common/static/overview/analog-21.jpg'
import analog_22 from 'common/static/overview/analog-22.jpg'
import analog_23 from 'common/static/overview/analog-23.jpg'
import analog_24 from 'common/static/overview/analog-24.jpg'
import analog_25 from 'common/static/overview/analog-25.jpg'
import analog_26 from 'common/static/overview/analog-26.jpg'
import analog_27 from 'common/static/overview/analog-27.jpg'
import analog_28 from 'common/static/overview/analog-28.jpg'
import analog_29 from 'common/static/overview/analog-29.jpg'
import {ProductOverView} from './ProductOverView'

export function ConnectedAnalogOverView() {
  return <ProductOverView title={'analog'} products={analog} />
}

export const analog = [
  {img: <img className="overview-image" src={analog_25} alt="Analog" />, to: 'get/analog-25'},
  {img: <img className="overview-image" src={analog_5} alt="Analog" />, to: 'get/analog-5'},
  {img: <img className="overview-image" src={analog_27} alt="Analog" />, to: 'get/analog-27'},
  {img: <img className="overview-image" src={analog_15} alt="Analog" />, to: 'get/analog-15'},
  {img: <img className="overview-image" src={analog_29} alt="Analog" />, to: 'get/analog-29'},
  {img: <img className="overview-image" src={analog_22} alt="Analog" />, to: 'get/analog-22'},
  {img: <img className="overview-image" src={analog_14} alt="Analog" />, to: 'get/analog-14'},
  {img: <img className="overview-image" src={analog_26} alt="Analog" />, to: 'get/analog-26'},
  {img: <img className="overview-image" src={analog_28} alt="Analog" />, to: 'get/analog-28'},
  {img: <img className="overview-image" src={analog_20} alt="Analog" />, to: 'get/analog-20'},
  {img: <img className="overview-image" src={analog_16} alt="Analog" />, to: 'get/analog-16'},
  {img: <img className="overview-image" src={analog_6} alt="Analog" />, to: 'get/analog-6'},
]

import {useStore} from 'products/dasregal/store/store'
import shallow from 'zustand/shallow'
import {regalModel, shelfMaterials, backpanelMaterials} from 'products/dasregal/store/datamodels'
import {accHelper} from 'utils/helpers'

export const TabelarList = () => {
  const tabelarList = useStore((store) => store.config.tabelars.list)
  const [depth, shelfHeight, columns, boards, subColumns, grid, backpanels, feetID, doors, drawers, flaps, materialID] = useStore(
    (store) => [
      store.config.main.depth,
      store.config.main.height,
      store.config.columns.list,
      store.config.boards.list,
      store.config.subColumns.list,
      store.config.main.grid,
      store.config.backpanels.list,
      store.config.feet.id,
      store.config.doors.list,
      store.config.drawers.list,
      store.config.flaps.list,
      store.config.main.materialID,
    ],
    shallow
  )
  const thickness = shelfMaterials[materialID].thickness
  const maxCols = grid * (Math.round((shelfHeight - thickness) / (grid * 0.065)) + 1)
  const maxColGrid = (shelfHeight - thickness) / maxCols
  const backpanelThickness = backpanelMaterials[useStore((state) => state.config.backpanels.materialID)].thickness

  const tabelars: {key: string; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof shelfMaterials; type: string}[] = []
  tabelarList.forEach((colList, i) => {
    const xIndex = Math.floor(colList.pos.x)
    if (xIndex < columns.length) {
      const innerXPos = colList.pos.x - xIndex
      const xPos = columns.slice(0, xIndex).reduce((pv, cv) => pv + cv.width + thickness, thickness + (columns[xIndex].width + thickness) * innerXPos)
      colList.pos.y.forEach((yIndex, j) => {
        if (yIndex < maxCols) {
          const posIndex = Math.floor(yIndex / (maxCols / grid))
          const subColumnList = accHelper.get_SubColumn_List({x: xIndex, y: posIndex}, columns, boards, subColumns)
          const nextSubColumn = Math.min(
            ...subColumnList.filter((obj) => {
              return obj > innerXPos
            })
          )
          const multiplier = nextSubColumn - innerXPos
          const yPos = yIndex * maxColGrid
          const isBackpanel = accHelper.is_Backpanel_at_pos({x: colList.pos.x, y: posIndex}, backpanels, boards)
          const wallMounted = feetID === 'wallMounted'
          const zPos = wallMounted
            ? regalModel.backpanelWallMountedInset + backpanelThickness
            : isBackpanel
            ? backpanelThickness < 0.016
              ? regalModel.backpanelInset + backpanelThickness
              : 0.001 + backpanelThickness
            : 0
          const width = (columns[xIndex].width + thickness) * multiplier - thickness
          const position = {x: colList.pos.x, y: yIndex / (maxCols / grid)}
          const size = {x: multiplier, y: 0}
          const isInset =
            accHelper.acc_is_in_front(position, size, doors, flaps) ||
            (accHelper.get_acc_width(position, size, doors, drawers) >= multiplier && accHelper.get_acc_width_below(position, size, doors, drawers) >= multiplier)
          const adjustedDepth = depth - zPos - (isInset ? regalModel.frontInset : 0)
          const isSubColumn = subColumnList.includes(innerXPos)
          isSubColumn && tabelars.push({key: i + '.' + yIndex, xPos: xPos, yPos: yPos, zPos: zPos, length: width, depth: adjustedDepth, thickness: thickness, material: materialID, type: 'tabelar'})
        }
      })
    }
  })

  return tabelars
}

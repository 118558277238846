import './overview.scss'
import digital_1 from 'common/static/overview/digital-1.jpg'
import digital_2 from 'common/static/overview/digital-2.jpg'
import digital_3 from 'common/static/overview/digital-3.jpg'
import digital_4 from 'common/static/overview/digital-4.jpg'
import digital_5 from 'common/static/overview/digital-5.jpg'
import digital_6 from 'common/static/overview/digital-6.jpg'
import digital_7 from 'common/static/overview/digital-7.jpg'
import digital_8 from 'common/static/overview/digital-8.jpg'
import digital_9 from 'common/static/overview/digital-9.jpg'
import digital_10 from 'common/static/overview/digital-10.jpg'
import digital_11 from 'common/static/overview/digital-11.jpg'
import {ProductOverView} from './ProductOverView'

export function ConnectedDigitalOverView() {
  return <ProductOverView title={'digital'} products={digital} />
}

export const digital = [
  {img: <img className="overview-image" src={digital_1} alt="digital" />, to: 'get/digital-1'},
  {img: <img className="overview-image" src={digital_8} alt="digital" />, to: 'get/digital-8'},
  {img: <img className="overview-image" src={digital_3} alt="digital" />, to: 'get/digital-3'},
  {img: <img className="overview-image" src={digital_6} alt="digital" />, to: 'get/digital-6'},
  {img: <img className="overview-image" src={digital_9} alt="digital" />, to: 'get/digital-9'},
  {img: <img className="overview-image" src={digital_4} alt="digital" />, to: 'get/digital-4'},
  {img: <img className="overview-image" src={digital_10} alt="digital" />, to: 'get/digital-10'},
  {img: <img className="overview-image" src={digital_5} alt="digital" />, to: 'get/digital-5'},
  {img: <img className="overview-image" src={digital_11} alt="digital" />, to: 'get/digital-11'},
]

import {shelfMaterials, shelfTypes} from '../../store/datamodels'
import {useStore} from 'products/kubatur/store/store'
import {SlidingDoorRailBottom, SlidingDoorRailTop} from './SlidingDoorRailsFC'

export const ConnectedSlidingDoorRails = (): any => {
  const shelfMaterialID = useStore((state) => state.config.main.materialID)
  const shelfThickness = shelfMaterials[shelfMaterialID].thickness
  const shelfTyp = useStore((state) => state.config.main.shelfTyp)
  const columns = useStore((state) => state.config.columns.list)
  const depth = useStore((state) => state.config.main.depth)
  const height = useStore((state) => state.config.main.height)
  const totalWidth = columns.reduce((acc, column) => acc + column.width + shelfThickness, shelfThickness)
  if (shelfTyp === 2) return null
  return (
    <>
      <SlidingDoorRailTop length={totalWidth - 2 * shelfThickness} xPos={shelfThickness} yPos={height - shelfTypes[shelfTyp].topInset} zPos={depth} />
      <SlidingDoorRailBottom length={totalWidth - 4 * shelfThickness} xPos={2 * shelfThickness} yPos={shelfTypes[shelfTyp].bottomInset} zPos={depth} />
    </>
  )
}

import {regalModel} from '../store/datamodels'

export const getMinGrid = (width: number, gridMax: number) => Math.ceil(width / gridMax)
export const getMaxGrid = (width: number, gridMin: number) => Math.floor(width / gridMin)

export const getGridOptions = (width: number, depth: number, material: {thickness: number; eModul: number; weight: number}) => {
  const shelfThickness = material.thickness
  const eModul = material.eModul
  const weight = material.weight

  const colOptions = []
  const minGrid = Math.ceil(width / regalModel.gridMax)
  const maxGrid = Math.floor(width / regalModel.gridMin)
  for (let i = minGrid; i <= maxGrid; i++) {
    const newColSizes = (width - shelfThickness) / i
    const maxLoadkg = (0.5 * 48 * ((Math.pow(shelfThickness * 1000, 3) * depth * 1000) / 12) * eModul) / Math.pow(newColSizes * 1000, 3) / 10
    const ownWeight = ((shelfThickness * depth * newColSizes) / 1000) * weight
    const maxLoad = Math.round((maxLoadkg - ownWeight) * 0.75)
    colOptions[i - minGrid] = {col: i, maxLoad: maxLoad}
  }
  return colOptions
}

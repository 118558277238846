import {Foot} from 'common/threeComponents/Foot'
import {materialModel} from 'utils/datamodels'
import {FootList} from 'products/digital/partLists/footList'
import {MaterialSingleton} from 'common/threeComponents/Materials'

export const ConnectedFeet = () => {
  const feetList = FootList()
  if (feetList.length === 0) return null
  const materialID = feetList[0].material as keyof typeof materialModel
  const materialReference = materialModel[materialID].matReference
  const materialObject = MaterialSingleton.getMaterials()
  const material = materialObject[materialReference] || materialObject[materialReference]
  return (
    <>
      {feetList.map((foot) => {
        return <Foot {...foot} material={material} />
      })}
    </>
  )
}

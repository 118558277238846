import {useStore} from 'products/metrum/store/store'
import shallow from 'zustand/shallow'
import {shelfMaterials} from 'products/metrum/store/datamodels'
import {materialModel} from 'utils/datamodels'
import {IVector} from 'common/threeComponents/FiberBoard'

export const ClothingRailList = () => {
  const clothingRailList = useStore((store) => store.config.clothingRails.list)
  const [depth, shelfHeight, columns, grid, materialID] = useStore(
    (store) => [store.config.main.depth, store.config.main.height, store.config.columns.list, store.config.main.grid, store.config.main.materialID],
    shallow
  )
  const thickness = shelfMaterials[materialID].thickness
  const gridSize = (shelfHeight - thickness) / grid

  const clothingRails: {key: string; pos: IVector; size: IVector; material: keyof typeof materialModel; type: string}[] = []
  clothingRailList.forEach((clothingRail, i) => {
    if (clothingRail.pos.x < columns.length && clothingRail.pos.y <= grid) {
      const xPos = columns.slice(0, clothingRail.pos.x).reduce((pv, cv) => pv + cv.width + thickness, thickness)
      const yPos = clothingRail.pos.y * gridSize - 0.0475
      const zPos = depth / 2
      const width = columns[clothingRail.pos.x].width
      const position = [xPos, yPos, zPos] as IVector
      const size = [0.015, 0.015, width] as IVector
      clothingRails.push({key: clothingRail.pos.x + '.' + clothingRail.pos.y, pos: position, size: size, material: 's13Ei', type: 'clothingrail'})
    }
  })

  return clothingRails
}

import {useStore} from 'products/metrum/store/store'
import shallow from 'zustand/shallow'
import {shelfMaterials} from 'products/metrum/store/datamodels'

export const ColumnList = () => {
  const columnList = useStore((store) => store.config.columns.list)
  const [height, depth, grid, materialID, doors] = useStore(
    (store) => [store.config.main.height, store.config.main.depth, store.config.main.grid, store.config.main.materialID, store.config.doors.list],
    shallow
  )
  const thickness = shelfMaterials[materialID].thickness
  let startPos = 0
  const columnHeight = height
  const yPos = 0
  const columns = [{key: 0, xPos: startPos, yPos: yPos, zPos: 0, length: columnHeight, depth: depth, thickness: thickness, material: materialID, gapHeight: [] as number[], type: 'column'}]
  const colSize = (height - thickness) / grid

  columnList.forEach((el, index) => {
    startPos += el.width + thickness
    const gapHeight = [] as number[]
    const doorsLeft = doors.filter((door) => door.pos.x === index && door.side === 'left')
    const doorsRight = doors.filter((door) => door.pos.x === index + 1 && door.side === 'right' && door.pos.x < columnList.length)
    const gHeight = (Math.round(0.9 / (colSize / 2)) / 2) * colSize < height - 0.075 ? (Math.round(0.9 / (colSize / 2)) / 2) * colSize : thickness + (colSize / 2) * (grid * 2 - 1)
    if (doorsRight.length > 0 || doorsLeft.length > 0) gapHeight.push(gHeight)
    columns.push({
      key: index + 1,
      xPos: startPos,
      yPos: yPos,
      zPos: 0,
      length: columnHeight,
      depth: depth,
      thickness: thickness,
      material: materialID,
      gapHeight: gapHeight,
      type: index + 1 === columnList.length ? 'column' : 'middleColumn',
    })
  })

  return columns
}

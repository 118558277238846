import {useStore} from '../../store/store'
import {DrawerFCFiber} from 'common/threeComponents/DrawerFCNew'
import {DrawerList} from 'products/tektur/partLists/drawerList'

export const ConnectedDrawers = () => {
  const drawerList = DrawerList()
  const visible = useStore((store) => store.view.drawers.visible)
  return (
    <>
      {visible &&
        drawerList.map((drawer, j) => {
          return <DrawerFCFiber {...drawer} />
        })}
    </>
  )
}

import {footModel as feets, materialModel} from 'utils/datamodels'

/**
 * These are static models (they dont change) so can be imported everywhere needed -> no need to pass down and save in state
 */
export const blueprint = {
  gridSize: 0.364,
  minWidth: 0.3,
  gridMin: 0.273,
  gridMax: 0.546,
  minDepth: 0.2,
  maxDepth: 0.6,
  minColHeight: 0.1,
  maxColHeight: 0.9,
  maxDrawerHeight: 0.4,
  minDrawerHeight: 0.1,
  shadowJoint: 0.0025,
  depth: [0.33, 0.41],
  width: [0.383, 0.747, 1.111, 1.475, 1.839, 2.203, 2.567, 2.931],
  colHeight: [0.254, 0.345, 0.527, 0.709],
  maxSpan: 1.1,
  maxFootSpan: 1.5,
  frontInset: 0.025,
  innerDoorInset: 0.0075,
  doorGrooveDistance: 0.0087,
  backpanelInset: {standard: 0.0085, wallMounted: 0.0175},
  backpanelSideInset: 0.003,
}

export const baseUrl = '/analog'

//Export Material Objects for all Three Elements that have a material state -> shelf, doors, drawers, back
const shelfMaterialModel = {
  m18Ah: materialModel['m18Ah'],
  m18Bi: materialModel['m18Bi'],
  m18Bu: materialModel['m18Bu'],
  m18Ei: materialModel['m18Ei'],
  m18Es: materialModel['m18Es'],
  m18Ki: materialModel['m18Ki'],
  m18aN: materialModel['m18aN'],
  m18MW: materialModel['m18MW'],
  m18SW: materialModel['m18SW'],
  m18CB: materialModel['m18CB'],
  m18SB: materialModel['m18SB'],
  s19Ah: materialModel['s19Ah'],
  s19Bi: materialModel['s19Bi'],
  s19Bu: materialModel['s19Bu'],
  s19Ei: materialModel['s19Ei'],
  s19Es: materialModel['s19Es'],
  s19Ki: materialModel['s19Ki'],
  s19aN: materialModel['s19aN'],
  s19eN: materialModel['s19eN'],
  s19sN: materialModel['s19sN'],
}

type Keys = keyof typeof feets
type IFootModel = {[key in Keys]: typeof feets[key] & {maxShelfHeight?: number}}

export const footModel: IFootModel = {
  s23: feets.s23,
  castor43: {...feets.castor43, maxShelfHeight: 0.9},
  rnd30: {...feets.rnd30, heights: [0.105, 0.155, 0.205], maxShelfHeight: 1.15},
  rct16: {...feets.rct16, heights: [0.105, 0.155, 0.205], maxShelfHeight: 1.15},
  wallMounted: {...feets.wallMounted, maxShelfHeight: 0.9},
}

export const doorMaterials = {
  h2SWBC: {...materialModel.h2SWBC, innerMatReference: 'solidAlu', shelfReference: []},
  h2SB: {...materialModel.h2SB, innerMatReference: 'solidAlu', shelfReference: []},
  h2Al: {...materialModel.h2Al, innerMatReference: 'solidAlu', shelfReference: []},
  h3Ei: {...materialModel.h3Ei, innerMatReference: 'solidAlu', shelfReference: ['m18Ei', 's19Ei']},
  h3aN: {...materialModel.h3aN, innerMatReference: 'solidAlu', shelfReference: ['m18aN', 's19aN']},
}

export const slidingDoorTypeModel = [
  {id: 0, name: 'innenliegend'},
  {id: 1, name: 'vorlaufend'},
]

export const drawerMaterials = {
  h2SWBC: {...materialModel.h2SWBC, shelfReference: []},
  h2SB: {...materialModel.h2SB, shelfReference: []},
  h2Al: {...materialModel.h2Al, shelfReference: []},
  h3Ei: {...materialModel.h3Ei, shelfReference: ['m18Ei', 's19Ei']},
  h3aN: {...materialModel.h3aN, shelfReference: ['m18aN', 's19aN']},
}

export const backpanelMaterials = {
  mdf8W: {...materialModel.mdf8W, shelfReference: []},
  h8TB: {...materialModel.h8TB, shelfReference: []},
  f9Ah: {...materialModel.f9Ah, shelfReference: ['m18Ah', 's19Ah']},
  f9Bi: {...materialModel.f9Bi, shelfReference: ['m18Bi', 's19Bi']},
  f9Bu: {...materialModel.f9Bu, shelfReference: ['m18Bu', 's19Bu']},
  f9Ei: {...materialModel.f9Ei, shelfReference: ['m18Ei', 's19Ei']},
  f9Es: {...materialModel.f9Es, shelfReference: ['m18Es', 's19Es']},
  f9Ki: {...materialModel.f9Ki, shelfReference: ['m18Ki', 's19Ki']},
  f9aN: {...materialModel.f9aN, shelfReference: ['m18aN', 's19aN']},
}

export const backpanelTypeModel = [
  {id: 0, name: 'fachgröße'},
  {id: 1, name: 'etagenbreite'},
]

const shelfDescription =
  'modulares regal- und sideboardsystem. in massivholz oder multiplex. analog wird geschossweise aufgebaut. vier verschiedene etagenhöhen können frei kombiniert werden. die drei grösseren fachhöhen sind zusätzlich mit verstellbaren fachböden ausgestattet. leichte schiebetüren in eiche furniert, mattweiss, mattschwarz(anti-finger-print) oder aluminium laufen vor oder in dem korpus. verschiedene schiebetürgrössen können beliebig angeordnet werden und lassen so die unverwechselbare ästhetik von analog entstehen. innenliegende schiebetüren ermöglichen ein komplett geschlossenes möbel.'

export {shelfMaterialModel, shelfDescription}

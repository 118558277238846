import {useStore} from 'products/digital/store/store'
import shallow from 'zustand/shallow'
import {regalModel, shelfMaterials, footModel} from 'products/digital/store/datamodels'
import {IVector} from 'common/threeComponents/FiberBoard'
import {materialModel} from 'utils/datamodels'

export const FootList = () => {
  const [depth, columns, width, grid] = useStore((store) => [store.config.main.depth, store.columns.clearColumns(), store.config.main.width, store.config.main.grid], shallow)
  const feetID = useStore((state) => state.config.feet.id)
  const footHeight = useStore((state) => state.config.feet.height)
  const foot = footModel[feetID]
  const thickness = shelfMaterials[useStore((store) => store.config.main.materialID)].thickness
  const feet = [] as {key: string; pos: IVector; size: IVector; material: keyof typeof materialModel; type: keyof typeof footModel}[]
  const gridSize = (width - thickness) / grid
  const nrGridsInset = thickness / 2 + 2 * gridSize < 0.4 ? 2 : 1
  const xInset = nrGridsInset * gridSize
  const nrFeet = Math.ceil((width - 2 * xInset) / regalModel.maxFootSpan) + 1
  switch (feetID) {
    case 'u308':
      feet.push({key: '0.0', pos: [foot.xInset || 0, 0, depth - (foot.xInset || 0)], size: [0.0275, footHeight, 0.0275], material: 'solidBlack', type: feetID})
      feet.push({key: '0.1', pos: [foot.xInset || 0, 0, foot.xInset || 0], size: [0.0275, footHeight, 0.0275], material: 'solidBlack', type: feetID})
      columns[0].forEach((el) => {
        feet.push({key: el + '.0', pos: [thickness / 2 + el * gridSize, 0, depth - (foot.xInset || 0)], size: [0.0275, footHeight, 0.0275], material: 'solidBlack', type: feetID})
        feet.push({key: el + '.1', pos: [thickness / 2 + el * gridSize, 0, foot.xInset || 0], size: [0.0275, footHeight, 0.0275], material: 'solidBlack', type: feetID})
      })
      feet.push({key: grid + '.0', pos: [width - (foot.xInset || 0), 0, depth - (foot.xInset || 0)], size: [0.0275, footHeight, 0.0275], material: 'solidBlack', type: feetID})
      feet.push({key: grid + '.1', pos: [width - (foot.xInset || 0), 0, foot.xInset || 0], size: [0.0275, footHeight, 0.0275], material: 'solidBlack', type: feetID})
      break
    case 'rnd30':
      ;[...Array(nrFeet)].forEach((_, i) => {
        const xPos = xInset + (i * (width - 2 * xInset)) / (nrFeet - 1)
        feet.push({key: i + '.0', pos: [xPos, 0, depth - 0.05], size: [0.015, footHeight, 0.015], material: 'solidBlack', type: feetID})
        feet.push({key: i + '.1', pos: [xPos, 0, 0.05], size: [0.015, footHeight, 0.015], material: 'solidBlack', type: feetID})
      })
      break
    case 'rct16':
      ;[...Array(nrFeet)].forEach((_, i) => {
        const xPos = xInset + (i * (width - 2 * xInset)) / (nrFeet - 1)
        feet.push({key: i + '.0', pos: [xPos, 0, depth - 0.05], size: [0.01, footHeight, 0.06], material: 'solidBlack', type: feetID})
        feet.push({key: i + '.1', pos: [xPos, 0, 0.05], size: [0.01, footHeight, 0.06], material: 'solidBlack', type: feetID})
      })
      break
  }
  return feet
}

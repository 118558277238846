import {useStore} from 'products/tektur/store/store'
import shallow from 'zustand/shallow'
import {backpanelMaterials, regalModel, shelfMaterials} from 'products/tektur/store/datamodels'

export const TabelarList = () => {
  const tabelarList = useStore((store) => store.tabelars.clearTabelars(), shallow)
  const [backpanels, boards, shelfWidth, shelfDepth, shelfMaterialID, backpanelMaterialID, grid] = useStore(
    (store) => [
      store.config.backpanels,
      store.config.rows.list,
      store.config.main.width,
      store.config.main.depth,
      store.config.main.materialID,
      store.config.backpanels.materialID,
      store.config.main.grid,
    ],
    shallow
  )
  const shelfThickness = shelfMaterials[shelfMaterialID].thickness
  const backpanelThickness = backpanelMaterials[backpanelMaterialID].thickness
  const gridSize = (shelfWidth - shelfThickness) / grid
  const tabelars: {key: string; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof shelfMaterials; type: 'tabelar'}[] = []

  tabelarList.forEach((tabelar) => {
    const yPosIndex = Math.floor(tabelar.pos.y)
    const innerYPos = tabelar.pos.y - yPosIndex
    const yPos = boards.slice(0, yPosIndex).reduce((pv, cv) => pv + cv.height + shelfThickness, 0) + (boards[yPosIndex].height + shelfThickness) * innerYPos
    const fullBackpanel = backpanels.list.find((backpanel) => backpanel.pos.y === yPosIndex && backpanel.cover === 'full')
    const isBackpanel = fullBackpanel || backpanels.list.find((backpanel) => backpanel.pos.x === tabelar.pos.x && backpanel.pos.y === Math.floor(tabelar.pos.y))
    const isInset = isBackpanel ? 1 : 0
    const adjustedDepth = shelfDepth - regalModel.frontInset - (backpanelThickness + regalModel.backpanelInset) * isInset
    const zPos = (backpanelThickness + regalModel.backpanelInset) * isInset
    const width = gridSize - shelfThickness
    const xPos = tabelar.pos.x * gridSize + shelfThickness
    tabelars.push({
      key: tabelar.pos.x + '-' + tabelar.pos.y,
      xPos: xPos,
      yPos: yPos,
      zPos: zPos,
      length: width,
      depth: adjustedDepth,
      thickness: shelfThickness,
      material: shelfMaterialID,
      type: 'tabelar',
    })
  })

  return tabelars
}

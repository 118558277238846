import {useStore} from 'products/digital/store/store'
import shallow from 'zustand/shallow'
import {backpanelMaterials, regalModel, shelfMaterials} from 'products/digital/store/datamodels'

export const BackpanelList = () => {
  const backpanelList = useStore((store) => store.backpanels.clearBackpanels(), shallow)
  const [columns, boards, shelfWidth, shelfMaterialID, backpanelMaterialID, grid] = useStore(
    (store) => [store.config.columns.list, store.config.rows.list, store.config.main.width, store.config.main.materialID, store.config.backpanels.materialID, store.config.main.grid],
    shallow
  )
  const shelfThickness = shelfMaterials[shelfMaterialID].thickness
  const backpanelThickness = backpanelMaterials[backpanelMaterialID].thickness
  const gridSize = (shelfWidth - shelfThickness) / grid
  const backpanels: {key: string; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof backpanelMaterials; type: 'backpanel'}[] = []

  backpanelList.forEach((el) => {
    const yPos = boards.slice(0, el.pos.y).reduce((pv, cv) => pv + cv.height + shelfThickness, shelfThickness)
    const zPos = regalModel.backpanelInset
    const height = boards[el.pos.y].height
    if (el.cover === 'full') {
      const xPos = shelfThickness - regalModel.backpanelSideInset
      const width = shelfWidth - shelfThickness * 2 + 2 * regalModel.backpanelSideInset
      backpanels.push({key: el.pos.x + '-full', xPos: xPos, yPos: yPos, zPos: zPos, length: height, depth: width, thickness: backpanelThickness, material: backpanelMaterialID, type: 'backpanel'})
    } else {
      const columnList = columns[el.pos.y].filter((i) => i > el.pos.x)
      columnList.push(grid)
      const nextColumn = Math.min(...columnList)
      const multiplier = nextColumn - el.pos.x
      const width = gridSize * multiplier - shelfThickness + 2 * regalModel.backpanelSideInset
      const xPos = el.pos.x * gridSize + shelfThickness - regalModel.backpanelSideInset
      backpanels.push({
        key: el.pos.x + '-' + el.pos.y,
        xPos: xPos,
        yPos: yPos,
        zPos: zPos,
        length: height,
        depth: width,
        thickness: backpanelThickness,
        material: backpanelMaterialID,
        type: 'backpanel',
      })
    }
  })

  return backpanels
}

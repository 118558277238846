import {useStore} from 'products/metrum/store/store'
import shallow from 'zustand/shallow'
import {regalModel, shelfMaterials, backpanelMaterials} from 'products/metrum/store/datamodels'
import {isDoorBottomOrTop, isDoorInFront, isDrawerInFront} from '../utils/metrumHelper'

export const BoardList = () => {
  const columns = useStore((store) => store.config.columns.list)
  const [height, depth, materialID, grid, boardList, doors, drawers] = useStore(
    (store) => [store.config.main.height, store.config.main.depth, store.config.main.materialID, store.config.main.grid, store.config.boards.list, store.config.doors.list, store.config.drawers.list],
    shallow
  )
  const thickness = shelfMaterials[materialID].thickness
  let xPos = thickness
  const gridsize = (height - thickness) / grid
  const backpanelThickness = backpanelMaterials[useStore((store) => store.config.backpanels.materialID)].thickness
  const boards: {key: string; xPos: number; yPos: number; zPos: number; length: number; depth: number; thickness: number; material: keyof typeof shelfMaterials; type: string}[] = []

  columns.forEach((el, index) => {
    const zPos = 0.001 + backpanelThickness
    const list = [0, ...boardList[index].filter((el) => el < grid), grid]
    list.forEach((i) => {
      const isInset = isDoorInFront({x: index, y: i}, doors) || isDrawerInFront({x: index, y: i}, drawers)
      const doorBottomorTop = isDoorBottomOrTop({x: index, y: i}, doors)
      const inset = isInset ? (doorBottomorTop ? regalModel.bottomTopInset : regalModel.frontInset) : 0
      const adjustedDepth = depth - zPos - inset
      const type = i === 0 ? 'bottom' : i === grid ? 'top' : 'board'
      boards.push({key: index + '.' + i, xPos: xPos, yPos: i * gridsize, zPos: zPos, length: el.width, depth: adjustedDepth, thickness: thickness, material: materialID, type: type})
    })
    xPos += el.width + thickness
  })
  if (height < 1.57) {
    const totalWidth = columns.reduce((acc, el) => acc + el.width + thickness, thickness)
    boards.push({key: 'cover', xPos: 0, yPos: height + regalModel.shadowJoint, zPos: 0, length: totalWidth, depth: depth, thickness: thickness, material: materialID, type: 'bottom'})
  }
  return boards
}

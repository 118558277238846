import {ContactShadows, OrbitControls} from '@react-three/drei'
import {useCommonStore} from 'common/Store/CommonStore'
import {useEffect, useRef} from 'react'
import {Canvas, events, RootState, useThree} from 'react-three-fiber'
import * as THREE from 'three'
import {Euler} from 'three'

import type {OrbitControls as OrbitControlsType} from 'three-stdlib'
import {degToRad} from 'three/src/math/MathUtils'
import {cameraPosition, perspectives} from 'utils/datamodels'
import {FitToView} from './FitToView'
import {Lights} from './Lights'

export const FiberScene = (props: any) => {
  const groupRef = useRef<THREE.Group>(null)
  // useEffect(() => {
  //   if (groupRef.current) {
  //     debugger
  //     const boundingBox = new THREE.Box3()
  //     boundingBox.setFromObject(groupRef.current)
  //     const size = boundingBox.getSize(new Vector3(0, 0, 0))
  //     console.log('jfkdlaöjfdsa', size)
  //     groupRef.current.position.setX(-size.x / 2)
  //   }
  // }, [])

  const callbackRef = (node: any) => {
    console.log('CallbackRef', node)
    // if (node) {
    //   const boundingBox = new THREE.Box3()
    //   boundingBox.setFromObject(node)
    //   const size = boundingBox.getSize(new Vector3(0, 0, 0))
    //   console.log('size', size)
    //   node.position.setX(-size.x / 2)
    // }
  }

  return (
    <Canvas className="config-canvas" shadows camera={{fov: 20, position: cameraPosition}} gl={{physicallyCorrectLights: true, toneMapping: THREE.NoToneMapping, outputEncoding: THREE.sRGBEncoding}}>
      {/* <CameraGizmo /> */}
      <Controls />
      <Lights />
      {/* <axesHelper scale={1} position={[0, 0, 0]} onUpdate={(self) => self.setColors(new Color('#4c353f'), new Color('#4c353f'), new Color('#4c353f'))} /> */}
      {/* <PresentationControls global zoom={1} config={{mass: 2, tension: 100}} snap={{mass: 2, tension: 100}} rotation={[0, 0, 0]} polar={[0, Math.PI / 2]} azimuth={[-Math.PI / 2, Math.PI / 2]}> */}
      {/* <Stage contactShadow={{blur: 1, opacity: 0.2}} shadows adjustCamera={false} intensity={1} environment="city" preset="rembrandt"> */}
      {/* <CenterBox> */}
      <FitToView paddingBottom={135} paddingTop={50} paddingLeft={50} paddingRight={50} minHeight={2.8} minWidth={2.8}>
        <group ref={callbackRef}>{props.children}</group>
      </FitToView>
      {/* </CenterBox> */}
      {/* </Stage> */}
      {/* </PresentationControls> */}
      {/* </Bounds> */}
      <ContactShadows position={[0, 0, 0]} opacity={2} scale={5} blur={0.8} far={props.footHeight + 0.01} resolution={256} color="#000000" />
    </Canvas>
  )
}
export const FiberPrintScene = (props: {perspective: keyof typeof perspectives; footHeight: number; children: any}) => {
  const ref = useRef(null!)
  const rotation = new Euler(0, -degToRad(perspectives[props.perspective].x), 0)

  const callbackRef = (node: any) => {
    console.log('CallbackRef', node)
  }
  const devicePixelRatio = window.devicePixelRatio || 1
  const paddingBottom = 20 / devicePixelRatio
  const paddingSides = 120 / devicePixelRatio
  const style = {width: 2480 / devicePixelRatio + 'px', height: 1594 / devicePixelRatio + 'px'}

  return (
    <div ref={ref} className="print_scene">
      <div style={style}>
        <Canvas
          shadows
          frameloop="demand"
          events={eventManagerFactory}
          camera={{fov: 20, position: cameraPosition}}
          gl={{preserveDrawingBuffer: true, physicallyCorrectLights: true, toneMapping: THREE.NoToneMapping, outputEncoding: THREE.sRGBEncoding}}>
          <color attach="background" args={['#ffffff']} />
          <SetView />
          <Lights />
          <FitToView paddingBottom={paddingBottom} paddingLeft={paddingSides} paddingRight={paddingSides} minHeight={1.65} minWidth={2}>
            <group rotation={rotation} ref={callbackRef}>
              {props.children}
            </group>
          </FitToView>
          <ContactShadows position={[0, 0, 0]} opacity={1.3} scale={5} blur={0.8} far={props.footHeight + 0.02} resolution={256} color="#000000" />
          <RenderImage pRef={ref}></RenderImage>
        </Canvas>
      </div>
      <div className="background">Bild wird generiert ...</div>
    </div>
  )
}

const eventManagerFactory: Parameters<typeof Canvas>[0]['events'] = (state) => ({
  // Default configuration
  ...events(state),

  // Determines if the event layer is active
  enabled: false,

  // Event layer priority, higher prioritized layers come first and may stop(-propagate) lower layer
  priority: 1,

  // The filter can re-order or re-structure the intersections
  filter: (items: THREE.Intersection[], state: RootState) => items,

  // Find more configuration default on ./packages/fiber/src/web/events.ts
  // And type definitions in ./packages/fiber/src/core/events.ts
})

export const Controls = () => {
  const orbitRef = useRef<OrbitControlsType>(null!)
  const perspective = useCommonStore((store) => store.perspective)

  const setView = (x: number, y: number) => {
    const azimuth = degToRad(x)
    const polar = degToRad(90 - y)
    orbitRef.current.setAzimuthalAngle(azimuth)
    orbitRef.current.setPolarAngle(polar)
  }

  useEffect(() => {
    orbitRef.current.target.y = cameraPosition.y
  })

  useEffect(() => {
    setView(perspectives[perspective].x, perspectives[perspective].y)
    const onMouseUp = () => {
      console.log('controlRef', orbitRef.current)
      setView(perspectives[perspective].x, perspectives[perspective].y)
    }
    window.addEventListener('mouseup', onMouseUp)
    return () => {
      window.removeEventListener('mouseup', onMouseUp)
    }
  }, [perspective])

  //needs to be set to makeDefault
  return (
    <OrbitControls
      makeDefault={true}
      maxPolarAngle={Math.PI / 2}
      minPolarAngle={-Math.PI / 2}
      minAzimuthAngle={-Math.PI}
      maxAzimuthAngle={Math.PI}
      ref={orbitRef}
      enableZoom={false}
      enablePan={false}
    />
  )
}

const RenderImage = (props: any) => {
  //gl preserveDrawingBuffer in Canvas does the trick :)
  const {gl, scene, camera, setFrameloop} = useThree()

  useEffect(() => {
    gl.render(scene, camera)
    const generateImage = () => {
      gl.render(scene, camera)
      const imgObj = gl.domElement.toDataURL('image/png').replace('image/png', 'image/octet-stream')
      const imgContainer = document.createElement('div')
      imgContainer.className += 'print_preview'
      const tmpImg = document.createElement('img')
      tmpImg.src = imgObj
      imgContainer.appendChild(tmpImg)
      gl.domElement.parentElement?.parentElement?.appendChild(imgContainer)
      setFrameloop('never')
    }
    const tmp = setTimeout(generateImage, 100)
  }, [camera, gl, gl.domElement, scene, setFrameloop])

  return <>{props.children}</>
}

const SetView = () => {
  const orbitRef = useRef<OrbitControlsType>(null!)
  const perspective = 'front'
  const {camera}: {camera: THREE.PerspectiveCamera} = useThree()

  const setView = (x: number, y: number) => {
    const azimuth = degToRad(x)
    const polar = degToRad(90 - y)
    orbitRef.current.setAzimuthalAngle(azimuth)
    orbitRef.current.setPolarAngle(polar)
  }

  useEffect(() => {
    orbitRef.current.target.y = camera.position.y
  })

  useEffect(() => {
    setView(perspectives[perspective].x, perspectives[perspective].y)
  }, [perspective])

  return <OrbitControls makeDefault={true} ref={orbitRef} enableDamping={false} enabled={false} />
}

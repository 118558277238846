import {useStore} from 'products/tektur/store/store'
import shallow from 'zustand/shallow'
import {shelfMaterials} from 'products/tektur/store/datamodels'

export const BoardList = () => {
  const boardList = useStore((store) => store.config.rows.list)
  const [width, depth, materialID] = useStore((store) => [store.config.main.width, store.config.main.depth, store.config.main.materialID, store.config.feet.height], shallow)
  const thickness = shelfMaterials[materialID].thickness
  let startPos = 0
  const boards = [{key: 0, xPos: 0, yPos: startPos, zPos: 0, length: width, depth: depth, thickness: thickness, material: materialID, type: 'bottom'}]

  boardList.forEach((el, index) => {
    startPos += el.height + thickness
    boards.push({key: index + 1, xPos: 0, yPos: startPos, zPos: 0, length: width, depth: depth, thickness: thickness, material: materialID, type: index + 1 !== boardList.length ? 'board' : 'top'})
  })
  return boards
}
